import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Page from 'src/components/Page';
import logo from 'src/icons/logo.png';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import swal from 'sweetalert';
import axios from 'src/utils/AxiosHelper';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const RecoveryPass = () => {
    
    const classes = useStyles();
    const navigate = useNavigate();
    
    //State
    const [firstStep, setFirstStep] = useState(true);
    const [code, setCode] = useState('');
    const [familyData, setFamilyData] = useState({});
    const [hasFtEmail, setHasFtEmail] = useState(false);
    const [hasMtEmail, setHasMtEmail] = useState(false);
    const [email, setEmail] = useState('');
    const [selectValue, setSelectValue] = useState('');

    const onValidatecode = () => {
        axios.post('family/passwordReset', {code})
        .then(async response => {
            if (response.status === 200) {
                setFirstStep(false);
                setFamilyData(response.data);
                var ftEmail = response.data.family.ft_email;
                var mtEmail = response.data.family.mt_email;
                if (ftEmail !== null) {
                    if (ftEmail !== '') {
                        await setHasFtEmail(true);
                    }
                }
                if (mtEmail !== null) {
                    if (mtEmail !== '') {
                        await setHasMtEmail(true);
                    }
                }
                if ( ((ftEmail === null) || (ftEmail === '')) && ((mtEmail === null) || (mtEmail === '')) ) {
                    swal("No cuenta con correos registados, favor de validar con su colegio.", { icon: "error" });
                    navigate('/login', { replace: true });
                }
            }
        })
            .catch(error => {
                swal("Ocurrio un error al validar el código", { icon: "error" });
        })
    }

    const onSendPassword = () => {
        if ((email !== '') && (selectValue !== '')) {
            if (email.toLowerCase() === selectValue.toLowerCase()) {
                axios.post('family/passwordResetMail', { code, email })
                .then(response => {
                    if (response.status === 200) {
                        swal("Se envio un email con el usuario y contraseña", { icon: "success" });
                        navigate('/login', { replace: true });
                    }
                })
                .catch(error => {
                    swal("Ocurrio un error al enviar el email", { icon: "error" });
                })
            }
        }
        else {
            swal("Ocurrio un error a la hora de realizar la recuperación de contraseña", { icon: "error" });
        }
    }

    const formatEmail = (s) => {
        if ((s !== null) || (s !== '')) {
            var i = s.indexOf('@');
            var startIndex = i * .2 | 0;
            var endIndex   = i * .9 | 0;
            return s.slice(0, startIndex) +
                   s.slice(startIndex, endIndex).replace(/./g, '*') +
                   s.slice(endIndex);
        }
        return '';
    }
    
    return (
        <>
            <Page
                className={classes.root}
                title="Recuperar Contraseña"
            >
                <Box display="flex" flexDirection="column" height="100%" justifyContent="center" >
                    <Container maxWidth="sm" style={{display: 'flex', flexDirection: 'column'}}>
                        
                        <img src={logo} style={{ width: 200, alignSelf: 'center', margin: 30 }} />

                        {firstStep ?
                            <GridItem flexDirection="column" xs={12}>
                                <Typography>Ingrese el código para recuperar la contraseña</Typography>
                                <TextField
                                    style={{marginTop: 30}}
                                    fullWidth label="Código" margin="normal" name="code"  onChange={e => {setCode(e.target.value)}} value={code} variant="outlined"
                                />
                                <Button color="primary" fullWidth size="large" onClick={onValidatecode} type="submit" variant="contained" style={{marginTop: 30}}>
                                    Validar código
                                </Button>
                            </GridItem>
                            :
                            <GridItem xs={12}>
                                <Typography>Seleccione el correo donde quiere recuperar la contraseña</Typography>
                                <TextField id="select" select
                                    label="Email"
                                    value={selectValue}
                                    onChange={e => { setSelectValue(e.target.value) }}
                                    margin="normal" fullWidth
                                >
                                    {hasFtEmail && <MenuItem value={familyData.family.ft_email} > {`${formatEmail(familyData.family.ft_email)} - (email del padre)`} </MenuItem>}
                                    {hasMtEmail && <MenuItem value={familyData.family.mt_email} > {`${formatEmail(familyData.family.mt_email)} - (email del la madre)`} </MenuItem>}
                                </TextField>
                                <Typography style={{marginTop: 30}}>Valide la dirección de email seleccionada </Typography>
                                <TextField
                                    fullWidth label="Email" margin="normal" name="code"  onChange={e => {setEmail(e.target.value)}} value={email} variant="outlined"
                                />
                                <Button color="primary" fullWidth size="large" onClick={onSendPassword} type="submit" variant="contained" style={{marginTop: 30}}>
                                    Recuperar contraseña
                                </Button>
                            </GridItem>
                        }
                        
                    </Container>
                </Box>
            </Page>
        </>
    )
}

export default RecoveryPass;
