import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    makeStyles,
    Button,
    Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import CustomTable from 'src/components/CustomTable/CustomTable';
import axios from 'src/utils/AxiosHelper';
import { CSVLink } from "react-csv";
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import SearchBar from 'src/components/SearchBar';

const cols = [
    { accessor: "family_id", header: "# familia" },
    { accessor: "family", header: "Familia" },
    { accessor: "register", header: "Registro" },
    { accessor: "student", header: "Estudiante" }
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const PagareLA = () => {
    const classes = useStyles();
    const [rowsData, setRowsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [excelData, setExcelData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    
    useEffect(() => {
        getUsers();
    }, [])

    const getUsers = () => {
        var aux = [["Familia", "Familia", "Registro", "Estudiante"]];
        axios.get('auth/externalPromissoryNotes')
        .then(async response => {
            if (response.status === 200) {
                response.data.map(item => {
                    aux.push([item.family_id, item.family, item.register, item.student]);
                });
                await setExcelData(aux);
                await setRowsData(response.data);
                await setIsLoading(false);
            }
        })
    }

    const onChangeSearchField = (value) => {
        if (value.length > 3) {
            setTimeout(() => {
                onSearchPress(value);
            }, 500);
        }
        else {
            setIsSearching(false);
        }
    }

    const onDeleteSearch = () => {
        setIsSearching(false);
    }

    const onSearchPress = async(value) => {
        var aux = [];
        await rowsData.map(item => {
            if (
                ( item.family_id && item.family_id.toString().includes(value)) ||
                ( item.family && item.family.toLowerCase().includes(value.toLowerCase())) ||
                ( item.register && item.register.toString().includes(value)) ||
                ( item.student && item.student.toLowerCase().includes(value.toLowerCase()))
            ) {
                aux.push(item);
            }
        })
        await setFilteredData(aux);
        await setIsSearching(true);
    }

    return (
        <Page
            className={classes.root}
            title=".:BECAS - Pagarés LA:."
        >
            <Container maxWidth={false} style={{ display: 'flex', flexDirection: 'column'}}>
                <Typography variant="h1">Pagarés los altos</Typography>
                <SearchBar
                    onChange={(value) => {onChangeSearchField(value)}}
                    onDeleteSearch={onDeleteSearch}
                    onSearchPress={(value) => {onSearchPress(value)}}
                >
                    <Button
                        size="small"
                        style={{alignSelf: 'flex-end'}}
                        endIcon={<TableChartOutlinedIcon/>}
                    >
                        <CSVLink
                            data={excelData}
                            filename={"Pagare los altos.csv"}
                            className="btn btn-primary"
                            target="_blank"
                            style={{height: 0, display: 'flex', color: '#263238',justifyContent: 'center', alignItems: 'center' }}
                        >
                        Exportar
                        </CSVLink>
                    </Button>
                </SearchBar>
                <Box mt={3}>
                    {
                        !isLoading &&
                        <CustomTable
                            cols={cols}
                            rows={isSearching? filteredData : rowsData}
                        />
                    }
                </Box>
            </Container>
        </Page>
    );
};

export default PagareLA;