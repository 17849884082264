import React, {useEffect, useState} from 'react';
import {
    Button,
    TextField,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box
} from '@material-ui/core';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import axios from 'src/utils/AxiosHelper';
import Cookies from 'js-cookie';
import CustomModal from 'src/components/CustomModal';
import Table from 'src/components/CustomTable/CustomTable';
import swal from 'sweetalert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const cols = [
    {accessor: "brand", header: "Marca"},
    {accessor: "model", header: "Modelo"},
    {accessor: "owner", header: "Propietario"},
    {accessor: "year", header: "Año"}
];

const initalData = {
    family_id: "",
    brand: "",
    model: "",
    owner: "",
    year: ""
}

const Autos = ({
    family_id,
    formVerification,
    getFormVerifications,
    estudios = false,
    observations= {}
}) => {

    useEffect(() => {
        getFamilyData();
        if(estudios){
            setReason(observations.vehicle);
        }
    }, [])

    //state
    const [vehicles, setVehicles] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [carData, setCarData] = useState(initalData);
    const [reason, setReason] = useState('');

    const handleChange = (e) => {
        setCarData({
            ...carData,
            [e.target.name]: e.target.value
        })
    }

    const getFamilyData = () => {
        axios.get(`family/vehicle/${family_id}`)
        .then(response => {
            if(response.status === 200){
                setVehicles(response.data);
            }
        })
    }

    const onDeleteAction = async(customer) => {
        await swal({
            title: "Estas seguro?",
            text: "Una vez eliminado no se podra recuperar el vehículo!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                axios.post(`family/vehicle/${customer.id}`, {_method: "delete"})
                .then(response => {
                    swal("Vehículo eliminado correctamente", {icon: "success"});
                    getFamilyData();
                })
            }
        });
    }

    const handleNewDependency = () => {
        setShowForm(true);
    }

    const onSaveNewCar = () => {
        carData.family_id = family_id;
        axios.post(`family/vehicle`, carData)
        .then(response => {
            if(response.status === 200){
                swal("Vehículo agregado correctamente", {icon: "success"});
                setCarData(initalData);
                getFamilyData();
            }
        })
        .catch(error => {
            Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                swal(error.response.data[val][0], {icon: "error"});
            });
        });
        setShowForm(false);
    }

    const submitReason = () => {
        axios.post(`research/observation/${family_id}`, { vehicle: reason }, 'put')
        .then(response => {
            if(response.status === 200){
                axios.post(`research/researchFormVerification/${family_id}`, {vehicle: 1}, 'put')
                .then(response => {
                    if(response.status === 200){
                        swal("Correcto", "Observaciones guardadas de manera correcta", "success");
                    }
                })
            }
        })
    }

    return (
        <>
        <Accordion defaultExpanded={!estudios}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                    Autos
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {/* Datos de la familia */}
                <GridContainer  alignItems="center" justify="center" spacing={2} style={{width: '100%'}}>
                    <GridItem xs={12} >
                        <Table 
                            cols={cols}
                            rows={vehicles}
                            actions={!estudios}
                            showAction={false}
                            updateAction={false}
                            onDelete={customer => onDeleteAction(customer)}
                        />
                    </GridItem>
                    {!estudios && <GridItem xs={6} style={{display: 'flex'}} alignItems="center" justify="center" >
                        <Button
                            color="primary"
                            size="large"
                            variant="contained"
                            onClick={handleNewDependency}
                        >
                            Agregar
                        </Button>
                    </GridItem>}
                </GridContainer>
            </AccordionDetails>
        </Accordion>
        {estudios && 
        <form onSubmit={(e) => {
            e.preventDefault();
            submitReason();
        }}>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                        Observaciones autos
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <GridContainer direction="row" alignItems="center" justify="center" spacing={2} style={{width: '100%'}}>
                        <GridItem xs={12} >
                            <TextField
                                fullWidth
                                label="Observaciones"
                                margin="normal" name="reason"
                                required={true} multiline rows={3}
                                onChange={(e) => setReason(e.target.value)}
                                value={reason}
                                variant="outlined"
                            />
                        </GridItem>
                        <GridItem xs={12} style={{display: 'flex'}} alignItems="center" justify="center" >
                            <Button
                                color="primary"
                                size="large"
                                variant="contained"
                                type="submit"
                            >
                                Guardar
                            </Button>
                        </GridItem>
                    </GridContainer>
                </AccordionDetails>
            </Accordion>
        </form>}
        <CustomModal
            open={showForm}
            handleClose={ () => {
                setShowForm(false);
                setCarData(initalData);
            }}
        >
            <Box mb={3}>
                <Typography
                    color="textPrimary"
                    variant="h2"
                >
                    Nuevo vehículo
                </Typography>
            </Box>
            <TextField
                fullWidth
                label="Marca"
                margin="normal"
                name="brand"
                required={true}
                onChange={(e) => handleChange(e)}
                value={carData.brand}
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Modelo"
                margin="normal"
                name="model"
                required={true}
                onChange={(e) => handleChange(e)}
                value={carData.model}
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Propietario"
                margin="normal"
                name="owner"
                required={true}
                onChange={handleChange}
                value={carData.owner}
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Año"
                margin="normal"
                name="year"
                required={true}
                onChange={(e) => handleChange(e)}
                value={carData.year}
                variant="outlined"
            />
            <Box my={2}>
                <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={onSaveNewCar}
                >
                    Agregar
                </Button>
            </Box>
        </CustomModal>
        </>
    )
}

export default Autos;