import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Button,
  TextField,
  Typography,
  MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import CustomTable from 'src/components/CustomTable/CustomTable';
import axios from 'src/utils/AxiosHelper';
import Toolbar from 'src/components/CustomTable/Toolbar';
import CustomModal from 'src/components/CustomModal';
import swal from 'sweetalert';
import Cookies from 'js-cookie';
import { CSVLink } from "react-csv";
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import SearchBar from 'src/components/SearchBar';

const cols = [
    { accessor: "family_id", header: "Familia" },
    { accessor: "name", header: "Nombre" },
    { accessor: "schoolarship_name", header: "Tipo de beca" },
    { accessor: "created_at", header: "Creación" },
    { accessor: "updated_at", header: "Ultima Actualización" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const SolicitudesEnCurso = () => {

    const userRole = Cookies.getJSON('userData');
    const permission = Cookies.getJSON('permission');

    const classes = useStyles();
    const [rowsData, setRowsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [buttonMessage, setButtonMessage] = useState("Agregar");
    const [currentUser, setCurrentUser] = useState({});
    const [schoolarship_id, setSchoolarship_id] = useState(1);

    const [excelData, setExcelData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    
    useEffect(() => {
        getUsers();
    }, [])

    const getUsers = () => {
        var aux = [
            [ "Familia", "Nombre", "Tipo de beca", "Creación", "Ultima Actualización"]
        ];
        axios.get('auth/startedRequest')
        .then(response => {
            if (response.status === 200) {
                setRowsData(response.data);
                setIsLoading(false);
                response.data.map(item => {
                    item.schoolarship_name = item.schoolarship.name;
                    item.name = `${item.family.ft_lastname} ${item.family.mt_lastname}`;
                    aux.push([ item.family_id, item.name, item.schoolarship_name, item.created_at, item.updated_at ])
                })
                setExcelData(aux);
            }
        })
    }

    const onEditAction = (customer) => {
        setCurrentUser(customer);
        setSchoolarship_id(customer.schoolarship_id);
        setButtonMessage("Editar");
        setShowModal(true);
    }

    const onDeleteAction = async(customer) => {
        await swal({
            title: "Estas seguro?",
            text: "Una vez eliminado no se podra recuperar el usuario!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                axios.post(`auth/finishedRequest/${customer.id}`, {_method: "delete"})
                .then(response => {
                    swal("Formulario eliminado correctamente", {icon: "success"});
                    getUsers();
                })
            }
        });
    }

    const onHandleSubmit = async() => {
        await axios.post(`family/formVerification/${currentUser.family_id}`, {
            family_id: currentUser.family_id,
            schoolarship_id: parseInt(schoolarship_id, 10),
            family: 0,
            student: 0,
            heritage_assets: 0,
            monthly_income: 0,
            monthly_expenses: 0,
            schoolarship_reason: 0,
            finished: 0,
            _method: 'put'
        })
        .then(response => {
            if (response.status === 200) {
                swal("solicitud actualizada correctamente", {icon: "success"});
            }
        })
        .catch(error => {
            Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                swal(error.response.data[val][0], {icon: "error"});
            });
        });
        setShowModal(false);
        getUsers();
    }

    const onChangeSearchField = (value) => {
        if (value.length > 3) {
            setTimeout(() => {
                onSearchPress(value);
            }, 500);
        }
        else {
            setIsSearching(false);
        }
    }

    const onDeleteSearch = () => {
        setIsSearching(false);
    }

    const onSearchPress = async(value) => {
        var aux = [];
        await rowsData.map(item => {
            if (
                (item.family_id && item.family_id.toString().includes(value)) ||
                (item.name && item.name.toLowerCase().includes(value.toLowerCase())) ||
                (item.schoolarship_name && item.schoolarship_name.toLowerCase().includes(value.toLowerCase()))
            ) {
                aux.push(item);
            }
        })
        await setFilteredData(aux);
        await setIsSearching(true);
    }

    return (
        <Page
            className={classes.root}
            title=".:BECAS - Solicitudes en curso:."
        >
            <Container maxWidth={false} style={{ display: 'flex', flexDirection: 'column'}}>
                <Typography variant="h1">Solicitudes en curso</Typography>
                <SearchBar
                    onChange={(value) => {onChangeSearchField(value)}}
                    onDeleteSearch={onDeleteSearch}
                    onSearchPress={(value) => {onSearchPress(value)}}
                >
                    <Button
                        size="small"
                        style={{alignSelf: 'flex-end'}}
                        endIcon={<TableChartOutlinedIcon/>}
                    >
                        <CSVLink
                            data={excelData}
                            filename={"SolicitudesEnCurso.csv"}
                            className="btn btn-primary"
                            target="_blank"
                            style={{height: 0, display: 'flex', color: '#263238',justifyContent: 'center', alignItems: 'center' }}
                        >
                        Exportar
                        </CSVLink>
                    </Button>
                </SearchBar>
                <Box mt={3}>
                    {
                        !isLoading &&
                        <CustomTable
                            cols={cols}
                            rows={isSearching? filteredData : rowsData}
                            data={isSearching? filteredData : rowsData}
                            actions={true}
                            showAction={false}
                            updateAction={(permission.update === 1) ? true : false}
                            delAction={(permission.delete === 1) ? true : false}
                            onEdit={(customer) => {onEditAction(customer)}}
                            onDelete={(customer) => {onDeleteAction(customer)}}
                        />
                    }
                </Box>
            </Container>
            <CustomModal
                open={showModal}
                handleClose={ () => setShowModal(false) }
            >
                <Box mb={3}>
                    <Typography
                        color="textPrimary"
                        variant="h2"
                    >
                        {buttonMessage} solicitud
                    </Typography>
                </Box>
                <TextField id="select" select name="role_id" label="Tipo de beca" value={schoolarship_id} onChange={(e) => setSchoolarship_id(e.target.value)} margin="normal" fullWidth >
                    <MenuItem value={1} > Interna / Oficial </MenuItem>
                    <MenuItem value={2} > Apoyo a familia numerosa </MenuItem>
                    <MenuItem value={3} > Orfandad </MenuItem>
                </TextField>
                <Box my={2}>
                    <Button
                        color="primary"
                        disabled={isSubmitting}
                        size="large"
                        variant="contained"
                        onClick={onHandleSubmit}
                    >
                        {buttonMessage}
                    </Button>
                </Box>
            </CustomModal>
            
        </Page>
    );
};

export default SolicitudesEnCurso;