import React, {useEffect, useState} from 'react';
import {
    Button,
    TextField,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    MenuItem
} from '@material-ui/core';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import axios from 'src/utils/AxiosHelper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import swal from 'sweetalert';

const initalData = {
    health_status: '',
    member_disease: '',
    medical_service: '',
    assured: '',
    insurance_policy_cost: '',
    insured_amount: '',
    observation: ''
}

const SaludFamiliar = ({
    family_id,
    formVerification,
    getFormVerifications
}) => {

    useEffect(() => {
        getData();
    }, [])

    //state
    const [userData, setUserData] = useState(initalData);
    const [update, setUpdate] = useState(false);

    const getData = () => {
        axios.get(`research/familyHealth/${family_id}`)
        .then(async response => {
            if((response.status === 200) && response.data.length > 0){
                await setUserData(response.data[0]);
                setUpdate(true);
            }
        })
    }

    const handleSubmit = () => {
        var url = `research/familyHealth`;
        userData.family_id=family_id;
        if(update){
            userData._method = 'put';
            url = `research/familyHealth/${family_id}`;
        }
        axios.post(url, userData)
        .then(response => {
            if(response.status === 200){
                axios.post(`research/researchFormVerification/${family_id}`, {family_health: 1, _method: "put"})
                .then(response => {
                    if(response.status === 200){
                        swal("Datos guardados correctamente", {icon: "success"});
                        getFormVerifications();
                    }
                })
            }
        })
        .catch(error => {
            Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                swal(error.response.data[val][0], {icon: "error"});
            });
        });
    }

    const handleChange = async(e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        })
    }

    return (
        <>
        <form onSubmit={e => {
            e.preventDefault();
            handleSubmit();
        }}>
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                    Salud familiar
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {/* Datos de la familia */}
                <GridContainer  alignItems="center" justify="center" spacing={2} style={{width: '100%'}}>
                    <GridItem xs={12} md={6}>
                        <TextField
                            fullWidth margin="normal" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.health_status} name="health_status"
                            multiline rows={5}
                            label="Estado de la salud de la familia"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            fullWidth margin="normal" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.member_disease} name="member_disease"
                            multiline rows={5}
                            label="¿Algún miembro cuenta con alguna enfermedad grave?"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            fullWidth margin="normal" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.medical_service} name="medical_service"
                            label="Tipo de servicio medico que usa la familia"
                            select
                        >
                            <MenuItem value={'Público'}> Público </MenuItem>
                            <MenuItem value={'Particular'}> Particular </MenuItem>
                        </TextField>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            fullWidth margin="normal" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            select
                            value={userData.assured} name="assured"
                            label="¿Cuenta con seguro?"
                        >
                            <MenuItem value={0} > {"NO"} </MenuItem>
                            <MenuItem value={1} > {"SI"} </MenuItem>
                        </TextField>
                    </GridItem>
                    { userData.assured === 1 && 
                    <GridItem xs={12} md={6}>
                        <TextField
                            fullWidth margin="normal" type="number" variant="outlined"
                            onChange={(e) => handleChange(e)}
                            value={userData.insurance_policy_cost} name="insurance_policy_cost"
                            label="Costo anual de la póliza de seguros"
                        />
                    </GridItem>}
                    { userData.assured === 1 && 
                    <GridItem xs={12} md={6}>
                        <TextField
                            fullWidth margin="normal" type="number" variant="outlined"
                            onChange={(e) => handleChange(e)}
                            value={userData.insured_amount} name="insured_amount"
                            label="Suma asegurada de la póliza"
                        />
                    </GridItem>}
                    <GridItem xs={12} md={6}>
                        <TextField
                            fullWidth margin="normal" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.observation} name="observation"
                            multiline rows={5}
                            label="Observaciones"
                        />
                    </GridItem>
                    <GridItem xs={12} style={{display: 'flex'}} alignItems="center" justify="center" >
                        <Button
                            color="primary"
                            size="large"
                            variant="contained"
                            type="submit"
                        >
                            Guardar
                        </Button>
                    </GridItem>
                </GridContainer>
            </AccordionDetails>
        </Accordion>
        </form>
        </>
    )
}

export default SaludFamiliar;