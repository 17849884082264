/**
 * @fileoverview AxiosHelper
 * Axios Helper
 * @version 1.0
 * @author César Paul Hernández Camacho
 * @date 2/11/2020
 * 
 */

import Cookies from 'js-cookie';
const axios = require('axios');
 let baseURL = "https://api.colmenares.org.mx/api/";
// let baseURL = "http://scholarshipsystem.test:8080/api/";
// let baseURL = "http://127.0.0.1:8000/api/";

const post = (url, data, method = false) => {
    if(method){
        data._method = method;
    }
    var form_data = new FormData();

    for ( var key in data ) {
        form_data.append(key, data[key]);
    }
    return new Promise((resolve, reject) => {
        axios.post(
            `${baseURL}${url}`,
            form_data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
        .then(async response => {
            resolve(await response);
        })
        .catch(error => {
            reject(error);
        })
    })
}

const get = (url) => {
    return new Promise((resolve, reject) => {
        axios.get(
            `${baseURL}${url}`,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
        .then(async response => {
            resolve(await response);
        })
        .catch(error => {
            reject(error);
        })
    })
}

export default ({post, get});