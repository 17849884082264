import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  modal: {
    overflow:'scroll',
    height:'100%',
    display:'block',
    marginTop: 'auto',
    marginBottom: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    outline: "0 !important",
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: '30vw',
    alignItems: 'flex-end'
  },
}));

export default function CustomModal(props) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        style={{display: 'grid'}}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper} elevation={0}>
          {!props.hideClose && <IconButton style={{alignSelf: 'flex-end'}} onClick={props.handleClose} color="primary" aria-label="upload picture" component="span">
              <CloseIcon />
          </IconButton>}
          {props.children}
        </div>
      </Modal>
    </div>
  );
}

CustomModal.defaultProps = {
  hideClose: false
}

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  hideClose: PropTypes.bool
}