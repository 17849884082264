import React, {useEffect, useState} from 'react';
import { Page, Text, View, Document, Image, StyleSheet, PDFDownloadLink, PDFViewer} from '@react-pdf/renderer';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'src/utils/AxiosHelper';
//Images
import talonPago from 'src/icons/talon_pago.jpg'
import refHeader from 'src/icons/refHeader.jpg'
import banorte from 'src/icons/banorte.jpg'

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  section: {
    margin: 10,
    flexGrow: 1,
    flexDirection: 'column'
  },
  title: {
      fontSize: 18,
      textAlign: 'center'
  },
  negritas: {
    fontWeight: '800',
    fontSize: 10
  },
  negritas_italic: {
    fontWeight: '800',
    fontSize: 10,
    fontStyle: 'italic'
  },
  table: {
      display: 'flex',
      width: '60%',
      flexDirection: 'row',
      flexWrap: 'wrap'
  },
  tableContent: {
      border: '1',
      borderStyle: 'solid',
      borderColor: 'black',
      width: '50%',
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingLeft: 10
  },
  tableRow: {
      border: '1',
      borderStyle: 'solid',
      borderColor: 'black',
      width: '100%',
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'space-around',
      flexDirection: 'row',
      paddingLeft: 10
  },
  tableText: {
      fontSize: 8
  }
});


// Create Document Component
const MyDocument = ({settings, family}) => {
    return(
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section} >
                    <View style={{fontSize: 10, margin: 10}}>
                        <Text>Pago en ventanilla</Text>
                    </View>
                    <View style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 10}} >
                        <Image src={talonPago} style={{width: '100%'}}/>
                    </View>
                    <View style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 10}} >
                        <Image src={banorte} style={{height: 80}}/>
                    </View>
                    <View style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginVertical: 10}} >
                        <View style={styles.table}>
                            <View style={styles.tableContent}>
                                <Text style={styles.tableText} >Empresa: {settings.bank_reference}</Text>
                            </View>
                            <View style={styles.tableContent}>
                                <Text style={styles.tableText} >Referencia: {family.bank_reference}</Text>
                            </View>
                            <View style={styles.tableContent}>
                                <Text style={styles.tableText} >Monto: ${settings.research_cost}</Text>
                            </View>
                            <View style={styles.tableContent}>
                                <Text style={styles.tableText} > Familia {`${family.ft_lastname} ${family.mt_lastname}`}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={{width: '50%'}} >
                                    <Text style={[styles.tableText, {textAlign: 'left'}]} >Conepto</Text>
                                </View>
                                <View style={{width: '50%'}} >
                                    <Text style={[styles.tableText, {textAlign: 'right', paddingRight: 10}]} >Total</Text>
                                </View>
                            </View>
                            <View style={[styles.tableRow, {borderWidth: 0}]}>
                                <View style={{width: '50%'}} >
                                    <Text style={[styles.tableText, {textAlign: 'left'}]} >Estudio socioeconómico</Text>
                                </View>
                                <View style={{width: '50%'}} >
                                    <Text style={[styles.tableText, {textAlign: 'right', paddingRight: 10}]} >${settings.research_cost}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{fontSize: 10, margin: 10, textAlign: 'center'}}>
                        <Text>*Pago no válido después de: {settings.research_date}</Text>
                    </View>
                </View>
                <View>
                </View>
            </Page>

        </Document>
    );
}

const DownloadLink = (props) => {

    //state
    const [family, setFamily] = useState({})
    const [settings, setSettings] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    
    let { family_id, schoolarship } = useParams();

    useEffect(() => {
        getData();
    }, [])

    const getData = async() => {
        await axios.get(`family/information/${family_id}`)
        .then(async response => {
            if(response.status === 200){
                await setFamily(response.data);
            }
        });
        await axios.get(`auth/settings`)
        .then(async response => {
            if(response.status === 200){
                await setSettings(response.data);
                setIsLoading(false);
            }
        });
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            {!isLoading && 
            <PDFViewer style={{width: '100vw', height: '80vh', marginBottom: 15}} >
                <MyDocument 
                    family={family}
                    settings={settings}
                />
            </PDFViewer>
            }
            {!isLoading && 
                <PDFDownloadLink 
                    document={
                        <MyDocument 
                            family={family}
                            settings={settings}
                        />
                    }
                    style={{backgroundColor: '#415f8e', color: 'white', minWidth: 150, padding: 15, marginTop: 10, borderRadius: 5}}
                    fileName="Referencia.pdf">
                    {({ blob, url, loading, error }) => (loading ? 'Cargando documento...' : 'Descargar documento')}
                </PDFDownloadLink>
            }
        </div>
    )
}

export default DownloadLink;