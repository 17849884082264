import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import {useSelector} from 'react-redux';

//components
import PrivacyPolicy from 'src/components/PrivacyPolicy/PrivacyPolicy';

import Cookies from 'js-cookie';

const loggedIn = Cookies.get('auth');

const App = () => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const authType = useSelector(state => state.auth.authType);
  const routing = useRoutes(routes(isAuthenticated, authType));

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
      <PrivacyPolicy />
    </ThemeProvider>
  );
};

export default App;
