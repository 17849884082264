import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Container,
    Typography,
    makeStyles,
} from '@material-ui/core';
import Cookies from 'js-cookie';
import swal from 'sweetalert';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import axios from 'src/utils/AxiosHelper';
//Components
import Page from 'src/components/Page';
import Table from 'src/components/CustomTable/CustomTable';

const useStyles = makeStyles((theme) => ({
  root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100vh',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    button: {
        width: '40vw',
        [theme.breakpoints.down('md')]: {
            marginTop: 10
        }
    },
    activeButton: {
        color: 'white',
        backgroundColor: '#3f51b5',
        width: '40vw',
        [theme.breakpoints.down('md')]: {
            marginTop: 10
        }
    }
}));

const cols = [
    {accessor: 'company_id', header: 'ID'},
    {accessor: 'family_id', header: 'Familia'},
    {accessor: 'familyName', header: 'Nombre de familia'},
    {accessor: 'address', header: 'Dirección'},
]

const Confirmation = () => {
    const userData = Cookies.getJSON('userData');
    const classes = useStyles();
    const navigate = useNavigate();
    //state
    const [showFinishedStudies, setShowFinishedStudies] = useState(false);
    const [estudios, setEstudios] = useState([]);
    const [estudiosFinalizados, setEstudiosFinalizados] = useState([]);

    useEffect(() => {
        getEstudios();
    }, []);

    const getEstudios = () => {
        axios.get(`research/formVerification/toDo/${userData.id}`)
        .then(response => {
            if(response.status === 200){
                var aux = [];
                response.data.map(item => {
                    item.familyName = `${item.family_object.user.name}`;
                    item.address = `${item.family_object.address}`;
                    aux.push(item);
                })
                setEstudios(aux);
            }
        })
        axios.get(`research/formVerification/company/${userData.id}`)
        .then(response => {
            if(response.status === 200){
                var aux = [];
                response.data.map(item => {
                    item.familyName = `${item.family_object.user.name}`;
                    item.address = `${item.family_object.address}`;
                    aux.push(item);
                })
                setEstudiosFinalizados(aux);
            }
        })
    }

    const handleStudies = () => {
        setShowFinishedStudies(false);
    }

    const handleFinishedStudies = () => {
        setShowFinishedStudies(true);
    }

    const checkFormVerification = (customer) => {
        axios.get(`research/observation/${customer.family_id}`)
        .then( async response => {
            if(response.status === 200){
                if(response.data.length > 0){
                    navigate(`/estudios/form/${customer.family_id}`);
                }
                else{
                    await axios.post(`research/observation`, 
                        {
                            family_id: customer.family_id,
                            family: 0,
                            student: 0,
                            economic_dependent: 0,
                            monthly_income: 0,
                            monthly_expenses: 0,
                            vehicle: 0
                        }
                    )
                    .then(async response => {
                        if(response.status === 200){
                            navigate(`/estudios/form/${customer.family_id}`);
                        }
                    })
                }
            }
        })
    }

    const onShowAction = (customer) => {
        window.open(`/estudiofinalizado/${customer.family_id}/${userData.id}`, "_blank");
    }

    return (
        <Page
        className={classes.root}
        title=".:BECAS - Colmenares:."
        >
            <Container style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <GridContainer item xs={12} direction="row" justify="center" alignItems="center" >
                    <GridItem container xs={12} md={6} justify="center" alignItems="center">
                        <Button
                            className={ showFinishedStudies ? classes.button : classes.activeButton}
                            fullWidth
                            size="large"
                            variant="contained"
                            onClick={handleStudies}
                        >
                            Estudios por realizar
                        </Button>
                    </GridItem>
                    <GridItem container xs={12} md={6} justify="center" alignItems="center">
                        <Button
                            className={showFinishedStudies ? classes.activeButton : classes.button}
                            fullWidth
                            size="large"
                            variant="contained"
                            onClick={handleFinishedStudies}
                        >
                            Estudios finalizados
                        </Button>
                    </GridItem>
                </GridContainer>
            </Container>
            <Container style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginTop: 10}}>
                <Typography color="textSecondary" gutterBottom variant="h2" style={{textAlign: 'center'}}>
                    Familias
                </Typography>
                <Table
                    cols={cols}
                    rows={showFinishedStudies? estudiosFinalizados : estudios}
                    actions={true}
                    updateAction={!showFinishedStudies}
                    delAction={false}
                    showAction={showFinishedStudies}
                    onShow={(customer) => onShowAction(customer)}
                    onEdit={(customer) => {checkFormVerification(customer)}}
                />
            </Container>
        </Page>
    );
};

export default Confirmation;
