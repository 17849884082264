import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  makeStyles,
  Typography,
  Input,
  InputLabel
} from '@material-ui/core';
import Page from 'src/components/Page';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import axios from 'src/utils/AxiosHelper';
import swal from 'sweetalert';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SettingsView = () => {
  const classes = useStyles();
  const [settingsData, setSettingsData] = useState({
    start_date: '',
    end_date: '',
    research_cost: '',
    inscription_percent: '',
    three_children_percent: '',
    four_up_children_percent: '',});

  useEffect(() => {
    getConfigData();
  }, [])

  const getConfigData = async() => {
    await axios.get('auth/settings')
    .then(response => {
      setSettingsData(response.data);
    })
  }

  const handleChange = (e) => {
    setSettingsData({
      ...settingsData,
      [e.target.name]: e.target.value
    })
  }

  const onSaveSettings = () => {
    settingsData._method = "put";
    axios.post("auth/settings/1", settingsData)
    .then(response => {
      swal("Configuración modificada exitosamente!", {icon: "success"});
    })
  }

  return (
    <Page
      className={classes.root}
      title=".:BECAS - Configuración:."
    >
      <Container maxWidth="lg">
      <Box>
        <Typography color="textSecondary" gutterBottom variant="h3" >
            Configuración
        </Typography>              
        </Box>
        <GridContainer direction="row" alignItems="center" justify="space-between" spacing={2}>
            <GridItem xs={12}>
              <InputLabel shrink>Fecha de inicio</InputLabel>
              <Input 
                label="Fecha de inicio"
                margin="normal"
                fullWidth
                type="date"
                name="start_date"
                onChange={(e) => handleChange(e)}
                value={settingsData.start_date}
              />
            </GridItem>
            <GridItem xs={12} style={{margin: '10px 0px'}}>
            <InputLabel shrink>Fecha final</InputLabel>
              <Input 
                label="Fecha final"
                margin="normal"
                fullWidth
                type="date"
                name="end_date"
                onChange={(e) => handleChange(e)}
                value={settingsData.end_date}
              />
            </GridItem>
          <GridItem xs={12}>
            <TextField
              label="Porcentaje de inscripción"
              margin="normal"
              fullWidth
              name="inscription_percent"
              onChange={(e) => handleChange(e)}
              value={settingsData.inscription_percent}
              variant="outlined"
              />
          </GridItem>
        </GridContainer>
          <Box>
            <Typography color="textSecondary" gutterBottom variant="h3" >
              Estudio socioeconómico
            </Typography>
          </Box>
        <GridContainer direction="row" alignItems="center" justify="space-between" spacing={2}>
          <GridItem xs={12}>
            <TextField
              label="Costo"
              margin="normal"
              fullWidth
              name="research_cost"
              onChange={(e) => handleChange(e)}
              value={settingsData.research_cost}
              variant="outlined"
              />
          </GridItem>
          <GridItem xs={12} style={{ margin: '10px 0px' }}>
            <InputLabel shrink>Fecha estudio socioecónomido</InputLabel>
              <Input 
                label="Fecha estudio socioecónomido"
                margin="normal"
                fullWidth
                type="date"
                name="research_date"
                onChange={(e) => handleChange(e)}
                value={settingsData.research_date}
              />
            </GridItem>
        </GridContainer>
        <Box>
          <Typography color="textSecondary" gutterBottom variant="h3" >
            Familia numerosa
          </Typography>   
        </Box>
        <GridContainer direction="row" alignItems="center" justify="space-between" spacing={2}>
          <GridItem xs={12}>
            <TextField
              label="Porcentaje de tres hijos"
              margin="normal"
              fullWidth
              name="three_children_percent"
              onChange={(e) => handleChange(e)}
              value={settingsData.three_children_percent}
              variant="outlined"
              />
          </GridItem>
          <GridItem xs={12}>
            <TextField
              label="Porcentaje de cuatro hijos o más"
              margin="normal"
              fullWidth
              name="four_up_children_percent"
              onChange={(e) => handleChange(e)}
              value={settingsData.four_up_children_percent}
              variant="outlined"
              />
          </GridItem>
        </GridContainer>
        <Box my={2}>
            <Button
                color="primary"
                fullWidth
                size="large"
                variant="contained"
                onClick={onSaveSettings}
            >
                Modificar
            </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default SettingsView;
