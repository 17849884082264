import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  AlertCircle as AlertCircleIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  UserPlus as UserPlusIcon,
  Users as UsersIcon,
  Archive,
  Book,
  Server,
  Package,
  UserCheck
} from 'react-feather';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import BusinessIcon from '@material-ui/icons/Business';
import NavItem from './NavItem';
import Cookies from 'js-cookie';

const navItems = [
  {
    href: '/app/consultas/',
    icon: Archive,
    title: 'Consultas',
    submodules: [
      {
        href: '/app/consultas/usuarios',
        icon: UserIcon,
        title: 'Usuarios'
      },
      {
        href: '/app/consultas/familias',
        icon: UsersIcon,
        title: 'Familias'
      },
      {
        href: '/app/consultas/alumnos',
        icon: UsersIcon,
        title: 'Alumnos'
      },
      {
        href: '/app/consultas/solicitudes',
        icon: Server,
        title: 'Solicitudes terminadas'
      },
      {
        href: '/app/consultas/pagares-los-altos',
        icon: Package,
        title: 'Pagarés Los Altos'
      },
      {
        href: '/app/consultas/accesos',
        icon: UserCheck,
        title: 'Accesos de Familia'
      },
      {
        href: '/app/consultas/validacion-up',
        icon: BarChartIcon,
        title: 'Alumnos UP'
      },
      {
        href: '/app/consultas/solicitues-en-curso',
        icon: UserCheck,
        title: 'Solicitudes en curso'
      }
    ]
  },
  {
    href: '/app/estudios/',
    icon: Book,
    title: 'Estudios',
    submodules: [
      {
        href: '/app/estudios/empresas',
        icon: BarChartIcon,
        title: 'Empresas'
      },
      {
        href: '/app/estudios/asignacion',
        icon: BusinessIcon,
        title: 'Asignación de empresas',
        submodules: null
      },
      {
        href: '/app/estudios/terminados',
        icon: DoneAllIcon,
        title: 'Estudios terminados',
        submodules: null
      },
    ]
  },
  {
    href: '/app/config',
    icon: SettingsIcon,
    title: 'Configuración',
    submodules: null
  }
]

const collegeNav = [
  {
    href: '/app/consultas/solicitudes',
    icon: Server,
    title: 'Solicitudes terminadas',
    submodules: null
  },
  {
    href: '/app/consultas/accesos',
    icon: UserCheck,
    title: 'Accesos de Familia',
    submodules: null
  },
  {
    href: '/app/estudios/terminados',
    icon: DoneAllIcon,
    title: 'Estudios terminados',
    submodules: null
  },
]
const asistantNav = [
  {
    href: '/app/consultas/solicitudes',
    icon: Server,
    title: 'Solicitudes terminadas',
    submodules: null
  },
  {
    href: '/app/consultas/accesos',
    icon: UserCheck,
    title: 'Accesos de Familia',
    submodules: null
  }
]

const upNav = [
  {
    href: '/app/consultas/solicitudes',
    icon: Server,
    title: 'Solicitudes terminadas',
    submodules: null
  },
  {
    href: '/app/consultas/accesos',
    icon: UserCheck,
    title: 'Accesos de Familia',
    submodules: null
  },
  {
    href: '/app/consultas/validacion-up',
    icon: BarChartIcon,
    title: 'Alumnos UP',
    submodules: null
  },
  {
    href: '/app/estudios/terminados',
    icon: DoneAllIcon,
    title: 'Estudios terminados',
    submodules: null
  },
]

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {

  const userData = Cookies.getJSON('userData');
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const renderNav = () => {
    // console.log(userData)
    var objectToMap = (userData.role_id === 3) ? (userData.campus_id === 99)? upNav : collegeNav : (userData.role_id === 4) ? asistantNav: navItems;
    return objectToMap.map((item) => (
      <NavItem
        data={item}
        href={item.href}
        key={item.title}
        title={item.title}
        icon={item.icon}
        subModules={item.submodules}
      />
    ))
  } 

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box p={2}>
        <List>
          {renderNav()}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      {/* <Hidden lgUp> */}
        <Drawer
          anchor="right"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      {/* </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden> */}
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
