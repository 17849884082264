import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    makeStyles,
    Typography,
    Grid,
    Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import CustomTable from 'src/components/CustomTable/CustomTable';
import axios from 'src/utils/AxiosHelper';
import CustomModal from 'src/components/CustomModal';
import swal from 'sweetalert';
import Cookies from 'js-cookie';
//Family components
import FormFamilias from './FormFamilias';
import { CSVLink } from "react-csv";
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import SearchBar from 'src/components/SearchBar';
import PasswordUpdateForm from 'src/components/PasswordUpdateForm/PasswordUpdateForm';

const cols = [
    {accessor: "code", header: "Codigo"},
    {accessor: "name", header: "Nombre"},
    {accessor: "ft_name", header: "Nombre (Padre)"},
    {accessor: "ft_email", header: "Email (Padre)"},
    {accessor: "mt_name", header: "Nombre (Madre)"},
    {accessor: "mt_email", header: "Email (Madre)"}
];

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    card: {
        width: "100%",
        minHeight: "20vh",
    },
    pos: {
        marginBottom: 10
    },
}));

const Familias = () => {
    const classes = useStyles();
    const [rowsData, setRowsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [showPasswordForm, setShowPasswordForm] = useState(false);
    const [currentCustomer, setCurrectCustomer] = useState({
        user: {
            code: '',
            created_at: '',
            end_date: '',
            family_id: '',
            full_access: '',
            id: '',
            individual_dates: '',
            name: '',
            observation: '',
            start_date: '',
            terms_and_conditions: '',
            updated_at: ''
        }
    });

    const permission = Cookies.getJSON('permission');
    
    useEffect(() => {
        getUsers();
    }, [])

    const getUsers = () => {
        var aux = [[
            "Codigo", "Nombre", "Dirección", "Ciudad","C.P.","Colonia","Teléfono",
            "Nombre (Padre)", "Apellidos paterno (Padre)", "Apellido materno (Padre)", "Email (Padre)", "Fecha de nacimiento (Padre)", "Edad (Padre)", "Actividad empresarial (Padre)", "Antiguedad (Padre)", "Nombre de la empresa (Padre)", "Puesto en la empresa (Padre)", "Dueño o socio de la empresa (Padre)", "Porcentaje % (Padre)", "Número de celular (Padre)",
            "Nombre (Madre)", "Apellidos paterno (Madre)", "Apellido materno (Madre)", "Email (Madre)", "Fecha de nacimiento (Madre)", "Edad (Madre)", "Actividad empresarial (Madre)", "Antiguedad (Madre)", "Nombre de la empresa (Madre)", "Puesto en la empresa (Madre)", "Dueño o socio de la empresa (Madre)", "Porcentaje (Madre)", "Número de celular (Madre)"
        ]];
        axios.get('auth/family')
        .then(async response => {
            if (response.status === 200) {
                response.data.map((item, key) => {
                    item.code = item.user.code;
                    item.user_id = item.user.id;
                    item.name = item.user.name;
                    aux.push([
                        item.code, item.name,item.address,item.town,item.pc,item.suburb,item.phone_number,
                        item.ft_name, item.ft_lastname, item.ft_second_lastname, item.ft_email, item.ft_birthdate, item.ft_age, item.ft_business_activity, item.ft_business_antiquity, item.ft_business_name, item.ft_business_position, item.ft_business_society, item.ft_business_percent, item.ft_cellphone_number,
                        item.mt_name, item.mt_lastname, item.mt_second_lastname, item.mt_email, item.mt_birthdate, item.mt_age, item.mt_business_activity, item.mt_business_antiquity, item.mt_business_name, item.mt_business_position, item.mt_business_society, item.mt_business_percent, item.mt_cellphone_number,
                    ])
                })
                await setExcelData(aux);
                await setRowsData(response.data);
                await setIsLoading(false);
            }
        })
    }

    const onShowAction = async(customer) => {
        await setCurrectCustomer(customer);
        setViewModal(true);
    }

    const onEditAction = async (customer) => {
        await setCurrectCustomer(customer);
        setShowModal(true);
    }

    const onPasswordAction = async (customer) => {
        await setCurrectCustomer(customer);
        setShowPasswordForm(true);
    }

    const handleChange = (e) => {
        setCurrectCustomer({
            ...currentCustomer,
            [e.target.name]: e.target.value
        })
    }
    
    const handleChangeUser = (e) => {
        setCurrectCustomer({
            ...currentCustomer,
            user: {
                ...currentCustomer.user,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleSubmit = () => {
        currentCustomer._method = "put";
        currentCustomer.user._method = "put";
        axios.post(`auth/family/${currentCustomer.id}`, currentCustomer)
        .then(response => {
            if (response.status === 200) {
                axios.post(`auth/userFamily/${currentCustomer.user.id}`, currentCustomer.user);
                swal("Datos de la familia actualizados correctamente", { icon: "success" });
                getUsers();
                setShowModal(false);
            }
        })
        .catch(error => {
            Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                swal(error.response.data[val][0], {icon: "error"});
            });
        });
        
        
    }

    const onChangeSearchField = (value) => {
        if (value.length > 3) {
            setTimeout(() => {
                onSearchPress(value);
            }, 500);
        }
        else {
            setIsSearching(false);
        }
    }

    const onDeleteSearch = () => {
        setIsSearching(false);
    }

    const onSearchPress = async(value) => {
        var aux = [];
        await rowsData.map(item => {
            if (
                (item.code && item.code.toLowerCase().includes(value.toLowerCase())) ||
                (item.name && item.name.toLowerCase().includes(value.toLowerCase())) ||
                (item.ft_name && item.ft_name.toLowerCase().includes(value.toLowerCase())) ||
                (item.ft_email && item.ft_email.toLowerCase().includes(value.toLowerCase())) ||
                (item.mt_name && item.mt_name.toLowerCase().includes(value.toLowerCase())) || 
                (item.mt_email && item.mt_email.toLowerCase().includes(value.toLowerCase()))
            ){
                aux.push(item);
            }
        })
        await setFilteredData(aux);
        await setIsSearching(true);
    }

    return (
        <Page
            className={classes.root}
            title=".:BECAS - Familias:."
        >
            <Container maxWidth={false} style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h1">Familias</Typography>
                <SearchBar
                    onChange={(value) => {onChangeSearchField(value)}}
                    onDeleteSearch={onDeleteSearch}
                    onSearchPress={(value) => {onSearchPress(value)}}
                >
                    <Button
                        size="small"
                        style={{alignSelf: 'flex-end'}}
                        endIcon={<TableChartOutlinedIcon/>}
                    >
                        <CSVLink
                            data={excelData}
                            filename={"Familias.csv"}
                            className="btn btn-primary"
                            target="_blank"
                            style={{height: 0, display: 'flex', color: '#263238',justifyContent: 'center', alignItems: 'center' }}
                        >
                        Exportar
                        </CSVLink>
                    </Button>
                </SearchBar>
                <Box mt={3}>
                    {
                        !isLoading &&
                        <CustomTable
                            cols={cols}
                            rows={isSearching? filteredData : rowsData}
                            actions={true}
                            delAction={false}
                            showAction={(permission.read === 1) ? true : false}
                            updateAction={(permission.update === 1) ? true : false}
                            passwordAction
                            onEdit={(customer) => {onEditAction(customer)}}
                            onShow={(customer) => onShowAction(customer)}
                            onPassword={(customer) => onPasswordAction(customer)}
                        />
                    }
                </Box>
            </Container>
            <PasswordUpdateForm
                showModal={showPasswordForm}
                setShowModal={(action) => setShowPasswordForm(action)}
                customer={currentCustomer}
            />
            <CustomModal
                open={showModal}
                handleClose={ () => setShowModal(false) }
            >
                <FormFamilias
                    currentCustomer={currentCustomer}
                    handleChange={(e) => handleChange(e)}
                    handleSubmit={handleSubmit}
                    handleChangeUser={(e) => {handleChangeUser(e)}}
                />
            </CustomModal>
            <CustomModal
                open={viewModal}
                handleClose={() => setViewModal(false)}
            >
                <Container style={{ width: '95vw', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}} >
                    <Grid item container xs={12} md={3} direction="column" justify="center" alignItems="center">
                        <Typography variant="h3" component="h2" style={{marginBottom: 20}}>
                            Datos familia
                        </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Dirección </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.address} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Teléfono </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.phone_number} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Colonia </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.suburb} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Código postal </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.pc} </Typography>
                        
                        <Typography variant="h3" component="h2" style={{ marginBottom: 20 }}>
                            Datos usuario
                        </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Nombre </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.user.name} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Full access </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.user.full_access} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Fechas individuales </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.user.individual_dates} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Fecha de inicio </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.user.start_date} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Fecha de finalización </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.user.end_date} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Observaciones </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.user.observation} </Typography>
                    </Grid>
                    <Grid item container xs={12} md={3} direction="column" justify="center" alignItems="center">
                        <Typography variant="h3" component="h2" style={{marginBottom: 20}}>
                            Datos del padre
                        </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Nombre </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.ft_name} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Apellido paterno </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.ft_lastname} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Apellido materno </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.ft_second_lastname} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Nombre de la empresa </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.ft_business_name} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Giro de la empresa </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.ft_business_activity} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Antiguedad de la empresa </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.ft_business_antiquity} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Puesto en la empresa </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.ft_business_position} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> ¿Es dueño o socio de la empresa? </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.ft_business_society} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Procentaje de la empresa </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.ft_business_percent}% </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Teléfono celular </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.ft_cellphone_number} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Email </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.ft_email} </Typography>
                    </Grid>
                    <Grid item container xs={12} md={3} direction="column" justify="center" alignItems="center">
                        <Typography variant="h3" component="h2" style={{marginBottom: 20}}>
                            Datos de la madre
                        </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Nombre </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.mt_name} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Apellido paterno </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.mt_lastname} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Apellido materno </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.mt_second_lastname} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Nombre de la empresa </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.mt_business_name} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Giro de la empresa </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.mt_business_activity} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Antiguedad de la empresa </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.mt_business_antiquity} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Puesto en la empresa </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.mt_business_position} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> ¿Es dueño o socio de la empresa? </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.mt_business_society} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Procentaje de la empresa </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.mt_business_percent}% </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Teléfono celular </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.mt_cellphone_number} </Typography>
                        <Typography variant="h4" component="p" color="textSecondary"> Email </Typography>
                        <Typography className={classes.pos} color="textSecondary"> {currentCustomer.mt_email} </Typography>
                    </Grid>
                </Container>
            </CustomModal>
        </Page>
    );
};

export default Familias;