import React, {useEffect, useState} from 'react';
import {
    Button,
    TextField,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    MenuItem
} from '@material-ui/core';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import axios from 'src/utils/AxiosHelper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import swal from 'sweetalert';
import ImageUploader from "react-images-upload";

let baseURL = "https://api.colmenares.org.mx";
// let baseURL = "http://scholarshipsystem.test:8080";

const Fotos = ({
    family_id,
    formVerification,
    getFormVerifications
}) => {

    useEffect(() => {
        getPictures();
    }, [])

    //state
    const [pictures, setPictures] = useState([]);
    const [prevPictures, setPrevPictures] = useState([]);
    const [picturesArray, setPicturesArray] = useState([]);

    const getPictures = async() => {
        await axios.get(`research/image/${family_id}`)
        .then(response => {
            if(response.status === 200){
                var aux = [];
                response.data.map(item => {
                    aux.push(`${baseURL}${item.image}`);
                })
                setPicturesArray(response.data);
                setPrevPictures(aux);
            }
        })
    }

    const onDrop = async(picture, prevPictures) => {
        setTimeout(() => {
            setPrevPictures(prevPictures);
            picturesArray.map((item) => {
                var found = prevPictures.find(element => element.includes(item.image_name));
                if(found === undefined){
                    axios.post(`research/image/${item.id}`, {_method: 'delete'})
                    .then(response => {
                        if(response.status === 200){
                            getPictures();
                        }
                    })
                }
            })
            if(picture.length > 10){
                setPictures(picture.slice(0,10));
                swal("Atención", "Solo se pueden agregar 10 imagenes, por lo tanto solo se tomaran en cuenta las primeras 10 fotos", "warning");
            }
            else{
                setPictures(picture);
            }
        }, 500);
    };

    const handleSubmit = () => {
        if(pictures.length > 0){
            var ok = true;
            pictures.map(picture => {
                axios.post('research/image', 
                {
                    family_id: family_id,
                    image: picture,
                })
                .then(response => {
                })
                .catch(error => {
                    ok = false;
                })
            });
            if(ok){
                swal("Correcto", "Imagenes cargadas con exito", "success");
            }
            else{
                swal("Error", "Ocurrio un error al cargar las imagenes", "error");
            }
            axios.post(`research/researchFormVerification/${family_id}`, {photos: 1, _method: "put"})
            .then(response => {
                if(response.status === 200){
                    getFormVerifications();
                }
            })
        }
    }

    return (
        <>
        <form onSubmit={e => {
            e.preventDefault();
            handleSubmit();
        }}>
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                    Fotografías
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <GridContainer alignItems="center" justify="center" spacing={2} style={{width: '100%'}}>
                    {/* <GridItem xs={12} container direction="column" alignItems="center" justify="center">
                        <Typography>
                            Excelente - Familia con superavit y economia estable
                        </Typography>
                    </GridItem> */}
                    <GridItem xs={12}>
                        <ImageUploader
                            withIcon={true}
                            buttonText={"Seleccionar imágenes"}
                            label=""
                            withPreview
                            defaultImages={prevPictures}
                            onChange={onDrop}
                            imgExtension={[".jpg",".jpeg", ".gif", ".png"]}
                            maxFileSize={5242880}
                        />
                    </GridItem>
                    <GridItem xs={12} style={{display: 'flex', marginTop: 10}} alignItems="center" justify="center" >
                        <Button
                            color="primary"
                            size="large"
                            variant="contained"
                            type="submit"
                        >
                            Guardar
                        </Button>
                    </GridItem>
                </GridContainer>
            </AccordionDetails>
        </Accordion>
        </form>
        </>
    )
}

export default Fotos;