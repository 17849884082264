import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    makeStyles,
    TextField,
    MenuItem,
    Typography,
    Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import CustomTable from 'src/components/CustomTable/CustomTable';
import axios from 'src/utils/AxiosHelper';
import CustomModal from 'src/components/CustomModal';
import swal from 'sweetalert';
import Cookies from 'js-cookie';
//Core components
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
//Estudios components
import { CSVLink } from "react-csv";
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import SearchBar from 'src/components/SearchBar';

const cols = [
    {accessor: "code", header: "Codigo"},
    {accessor: "name", header: "Nombre"},
    {accessor: "ft_name", header: "Nombre (Padre)"},
    {accessor: "mt_name", header: "Nombre (Madre)"},
    {accessor: "company_name", header: "Empresa"},
];

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    card: {
        width: "100%",
        minHeight: "20vh",
    },
    pos: {
        marginBottom: 10
    },
}));

const EstudiosTerminados = () => {
    const classes = useStyles();
    const [rowsData, setRowsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [excelData, setExcelData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [currentCustomer, setCurrectCustomer] = useState({});

    //cookies
    const userData = Cookies.getJSON('userData');
    const permission = Cookies.getJSON('permission');
    
    useEffect(() => {
        getUsers();
    }, [])

    const getUsers = () => {
        var aux = [["Codigo", "Nombre","Nombre (Padre)", "Nombre (Madre)", "Empresa"]];
        axios.get(`research/formVerification/campus/${userData.campus_id}`)
        .then(async response => {
            if (response.status === 200) {
                response.data.map((item, key) => {
                    item.code = item.family_object.user.code;
                    item.name = item.family_object.user.name;
                    item.ft_name = item.family_object.ft_name;
                    item.ft_email = item.family_object.ft_email;
                    item.mt_name = item.family_object.mt_name;
                    item.mt_email = item.family_object.mt_email;
                    item.company_name = item.family_object.company.name;
                    aux.push([ item.code, item.name, item.ft_name, item.mt_name, item.company_name ])
                })
                await setExcelData(aux);
                await setRowsData(response.data);
                await setIsLoading(false);
            }
        })
    }

    const onShow = (customer) => {
        // console.log(customer)
        window.open(`/estudiofinalizado/${customer.family_id}/${customer.company_id}`, "_blank");
    }

    const onChangeSearchField = (value) => {
        if (value.length > 3) {
            setTimeout(() => {
                onSearchPress(value);
            }, 500);
        }
        else {
            setIsSearching(false);
        }
    }

    const onDeleteSearch = () => {
        setIsSearching(false);
    }

    const onSearchPress = async(value) => {
        var aux = [];
        await rowsData.map(item => {
            if (
                (item.code && item.code.toString().toLowerCase().includes(value.toLowerCase())) ||
                (item.name && item.name.toLowerCase().includes(value.toLowerCase())) ||
                (item.ft_name && item.ft_name.toLowerCase().includes(value.toLowerCase())) ||
                (item.ft_email && item.ft_email.toLowerCase().includes(value.toLowerCase())) ||
                (item.mt_name && item.mt_name.toLowerCase().includes(value.toLowerCase())) || 
                (item.mt_email && item.mt_email.toLowerCase().includes(value.toLowerCase()))
            ){
                aux.push(item);
            }
        })
        await setFilteredData(aux);
        await setIsSearching(true);
    }

    return (
        <Page
            className={classes.root}
            title="Estudios terminados"
        >
            <Container maxWidth={false} style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h1">Estudios terminados</Typography>
                <SearchBar
                    onChange={(value) => {onChangeSearchField(value)}}
                    onDeleteSearch={onDeleteSearch}
                    onSearchPress={(value) => {onSearchPress(value)}}
                >
                    <Button
                        size="small"
                        style={{alignSelf: 'flex-end'}}
                        endIcon={<TableChartOutlinedIcon/>}
                    >
                        <CSVLink
                            data={excelData}
                            filename={"Estudios-Terminados.csv"}
                            className="btn btn-primary"
                            target="_blank"
                            style={{height: 0, display: 'flex', color: '#263238',justifyContent: 'center', alignItems: 'center' }}
                        >
                        Exportar
                        </CSVLink>
                    </Button>
                </SearchBar>
                <Box mt={3}>
                    {
                        !isLoading &&
                        <CustomTable
                            cols={cols}
                            rows={isSearching? filteredData : rowsData}
                            actions={true}
                            delAction={false}
                            updateAction={false}
                            showAction={(permission.read === 1) ? true : false}
                            onShow={(customer) => {onShow(customer)}}
                        />
                    }
                </Box>
            </Container>
        </Page>
    );
};

export default EstudiosTerminados;