import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Page from 'src/components/Page';
import logo from 'src/icons/logo.png';
import GridItem from 'src/components/Grid/GridItem';
import swal from 'sweetalert';
import axios from 'src/utils/AxiosHelper';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const RecoveryPassE = () => {
    
    const classes = useStyles();
    const navigate = useNavigate();
    
    //State
    const [firstStep, setFirstStep] = useState(true);
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [password_confirmation, setConfirmationPassword] = useState('');
    const [hasFtEmail, setHasFtEmail] = useState(false);
    const [hasMtEmail, setHasMtEmail] = useState(false);
    const [userData, setUserData] = useState({});
    const [email, setEmail] = useState('');
    const [validatedEmail, setValidatedEmail] = useState(false);

    const onValidatecode = () => {
        axios.post('research/passwordReset', {user_id: code})
            .then(async response => {
                if (response.status === 200) {
                    setFirstStep(false);
                    response.data.emailC = formatEmail(response.data.email);
                    setUserData(response.data);
            }
        })
            .catch(error => {
                swal("Ocurrio un error al validar el código", { icon: "error" });
        })
    }

    const onSendPassword = () => {
        if ((password !== '') && (password_confirmation !== '')) {
            if (password.toLowerCase() === password_confirmation.toLowerCase()) {
                axios.post(`auth/passwordResetUpdate/${userData.id}`, { password, password_confirmation }, 'put')
                .then(response => {
                    if (response.status === 200) {
                        swal("La contraseña se cambio correctamente", { icon: "success" });
                        navigate('/admin', { replace: true });
                    }
                })
                .catch(error => {
                    swal("Ocurrio un error al cambiar la contraseña", { icon: "error" });
                })
            }
        }
        else {
            swal("Ocurrio un error a la hora de realizar la recuperación de contraseña", { icon: "error" });
        }
    }

    const onValidateEmail = () => {
        if(email !== '') {
            if (email.toLowerCase() === userData.email.toLowerCase()) {
                setValidatedEmail(true);
            }
        }
    }

    const formatEmail = (s) => {
        if ((s !== null) || (s !== '')) {
            var i = s.indexOf('@');
            var startIndex = i * .2 | 0;
            var endIndex   = i * .9 | 0;
            return s.slice(0, startIndex) +
                   s.slice(startIndex, endIndex).replace(/./g, '*') +
                   s.slice(endIndex);
        }
        return '';
    }
    
    return (
        <>
            <Page
                className={classes.root}
                title="Recuperar Contraseña"
            >
                <Box display="flex" flexDirection="column" height="100%" justifyContent="center" >
                    <Container maxWidth="sm" style={{display: 'flex', flexDirection: 'column'}}>
                        
                        <img src={logo} style={{ width: 200, alignSelf: 'center', margin: 30 }} />

                        {firstStep ?
                            <GridItem flexDirection="column" xs={12}>
                                <Typography>Ingrese el código para recuperar la contraseña</Typography>
                                <TextField
                                    style={{marginTop: 30}}
                                    fullWidth label="Código" margin="normal" name="code"  onChange={e => {setCode(e.target.value)}} value={code} variant="outlined"
                                />
                                <Button color="primary" fullWidth size="large" onClick={onValidatecode} type="submit" variant="contained" style={{marginTop: 30}}>
                                    Validar código
                                </Button>
                            </GridItem>
                            :
                            <>
                                {!validatedEmail && <GridItem xs={12}>
                                    <Typography style={{marginTop: 30}}>Valide su dirección de email </Typography>
                                    <Typography style={{marginTop: 30}}> {userData.emailC} </Typography>
                                    <TextField
                                        fullWidth label="Email" margin="normal" name="code"  onChange={e => {setEmail(e.target.value)}} value={email} variant="outlined"
                                    />
                                    <Button color="primary" fullWidth size="large" onClick={onValidateEmail} type="submit" variant="contained" style={{marginTop: 30}}>
                                        Confirmar email
                                    </Button>
                                </GridItem>}
                                {validatedEmail && <GridItem xs={12}>
                                    <Typography style={{marginTop: 30}}>Ingrese su nueva contraseña </Typography>
                                    <TextField
                                        fullWidth label="contraseña" margin="normal" type="password"
                                        onChange={e => { setPassword(e.target.value) }} value={password} variant="outlined"
                                    />
                                    <Typography style={{marginTop: 30}}>Confirme su nueva contraseña </Typography>
                                    <TextField
                                        fullWidth label="contraseña" margin="normal" type="password"
                                        onChange={e => { setConfirmationPassword(e.target.value) }} value={password_confirmation} variant="outlined"
                                    />
                                    <Button color="primary" fullWidth size="large" onClick={onSendPassword} type="submit" variant="contained" style={{marginTop: 30}}>
                                        Cambiar contraseña
                                    </Button>
                                </GridItem>}
                            </>
                        }
                        
                    </Container>
                </Box>
            </Page>
        </>
    )
}

export default RecoveryPassE;
