import React, {useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Typography,
    makeStyles,
    Grid
} from '@material-ui/core';
import Cookies from 'js-cookie';
import Axios from 'src/utils/AxiosHelper';
import swal from 'sweetalert';
import axios from 'src/utils/AxiosHelper'

//Components
import Page from 'src/components/Page';

//Forms
import MomDadForm from '../becas/MomDadForm';
import StudentPercentForm from '../becas/StudentPercentForm';
import EconomicDependenciesForm from '../becas/EconomicDependenciesForm';
import MonthlyIncome from '../becas/MonthlyIncome';
import MonthlyExpense from '../becas/MonthlyExpense';
import Autos from '../becas/Autos';
import BienesDetallados from './BienesDetallados';
import SaludFamiliar from './SaludFamiliar';
import Interes from './Interes';
import InteresTable from './InteresTable';
import ReferenciaFamiliar from './ReferenciaFamiliar';
import Situacion from './Situacion';
import Fotos from './Fotos';

const useStyles = makeStyles((theme) => ({
  root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100vh',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
  }
}));

const initialValues = {
    ft_name: '',
    ft_lastname: '',
    ft_second_lastname: '',
    ft_business_name: '',
    ft_business_activity: '',
    ft_business_antiquity: '',
    ft_business_position: '',
    ft_business_society: '',
    ft_business_percent: '',
    ft_cellphone_number: '',
    ft_email: '',
    mt_name: '',
    mt_lastname: '',
    mt_second_lastname: '',
    mt_business_name: '',
    mt_business_activity: '',
    mt_business_antiquity: '',
    mt_business_position: '',
    mt_business_society: '',
    mt_business_percent: '',
    mt_cellphone_number: '',
    mt_email: '',
    address: '',
    suburb: '',
    phone_number: '',
    pc: '',
    municipio: ''
}

const initVerification = {
    family: 0,
    finished: 0,
    heritage_assets: 0,
    monthly_expenses: 0,
    monthly_income: 0,
    schoolarship_id: 0,
    schoolarship_reason: 0,
    student: 0
}

const Estudios = () => {
    const userData = Cookies.getJSON('userData');
    const classes = useStyles();
    const navigate = useNavigate();
    let { family_id } = useParams();
    //state
    const [currentCustomer, setCurrentCustomer] = useState({initialValues});
    const [formVerification, setFormVerification] = useState(initVerification);
    const [loaded, setLoaded] = useState(false);
    const [observations, setObservations] = useState({})

    useEffect(() => {
        getFormVerifications();
    }, []);

    const getFormVerifications = async() => {
        await Axios.get(`research/formVerification/${family_id}`)
        .then(response => {
            if (response.status === 200) {
                setFormVerification(response.data);
            }
        });
        await Axios.get(`research/observation/${family_id}`)
        .then( async response => {
            if (response.status === 200) {
                setObservations(response.data[0]);
                setLoaded(true);
            }
        });
    }

    const handleChange = (e) => {
        setCurrentCustomer({
            ...currentCustomer,
            [e.target.name]: e.target.value
        });
    }

    const handleFinishSubmit = () => {
        // console.log(formVerification);
        if(
            (formVerification.family === 1) &&
            (formVerification.student === 1) &&
            (formVerification.economic_dependent === 1) &&
            (formVerification.monthly_income === 1) &&
            (formVerification.monthly_expenses === 1) &&
            (formVerification.vehicle === 1) &&
            (formVerification.heritage_assets_detail === 1) &&
            (formVerification.family_health === 1) &&
            (formVerification.leisure_time === 1) &&
            (formVerification.economic_situation_and_conclusion === 1) &&
            (formVerification.photos === 1) 
        ){
            axios.post(`research/researchFormVerification/${family_id}`, {finished: 1, _method: "put"})
            .then(response => {
                if(response.status === 200){
                    swal("Formulario finalizado correctamente", {icon: "success"});
                    getFormVerifications();
                    navigate('/estudios/inicio', { replace: true });
                }
            })
        }
        else{
            swal("¡Aviso!", "Es necesario guardar todos los formularios para poder finalizar", "warning");
        }
    }

    const onShowPDF = () => {
        window.open(`/estudiofinalizado/${family_id}`, "_blank");
    }

    return (
        <Page
        className={classes.root}
        title=".:BECAS - ESE:."
        >
            {loaded && <Container>
                <Typography color="textSecondary" gutterBottom variant="h3" style={{textAlign: 'center'}}>
                    {`Estudio socioeconómico`}
                </Typography>

                <MomDadForm
                    currentCustomer={currentCustomer}
                    handleChange={(e) => handleChange(e)}
                    setFamilyData={(data) => {setCurrentCustomer(data)}}
                    family_id={family_id}
                    getFormVerifications={getFormVerifications}
                    estudios
                    observations={observations}
                />
                
                
                <StudentPercentForm 
                    family_id={family_id}
                    formVerification={formVerification}
                    getFormVerifications={getFormVerifications}
                    estudios
                    observations={observations}
                />

                <EconomicDependenciesForm 
                    family_id={family_id}
                    formVerification={formVerification}
                    getFormVerifications={getFormVerifications}
                    estudios
                    observations={observations}
                />

                <Autos 
                    family_id={family_id}
                    formVerification={formVerification}
                    getFormVerifications={getFormVerifications}
                    estudios
                    observations={observations}
                />

                <MonthlyIncome 
                    family_id={family_id}
                    formVerification={formVerification}
                    getFormVerifications={getFormVerifications}
                    estudios
                    observations={observations}
                />
                
                <MonthlyExpense 
                    family_id={family_id}
                    formVerification={formVerification}
                    getFormVerifications={getFormVerifications}
                    estudios
                    observations={observations}
                />

                <BienesDetallados 
                    family_id={family_id}
                    formVerification={formVerification}
                    getFormVerifications={getFormVerifications}
                />
                
                <SaludFamiliar 
                    family_id={family_id}
                    formVerification={formVerification}
                    getFormVerifications={getFormVerifications}
                />
                
                <Interes 
                    family_id={family_id}
                    formVerification={formVerification}
                    getFormVerifications={getFormVerifications}
                />
                
                <InteresTable 
                    family_id={family_id}
                    formVerification={formVerification}
                    getFormVerifications={getFormVerifications}
                />
                
                <ReferenciaFamiliar 
                    family_id={family_id}
                    formVerification={formVerification}
                    getFormVerifications={getFormVerifications}
                />
                
                <Situacion 
                    family_id={family_id}
                    formVerification={formVerification}
                    getFormVerifications={getFormVerifications}
                />
                
                <Fotos 
                    family_id={family_id}
                    formVerification={formVerification}
                    getFormVerifications={getFormVerifications}
                />
            </Container>
            }
            
            <Box my={2} style={{display: 'flex', justifyContent:'space-around', alignItems:'center', margin: 20}}>
                <Button
                    color="primary" size="large" variant="contained"
                    onClick={handleFinishSubmit}
                >
                    Finalizar
                </Button>
                <Button
                    style={{backgroundColor: 'red', color: 'white'}} size="large" variant="contained"
                    onClick={() => { navigate('/estudios/inicio') }}
                >
                    Salir
                </Button>
            </Box>
            
        </Page>
    );
};

export default Estudios;