import React, {useEffect, useState} from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { Page, Text, View, Document, Image, StyleSheet, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import axios from 'src/utils/AxiosHelper';
import Cookies from 'js-cookie';
//Images
import talonPago from 'src/icons/talon_pago.jpg';
import headerSolicitud from 'src/icons/headerSolicitud.jpg';
import banorte from 'src/icons/banorte.jpg';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  section: {
    margin: 10,
    flexGrow: 1,
    flexDirection: 'column'
  },
  title: {
      fontSize: 18,
      textAlign: 'center'
  },
  negritas: {
    fontWeight: '800',
    fontSize: 10
  },
  negritas_italic: {
    fontWeight: '800',
    fontSize: 10,
    fontStyle: 'italic'
  },
  blueHeader: {
      backgroundColor: '#415f8e',
      height: 20,
      width: '100%',
  },
  blueHeader2: {
      backgroundColor: '#415f8e',
      height: 40,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
  },
  table: {
      display: 'flex',
      width: '60%',
      flexDirection: 'row',
      flexWrap: 'wrap'
  },
  tableContent: {
      border: '1',
      borderStyle: 'solid',
      borderColor: 'black',
      width: '50%',
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingLeft: 10
  },
  tableRow: {
      border: '1',
      borderStyle: 'solid',
      borderColor: 'black',
      width: '100%',
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'space-around',
      flexDirection: 'row',
      paddingLeft: 10
  },
  tableText: {
      fontSize: 10
  }
});

let baseURL = "https://api.colmenares.org.mx";
// let baseURL = "http://scholarshipsystem.test";

// Create Document Component
const MyDocument = ({
    family,
    students,
    upStudents,
    heritageAssets,
    monthlyIncomes,
    monthlyExpenses,
    reason,
    settings,
    vehicles,
    economicDependent,
    observations,
    bienesDetallados,
    familyHealth,
    leisureTime,
    refFam,
    situation,
    places,
    pictures,
    sign
}) => {

    const renderImages = () => {
        let buffer = [];
        for (let index = 0; index < pictures.length; index++) {
            buffer.push(
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <View style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                        <Image
                            src={pictures[index] + '?noCache=' + Math.random().toString()}
                            style={{height: 360}}
                            source={{
                                header: {
                                'Access-Control-Allow-Origin': '*'
                                }
                            }}
                        />
                        </View>
                        {((index + 1) <= pictures.length) && <View style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                            <Image
                                src={pictures[index+1] + '?noCache=' + Math.random().toString()}
                                style={{height: 360}}
                                source={{
                                    header: {
                                    'Access-Control-Allow-Origin': '*'
                                    }
                                }}
                            />
                        </View>}
                    </View>
                </Page>
            )
            index++;
        }
        return(
            <>
             {buffer}
            </>
        )
    }

    let date = (situation.hasOwnProperty('form_verification')) ? situation.form_verification.updated_at.split(' ')[0] : '';
    date = date.split('-');
    date = `${date[2]}-${date[1]}-${date[0]}`;

    return(
        <Document>
        
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                        <Image src={headerSolicitud} style={{width: '100%'}}/>
                    </View>
                    <View style={styles.blueHeader2} >
                        <Text style={{textAlign: 'center', color: 'white', fontSize: 25, fontWeight: 'bold'}} > ESTUDIO SOCIOECONÓMICO </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        <Text style={styles.negritas} >
                            Ciclo Escolar: {`${new Date().getFullYear()} - ${new Date().getFullYear()+1}`}
                        </Text>
                        <Text style={styles.negritas} >
                            {'\n'}Familia: {`${family.family_id} ${family.ft_lastname} ${family.mt_lastname} \n\n`}
                            Fecha: {`${date}`}
                        </Text>
                    </View>
                    
                    {/*Inicio datos de la familia */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Datos de la familia </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        <Text style={styles.negritas} >
                            Domicilio particular: {family.address} {'\t\t\n'} CP:{family.pc} {'\n'}
                            Ciudad: {family.town} {'\t\t\n'} Colonia: {family.suburb} {'\t\t\n'} Télefono de casa:{family.phone_number} {''}
                        </Text>
                    </View>

                    {/* Inicio datos del padre */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Datos del padre </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        <Text style={{marginVertical: 5}}>Datos Personales</Text>
                        <Text style={[styles.negritas]} >
                            Nombre completo del padre: {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_name} ${family.ft_lastname} ${family.ft_second_lastname}    \t\n\n`}</Text>
                            Fecha de nacimiento:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_birthdate}    \t\t`}</Text>
                            {' '}Edad:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_age}    \t\t\n\n`}</Text>
                            Celular:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_cellphone_number}    \t\t`}</Text>
                            {' '}Email:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_email}    \t\t\n\n`}</Text>
                            {/* Profesión / Ocupación:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_business_position}    \t\t\n\n`}</Text> */}
                        </Text>
                        <Text style={{marginVertical: 5}}>Datos Laborales</Text>
                        <Text style={[styles.negritas]} >
                            Nombre de la empresa: {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_business_name}    \t\n\n`}</Text>
                            Giro de la empresa:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_business_activity}    \t\t\n\n`}</Text>
                            Puesto que desempeña:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_business_position}    \t\t`}</Text>
                            {' '}Antigüedad:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_business_antiquity}    \t\t\n\n`}</Text>
                            ¿Es dueño o socio de la empresa?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_business_society? (family.ft_business_society === '1') ? 'SI' : 'NO' : 'NO' }    \t\t`}</Text>
                            {' '}¿Qué porcentaje?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_business_percent}    \t\t\n\n`}</Text>
                        </Text>
                    </View>

                    {/* Inicio datos de la madre */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Datos de la madre </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        <Text style={{marginVertical: 5}}>Datos Personales</Text>
                        <Text style={[styles.negritas]} >
                            Nombre completo de la madre: {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_name} ${family.mt_lastname} ${family.mt_second_lastname}    \t\n\n`}</Text>
                            Fecha de nacimiento:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_birthdate}    \t\t`}</Text>
                            {' '}Edad:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_age}    \t\t\n\n`}</Text>
                            Celular:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_cellphone_number}    \t\t`}</Text>
                            {' '}Email:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_email}    \t\t\n\n`}</Text>
                            {/* Profesión / Ocupación:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_business_position}    \t\t\n\n`}</Text> */}
                        </Text>
                        <Text style={{marginVertical: 5}}>Datos Laborales</Text>
                        <Text style={[styles.negritas]} >
                            Nombre de la empresa: {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_business_name}    \t\n\n`}</Text>
                            Giro de la empresa:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_business_activity}    \t\t\n\n`}</Text>
                            Puesto que desempeña:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_business_position}    \t\t`}</Text>
                            {' '}Antigüedad:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_business_antiquity}    \t\t\n\n`}</Text>
                            ¿Es dueña o socia de la empresa?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_business_society? (family.mt_business_society === '1') ? 'SI' : 'NO' : 'NO' }    \t\t`}</Text>
                            {' '}¿Qué porcentaje?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_business_percent}    \t\t\n\n`}</Text>
                        </Text>
                    </View>
                </View>
            </Page>

            <Page size="A4" style={styles.page}>
                <View style={styles.section} >
                    {/* Datos del alumno */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Datos del alumno </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        {students.map((item, key) => (
                            <Text style={[styles.negritas]} key={key}>
                                Nombre completo : {'\t'}
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.name} ${item.lastname} ${item.second_lastname}    \t\t`}</Text>
                                {' '}Colegio : {'\t'}
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.campus.name}    \t\n\n`}</Text>
                                Matricula:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.register}    \t\t`}</Text>
                                {' '}Seccion a ingresar:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.section}    \t\t`}</Text>
                                {' '}Grado a ingresar:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.grade}    \t\t`}</Text>
                                {' '}% de beca solicitado:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.schoolarship_percent}    \t\t\n\n\n\n`}</Text>
                            </Text>
                        ))}
                        {upStudents.map((item, key) => (
                            <Text style={[styles.negritas]} key={key}>
                                Nombre completo : {'\t'}
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.name} ${item.lastname} ${item.second_lastname}    \t\t`}</Text>
                                {' '}Colegio : {'\t'}
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.campus.name}    \t\n\n`}</Text>
                                Matricula:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.register}    \t\t`}</Text>
                                {' '}Seccion a ingresar:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.section}    \t\t`}</Text>
                                {' '}Grado a ingresar:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.grade}    \t\t`}</Text>
                                {' '}% de beca solicitado:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.schoolarship_percent}    \t\t\n\n\n\n`}</Text>
                            </Text>
                        ))}
                    </View>
                </View>
            </Page>
            
            <Page size="A4" style={styles.page}>
                <View style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                    <Image src={headerSolicitud} style={{width: '100%'}}/>
                </View>
                <View style={styles.section} >
                    
                    {/* Bienes patrimoniales */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Bienes patrimoniales </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        <Text style={[styles.negritas]} >
                            ¿Tiene casa propia?: {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${(heritageAssets.house === 1) ? 'SI' : 'NO'}    \t\t`}</Text>
                            {' '}Es hipotecada?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${(heritageAssets.mortgage === 1) ? 'SI' : 'NO'}    \t\t\n\n`}</Text>
                            ¿Es rentada?: {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${(heritageAssets.rent === 1) ? 'SI' : 'NO'}    \t\t`}</Text>
                            {' '}¿Tiene algún condominio?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${(heritageAssets.condominium === 1) ? 'SI' : 'NO'}    \t\t\n\n`}</Text>
                            ¿Tiene algún terreno?: {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${(heritageAssets.ground === 1) ? 'SI' : 'NO'}    \t\t`}</Text>
                            {' '}¿Tiene casa de campo?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${(heritageAssets.cottage === 1) ? 'SI' : 'NO'}    \t\t\n\n`}</Text>
                            ¿Tiene alguna propiedad?: {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${(heritageAssets.property === 1) ? 'SI' : 'NO'}    \t\t`}</Text>
                           ¿Pertenece a algún club?: {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${(heritageAssets.club === 1) ? 'SI' : 'NO'}    \t\t`}</Text>
                            {' '}¿A qué club?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${heritageAssets.club_name}    \t\t\n\n`}</Text>
                        </Text>
                    </View>
                    
                    {/* Ingresos mensuales */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Ingresos mensuales </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        <Text style={[styles.negritas]} >
                            Ingresos del padre(después de impuestos):
                            <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyIncomes.father}    \t\t\n\n`}</Text>
                            Ingresos de la madre(después de impuestos):
                            <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyIncomes.mother}    \t\t\n\n`}</Text>
                            Ingresos en vales de despensa:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyIncomes.pantry_voucher}    \t\t\n\n`}</Text>
                            Otros ingresos:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyIncomes.other}    \t\t\n\n`}</Text>
                            Ingresos totales:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyIncomes.total}    \t\t\n\n`}</Text>
                        </Text>
                    </View>
                    
                    {/* Gastos Mensuales */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Gastos Mensuales </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60, display: 'flex', flexDirection: 'row'}}>
                        <View style={{width: '45%'}}>
                            <Text style={[styles.negritas]} >
                                Alimentación:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.food}    \t\t\n\n`}</Text>
                                Prestamos:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.lending}    \t\t\n\n`}</Text>
                                Agua Potable:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.water}    \t\t\n\n`}</Text>
                                Autos:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.car}    \t\t\n\n`}</Text>
                                Predial:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.predial}    \t\t\n\n`}</Text>
                                Cable:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.cable}    \t\t\n\n`}</Text>
                                Colegiaturas:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.tuition}    \t\t\n\n`}</Text>
                                Servicio doméstico:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.domestic_service}    \t\t\n\n`}</Text>
                                Diversión:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.free_time}    \t\t\n\n`}</Text>
                                Dentista:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.dentist}    \t\t\n\n`}</Text>
                                Seguros:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.assured}    \t\t\n\n`}</Text>
                                Otros gastos:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.other}    \t\t\n\n`}</Text>
                                Desgloce de otros gastos: {`    \t${monthlyExpenses.other_description}    \t\t`}
                            </Text>
                        </View>
                        <View style={{width: '45%'}}>
                            <Text style={[styles.negritas]} >
                                Renta:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.rent}    \t\t\n\n`}</Text>
                                Energía electrica:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.electric_power}    \t\t\n\n`}</Text>
                                Gas:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.gas}    \t\t\n\n`}</Text>
                                Hipoteca:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.mortgage}    \t\t\n\n`}</Text>
                                Telefono:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.phone}    \t\t\n\n`}</Text>
                                Internet:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.internet}    \t\t\n\n`}</Text>
                                Clases o cursos extracurriculares:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.extra_class}    \t\t\n\n`}</Text>
                                Libros:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.book}    \t\t\n\n`}</Text>
                                Médicos:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.med}    \t\t\n\n`}</Text>
                                Vestido:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.clothes}    \t\t\n\n`}</Text>
                                Viajes:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.trip}    \t\t\n\n`}</Text>
                                Gastos totales:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.total}    \t\t\n\n`}</Text>
                            </Text>
                        </View>
                    </View>
                    
                    {/* Motivo de la solicitud de beca */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Motivo de la solicitud de beca </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        <Text style={[styles.negritas]} >
                            Motivo:{`    \t${reason.description}    \t\n\n`}
                        </Text>
                    </View>

                </View>
            </Page>
            
            <Page size="A4" style={styles.page}>
                <View style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                    <Image src={headerSolicitud} style={{width: '100%'}}/>
                </View>
                <View style={styles.section} >

                    {/* Automoviles */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Automoviles </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        {vehicles.map((item, key) => (
                            <Text style={[styles.negritas]} key={key}>
                                Marca : {'\t'}
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.brand}    \t\t`}</Text>
                                Modelo:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.model}    \t\t`}</Text>
                                {' '}Propietario:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.owner}    \t\t`}</Text>
                                {' '}Año:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.year}    \t\t\n\n\n\n`}</Text>
                            </Text>
                        ))}
                    </View>

                    {/* Dependientes economicos */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Dependientes economicos </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        {economicDependent.map((item, key) => (
                            <Text style={[styles.negritas]} key={key}>
                                Nombre : {'\t'}
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.name}    \t\t`}</Text>
                                Parentesco:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.kinship}    \t\t`}</Text>
                                {' '}Ocupación:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.ocupation}    \t\t`}</Text>
                                {' '}Edad:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.age}    \t\t\n\n\n\n`}</Text>
                            </Text>
                        ))}
                    </View>

                    {/* Observaciones */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Observaciones </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        
                        <Text style={[styles.negritas]} >
                            Familias:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${observations.family}    \t\t\n\n`}</Text>
                            Alumnos:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${observations.student}    \t\t\n\n`}</Text>
                            Dependientes economicos:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${observations.economic_dependent}    \t\t\n\n`}</Text>
                            Autos:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${observations.vehicle}    \t\t\n\n`}</Text>
                            Ingresos mensuales:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${observations.monthly_income}    \t\t\n\n`}</Text>
                            Gastos mensuales:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${observations.monthly_expenses}    \t\t\n\n`}</Text>
                        </Text>
                    </View>

                </View>
            </Page>
            
            <Page size="A4" style={styles.page}>
                <View style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                    <Image src={headerSolicitud} style={{width: '100%'}}/>
                </View>
                <View style={styles.section} >
                    {/* Bienes patrimoniales detallados */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Bienes patrimoniales detallados </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60, display: 'flex', flexDirection: 'row'}}>
                        <View style={{width: '45%'}}>
                            <Text style={[styles.negritas]} >
                                Propiedad:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.property}    \t\t\n\n`}</Text>
                                Terreno (Valor estimado):
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.property_price}    \t\t\n\n`}</Text>
                                Ubicación de la propiedad:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.location}    \t\t\n\n`}</Text>
                                <Text style={{marginVertical: 2, fontSize:13}}> {`Datos de la vivienda \n\n`} </Text>
                                Tiempo viviendo en el domicilio:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.permanence}    \t\t\n\n`}</Text>
                                ¿Nº de personas que viven ahí?:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.people}    \t\t\n\n`}</Text>
                                Tipo de vivienda:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.property_type}    \t\t\n\n`}</Text>
                                Estatus de la vivienda:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.property_status}    \t\t\n\n`}</Text>
                                Superficie (mts2):
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.surface}    \t\t\n\n`}</Text>
                                Construcción (mts2):
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.building}    \t\t\n\n`}</Text>
                                Propietario:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.owner}    \t\t\n\n`}</Text>
                                ¿Precio de la construcción?:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.building_price}    \t\t\n\n`}</Text>
                                <Text style={{marginVertical: 5, fontSize:13}}> {`Número de compartimentos \n\n`} </Text>
                                Dormitorios:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.bedroom}    \t\t\n\n`}</Text>
                                Baños:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.bathroom}    \t\t\n\n`}</Text>
                                Salas:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.living_room}    \t\t\n\n`}</Text>
                                Patios:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.yard}    \t\t\n\n`}</Text>
                                Comedores:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.dinning_room}    \t\t\n\n`}</Text>
                            </Text>
                        </View>
                        <View style={{width: '45%'}}>
                            <Text style={[styles.negritas]} >
                                
                                <Text style={{marginVertical: 5, fontSize:13}}> {`Número de mobiliario \n\n`} </Text>
                                TV's:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.tv}    \t\t\n\n`}</Text>
                                PC's:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.computer}    \t\t\n\n`}</Text>
                                DVD / Teatro en casa / Videojuegos:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.dvd_bluray_videogame}    \t\t\n\n`}</Text>
                                Refrigeradores:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.refrigerator}    \t\t\n\n`}</Text>
                                Dispositivos inteligentes:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.smartdevice}    \t\t\n\n`}</Text>
                                Celulares/Tablets:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.cellphone_tablet}    \t\t\n\n`}</Text>
                                
                                <Text style={{marginVertical: 5, fontSize:13}}> {`Número de servicios \n\n`} </Text>
                                Jardinero:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.gardener}    \t\t\n\n`}</Text>
                                Empleado domestico:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.domestic_employee}    \t\t\n\n`}</Text>
                                Conductor:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.driver}    \t\t\n\n`}</Text>
                                Cocinero:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.kitchener}    \t\t\n\n`}</Text>
                                Internet:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.cable_phone_internet}    \t\t\n\n`}</Text>
                                Servicios de streaming:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.streaming_service}    \t\t\n\n`}</Text>
                                Plusvalia del inmueble:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.added_value}    \t\t\n\n`}</Text>
                                Condiciones generales:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.general_conditions}    \t\t\n\n`}</Text>
                                Observaciones:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${bienesDetallados.observation}    \t\t\n\n`}</Text>
                            </Text>
                        </View>
                    </View>

                    {/* Salid familiar */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Salud familiar </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        <Text style={[styles.negritas]} >
                            Estado de la salud de la familia : {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${familyHealth.health_status}    \t\t\n\n`}</Text>
                            ¿Algún miembro cuenta con alguna enfermedad grave?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${familyHealth.member_disease}    \t\t\n\n`}</Text>
                            Tipo de servicio medico que usa la familia:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${familyHealth.medical_service}    \t\t\n\n`}</Text>
                            ¿Cuenta con seguro?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${familyHealth.assured}    \t\t\n\n`}</Text>
                            Costo anual de la póliza de seguros:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${familyHealth.insurance_policy_cost}    \t\t\n\n`}</Text>
                            Suma asegurada de la póliza:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${familyHealth.insured_amount}    \t\t\n\n`}</Text>
                            Observaciones:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${familyHealth.observation}    \t\t\n\n`}</Text>
                        </Text>
                    </View>
                </View>
            </Page>
            
            <Page size="A4" style={styles.page}>
                <View style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                    <Image src={headerSolicitud} style={{width: '100%'}}/>
                </View>
                <View style={styles.section} >
                    {/* Interes cultural */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Interes cultural </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        <Text style={[styles.negritas]} >
                            ¿A qué dedican el tiempo libre? : {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${leisureTime.free_time}    \t\t\n\n`}</Text>
                            ¿Con que frecuencia salen a divertirse?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${leisureTime.free_time_frequency}    \t\t\n\n`}</Text>
                            ¿A dónde acostumbran a ir?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${leisureTime.free_time_place}    \t\t\n\n`}</Text>
                            ¿Con que frecuencia salen de vacaciones? : {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${leisureTime.vacation_frequency}    \t\t\n\n`}</Text>
                            ¿A dónde acostumbran a ir de vacaciones?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${leisureTime.vacation_place}    \t\t\n\n`}</Text>
                            ¿Cuando y a donde fueron la última vez?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${leisureTime.last_vacation}    \t\t\n\n`}</Text>
                            Observaciones:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${leisureTime.observation}    \t\t\n\n`}</Text>
                        </Text>
                        
                    </View>
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Lugares de interes </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        {places.map((item, key) => (
                            <Text style={[styles.negritas]} key={key}>
                                Lugar : {'\t'}
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.place}    \t\t`}</Text>
                                Nombre:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.name}    \t\t`}</Text>
                                {' '}Cargo que ocupa:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.position}    \t\t\n\n`}</Text>
                            </Text>
                        ))}
                    </View>
                    {/* Referencias */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Referencias </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        {refFam.map((item, key) => (
                            <Text style={[styles.negritas]} key={key}>
                                Nombre : {'\t'}
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.name}    \t\t`}</Text>
                                {' '}Telefono:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.phone}    \t\t`}</Text>
                                {' '}Relación:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.relationship}    \t\t\n\n`}</Text>
                            </Text>
                        ))}
                    </View>
                    {/* Situación economica y conclusión */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Situación economica y conclusión </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        <Text style={[styles.negritas]} >
                            Situación economica : {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${situation.situation}    \t\t\n\n\n\n`}</Text>
                            Conclusión:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${situation.conclusion}    \t\t\n\n\n\n`}</Text>
                        </Text>
                    </View>
                </View>
            </Page>

            {renderImages()}
            <Page size="A4" style={styles.page}>
                <View style={styles.blueHeader} >
                    <Text style={{textAlign: 'center', color: 'white', marginTop: 10}} > Firma de la empresa </Text>
                </View>
                <View style={styles.section}>
                    <View style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                    <Image
                        src={sign}
                        style={{height: 360}}
                        source={{
                            header: {
                            'Access-Control-Allow-Origin': '*'
                            }
                        }}
                    />
                    </View>
                </View>
            </Page>


        </Document>
    );
}

const DownloadLink = (props) => {
    const navigate = useNavigate();
    let { family_id, company_id } = useParams();

    const userData = Cookies.getJSON('userData');

    //state
    const [fileName, setFileName] = useState('Estudio.pdf');
    const [family, setFamily] = useState({});
    const [students, setStudents] = useState([]);
    const [upStudents, setUpStudents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [heritageAssets, setHeritageAssets] = useState({});
    const [monthlyIncomes, setMonthlyIncomes] = useState({});
    const [monthlyExpenses, setMonthlyExpenses] = useState({});
    const [reason, setReason] = useState({});
    const [settings, setSettings] = useState({});
    const [vehicles, setVehicles] = useState([]);
    const [economicDependent, setEconomicDependent] = useState([]);
    const [observations, setObservations] = useState({});
    const [bienesDetallados, setBienesDetallados] = useState({});
    const [familyHealth, setFamilyHealth] = useState({});
    const [leisureTime, setLeisureTime] = useState({});
    const [refFam, setRefFam] = useState([]);
    const [situation, setSituation] = useState({});
    const [places, setPlaces] = useState([]);
    const [pictures, setPictures] = useState([]);
    const [sign, setSign] = useState('');

    useEffect(() => {
        getData();
        setFileName(`Estudio-${family_id}.pdf`);
    }, [])

    const getData = async() => {
        await axios.get(`family/heritageAssets/${family_id}`)
        .then(response => {
            if(response.status === 200){
                setHeritageAssets(response.data[0]);
            }
        });

        await axios.get(`family/monthlyIncome/${family_id}`)
        .then(response => {
            if(response.status === 200){
                if(response.data.length > 0){
                    setMonthlyIncomes(response.data[0]);
                }
            }
        });

        await axios.get(`family/monthlyExpense/${family_id}`)
        .then(response => {
            if(response.status === 200){
                if(response.data.length > 0){
                    setMonthlyExpenses(response.data[0]);
                }
            }
        });
        await axios.get(`family/reason/${family_id}`)
        .then(response => {
            if(response.status === 200){
                if(response.data.length > 0){
                    setReason(response.data[0]);
                }
            }
        });
        
        await axios.get(`family/upStudent/${family_id}`)
        .then(async response => {
            if(response.status === 200){
                await setUpStudents(response.data);
            }
        });
        
        await axios.get(`family/studentSchoolarship/${family_id}`)
        .then(async response => {
            if(response.status === 200){
                await setStudents(response.data);
            }
        });
        await axios.get(`auth/settings`)
        .then(async response => {
            if(response.status === 200){
                await setSettings(response.data);
            }
        });
        await axios.get(`family/information/${family_id}`)
        .then(async response => {
            if(response.status === 200){
                await setFamily(response.data);
            }
        });
        await axios.get(`family/vehicle/${family_id}`)
        .then(response => {
            if(response.status === 200){
                setVehicles(response.data);
            }
        })
        await axios.get(`family/economicDependent/${family_id}`)
        .then(response => {
            if(response.status === 200){
                setEconomicDependent(response.data);
            }
        })
        await axios.get(`research/observation/${family_id}`)
        .then(response => {
            if(response.status === 200){
                setObservations(response.data[0]);
            }
        })
        await axios.get(`research/heritageAssetsDetail/${family_id}`)
        .then(response => {
            if(response.status === 200){
                setBienesDetallados(response.data[0]);
            }
        })
        await axios.get(`research/familyHealth/${family_id}`)
        .then(response => {
            if(response.status === 200){
                setFamilyHealth(response.data[0]);
            }
        })
        await axios.get(`research/leisureTime/${family_id}`)
        .then(response => {
            if(response.status === 200){
                setLeisureTime(response.data[0]);
            }
        })
        await axios.get(`research/reference/${family_id}`)
        .then(response => {
            if(response.status === 200){
                setRefFam(response.data);
            }
        })
        await axios.get(`research/cultural/${family_id}`)
        .then(response => {
            if(response.status === 200){
                setPlaces(response.data);
            }
        })
        await axios.get(`research/situationAndConclusion/${family_id}`)
        .then(response => {
            if (response.status === 200) {
                setSituation(response.data[0]);
            }
        });
        await axios.get(`research/image/${family_id}`)
        .then(response => {
            if(response.status === 200){
                var aux = [];
                response.data.map(item => {
                    aux.push(`${baseURL}${item.image}`);
                })
                setPictures(aux);
            }
        })
        await axios.get(`auth/userCompany/${company_id}`)
            .then(async response => {
            await setSign(`${baseURL}${response.data.image}` + '?noCache=' + Math.random().toString());
        })
        await setIsLoading(false);
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            {!isLoading && 
            <PDFViewer style={{width: '100vw', height: '80vh', marginBottom: 15}} >
                <MyDocument 
                    family={family}
                    students={students}
                    upStudents={upStudents}
                    heritageAssets={heritageAssets}
                    monthlyIncomes={monthlyIncomes}
                    monthlyExpenses={monthlyExpenses}
                    reason={reason}
                    settings={settings}
                    vehicles={vehicles}
                    economicDependent={economicDependent}
                    observations={observations}
                    bienesDetallados={bienesDetallados}
                    familyHealth={familyHealth}
                    leisureTime={leisureTime}
                    refFam={refFam}
                    situation={situation}
                    places={places}
                    pictures={pictures}
                    sign={sign}
                />
            </PDFViewer>
            }
            {!isLoading && 
                <PDFDownloadLink 
                    document={
                        <MyDocument 
                            family={family}
                            students={students}
                            upStudents={upStudents}
                            heritageAssets={heritageAssets}
                            monthlyIncomes={monthlyIncomes}
                            monthlyExpenses={monthlyExpenses}
                            reason={reason}
                            settings={settings}
                            vehicles={vehicles}
                            economicDependent={economicDependent}
                            observations={observations}
                            bienesDetallados={bienesDetallados}
                            familyHealth={familyHealth}
                            leisureTime={leisureTime}
                            refFam={refFam}
                            situation={situation}
                            places={places}
                            pictures={pictures}
                            sign={sign}
                        />
                    }
                    style={{backgroundColor: '#415f8e', color: 'white', minWidth: 150, padding: 15, marginTop: 10, borderRadius: 5}}
                    fileName={fileName}>
                    {({ blob, url, loading, error }) => (loading ? 'Cargando documento...' : 'Descargar documento')}
                </PDFDownloadLink>
            }
        </div>
    )
}

export default DownloadLink;