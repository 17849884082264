/**
 * @fileoverview Auth reducer
 * @version 1.0
 * @author César Paul Hernández Camacho
 * @date 30/10/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * 
 */

import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  SEEN_PRIVACY_POLICY,
  SHOW_PRIVACY_POLICY
} from '../actions';

export default (
  state = {
    isAuthenticated: false,
    seenPrivacyPolicy: false,
    showPrivacyPolicy: false,
    authType:1
  },
  action,
) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        authType: action.authType
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false
      };
    case SEEN_PRIVACY_POLICY:
      return{
        ...state,
        seenPrivacyPolicy: true,
        showPrivacyPolicy: false,
      }
    case SHOW_PRIVACY_POLICY:
      return{
        ...state,
        showPrivacyPolicy: true
      }
    default:
      return state;
  }
};
