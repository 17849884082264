import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Button,
  TextField,
  Typography,
  MenuItem
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Page from 'src/components/Page';
import CustomTable from 'src/components/CustomTable/CustomTable';
import axios from 'src/utils/AxiosHelper';
import Toolbar from 'src/components/CustomTable/Toolbar';
import CustomModal from 'src/components/CustomModal';
import swal from 'sweetalert';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import { CSVLink } from "react-csv";
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import SearchBar from 'src/components/SearchBar';
import Cookies from 'js-cookie';

const cols = [
    { accessor: "register", header: "Matricula" },
    { accessor: "name", header: "Nombre" },
    { accessor: "lastname", header: "Apellido paterno" },
    { accessor: "second_lastname", header: "Apellido materno" },
    { accessor: "grade", header: "Grado a ingresar" },
    { accessor: "group", header: "Grupo a ingresar" },
    { accessor: "section", header: "Sección a ingresar" },
    { accessor: "letter", header: "Carta" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Alumnos = () => {
    const classes = useStyles();
    const [rowsData, setRowsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [currectCustomer, setCurrectCustomer] = useState({});
    const [viewModal, setViewModal] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    
    const permission = Cookies.getJSON('permission');
    
    useEffect(() => {
        getUsers();
    }, [])

    const getUsers = () => {
        var aux = [["Matricula", "Nombre", "Apellido paterno", "Apellido materno", "Grado a ingresar", "Grupo a ingresar", "Sección a ingresar", "Carta"]];
        axios.get('auth/student')
        .then(async response => {
            if (response.status === 200) {
                response.data.map(item => {
                    aux.push([item.register, item.name, item.lastname, item.second_lastname, item.grade, item.group, item.section, item.letter]);
                })
                await setExcelData(aux);
                await setRowsData(response.data);
                await setIsLoading(false);
            }
        })
    }

    const onEditAction = async (customer) => {
        await setCurrectCustomer(customer);
        setShowModal(true);
    }

    const onDeleteAction = async(customer) => {
        await swal({
            title: "Estas seguro?",
            text: "Una vez eliminado no se podra recuperar el alumno!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                axios.post(`auth/student/${customer.id}`, {_method: "delete"})
                .then(response => {
                    swal("Alumno eliminado correctamente", {icon: "success"});
                })
            }
        });
        getUsers();
    }

    const handleChange = (e) => {
        setCurrectCustomer({
            ...currectCustomer,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        currectCustomer._method = "put";
        axios.post(`auth/student/${currectCustomer.id}`, currectCustomer)
        .then(response => {
            if(response.status === 200){
                swal("Alumno actualizado correctamente", {icon: "success"});
                getUsers();
                setShowModal(false);
            }
        })
        .catch(error => {
            Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                swal(error.response.data[val][0], {icon: "error"});
            });
        })
    }

    const onShowAction = async(customer) => {
        await setCurrectCustomer(customer);
        setViewModal(true);
    }

    const onChangeSearchField = (value) => {
        if (value.length > 3) {
            setTimeout(() => {
                onSearchPress(value);
            }, 500);
        }
        else {
            setIsSearching(false);
        }
    }

    const onDeleteSearch = () => {
        setIsSearching(false);
    }

    const onSearchPress = async(value) => {
        var aux = [];
        await rowsData.map(item => {
            if (
                (item.register && item.register.toString().includes(value.toLowerCase())) ||
                (item.name && item.name.toLowerCase().includes(value.toLowerCase())) ||
                (item.lastname && item.lastname.toLowerCase().includes(value.toLowerCase())) ||
                (item.second_lastname && item.second_lastname.toLowerCase().includes(value.toLowerCase()))
            ) {
                aux.push(item);
            }
        })
        await setFilteredData(aux);
        await setIsSearching(true);
    }

    return (
        <Page
            className={classes.root}
            title=".:BECAS - Alumnos:."
        >
            <Container maxWidth={false} style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h1">Alumnos</Typography>
                <SearchBar
                    onChange={(value) => {onChangeSearchField(value)}}
                    onDeleteSearch={onDeleteSearch}
                    onSearchPress={(value) => {onSearchPress(value)}}
                >
                    <Button
                        size="small"
                        style={{alignSelf: 'flex-end'}}
                        endIcon={<TableChartOutlinedIcon/>}
                    >
                        <CSVLink
                            data={excelData}
                            filename={"Alumnos.csv"}
                            className="btn btn-primary"
                            target="_blank"
                            style={{height: 0, display: 'flex', color: '#263238',justifyContent: 'center', alignItems: 'center' }}
                        >
                        Exportar
                        </CSVLink>
                    </Button>
                </SearchBar>
                <Box mt={3}>
                    {
                        !isLoading &&
                        <CustomTable
                            cols={cols}
                            rows={isSearching? filteredData : rowsData}
                            actions={true}
                            showAction={(permission.read === 1) ? true : false}
                            updateAction={(permission.update === 1) ? true : false}
                            delAction={(permission.delete === 1) ? true : false}
                            onEdit={(customer) => {onEditAction(customer)}}
                            onDelete={(customer) => {onDeleteAction(customer)}}
                            onShow={(customer) => onShowAction(customer)}
                        />
                    }
                </Box>
            </Container>
            <CustomModal
                open={showModal}
                handleClose={ () => setShowModal(false) }
            >
                <Container>
                    <Box>
                        <Typography color="textSecondary" gutterBottom variant="h3" >
                            Editar alumno
                        </Typography>              
                    </Box>
                    <GridContainer direction="row" alignItems="center" justify="space-between" spacing={2}>
                        <GridItem xs={12} md={6}>
                            <TextField
                            label="Nombre"
                            margin="normal"
                            fullWidth
                            name="name"
                            onChange={(e) => handleChange(e)}
                            value={currectCustomer.name}
                            variant="outlined"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                            label="Apellido paterno"
                            margin="normal"
                            fullWidth
                            name="lastname"
                            onChange={(e) => handleChange(e)}
                            value={currectCustomer.lastname}
                            variant="outlined"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                            label="Apellido materno"
                            margin="normal"
                            fullWidth
                            name="second_lastname"
                            onChange={(e) => handleChange(e)}
                            value={currectCustomer.second_lastname}
                            variant="outlined"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                            label="Sección"
                            margin="normal"
                            fullWidth
                            name="section"
                            onChange={(e) => handleChange(e)}
                            value={currectCustomer.section}
                            variant="outlined"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                            label="Grado"
                            margin="normal"
                            fullWidth
                            name="grade"
                            onChange={(e) => handleChange(e)}
                            value={currectCustomer.grade}
                            variant="outlined"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                            label="Grupo"
                            margin="normal"
                            fullWidth
                            name="group"
                            onChange={(e) => handleChange(e)}
                            value={currectCustomer.group}
                            variant="outlined"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField 
                                select 
                                value={currectCustomer.letter}
                                variant="outlined"
                                fullWidth
                                style={{minWidth: 200}}
                                label="Carta"
                                name="letter"
                                onChange={(e) => handleChange(e)}
                            >
                                <MenuItem value={0} > No </MenuItem>
                                <MenuItem value={1} > SI </MenuItem>
                            </TextField>
                        </GridItem>
                        {/* <GridItem xs={12} md={6}>
                            <TextField
                            label="Last name"
                            margin="normal"
                            fullWidth
                            name="lastName"
                            onChange={(e) => handleChange(e)}
                            value={currectCustomer.lastName}
                            variant="outlined"
                            />
                        </GridItem> */}
                    </GridContainer>
                    <Box my={2}>
                        <Button
                            color="primary"
                            fullWidth
                            size="large"
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Modificar
                        </Button>
                    </Box>
                </Container>
            </CustomModal>

            <CustomModal
                open={viewModal}
                handleClose={() => setViewModal(false)}
            >
                <Container>
                    <Typography variant="h3" component="h2" style={{marginBottom: 20}}>
                        Datos del alumno
                    </Typography>
                    <Typography variant="h4" component="p" color="textSecondary"> Familia </Typography>
                    <Typography className={classes.pos} color="textSecondary"> {currectCustomer.family_id} </Typography>
                    <Typography variant="h4" component="p" color="textSecondary"> Nombre </Typography>
                    <Typography className={classes.pos} color="textSecondary"> {currectCustomer.name} </Typography>
                    <Typography variant="h4" component="p" color="textSecondary"> Apellido paterno </Typography>
                    <Typography className={classes.pos} color="textSecondary"> {currectCustomer.lastname} </Typography>
                    <Typography variant="h4" component="p" color="textSecondary"> Apellido materno </Typography>
                    <Typography className={classes.pos} color="textSecondary"> {currectCustomer.second_lastname} </Typography>
                    <Typography variant="h4" component="p" color="textSecondary"> Registro </Typography>
                    <Typography className={classes.pos} color="textSecondary"> {currectCustomer.register} </Typography>
                    <Typography variant="h4" component="p" color="textSecondary"> Grupo </Typography>
                    <Typography className={classes.pos} color="textSecondary"> {currectCustomer.group} </Typography>
                    <Typography variant="h4" component="p" color="textSecondary"> Grado </Typography>
                    <Typography className={classes.pos} color="textSecondary"> {currectCustomer.grade} </Typography>
                    <Typography variant="h4" component="p" color="textSecondary"> Sección a ingresar </Typography>
                    <Typography className={classes.pos} color="textSecondary"> {currectCustomer.section} </Typography>
                    
                </Container>
            </CustomModal>
        </Page>
    );
};

export default Alumnos;