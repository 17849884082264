import React, {useState, useEffect} from 'react';
import {
    Box,
    Container,
    makeStyles,
    Button,
    Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import CustomTable from 'src/components/CustomTable/CustomTable';
import axios from 'src/utils/AxiosHelper';
import Cookies from 'js-cookie';
import { CSVLink } from "react-csv";
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import SearchBar from 'src/components/SearchBar';
  
  const cols = [
    { accessor: "family_id", header: "Familia" },
    { accessor: "name", header: "Nombre de la familia" },
    { accessor: "terms_and_conditions", header: "Terminos y condiciones" },
    { accessor: "finished", header: "Beca finalizada" },
    { accessor: "updated_at", header: "Fecha de finalización" },
];

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  }));

const AccessosFamilia = (props) => {

    //cookies
    const userData = Cookies.getJSON('userData');

    //estilos
    const classes = useStyles();

    //state
    const [rowsData, setRowsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [excelData, setExcelData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        var rows = [["Familia", "Nombre de la familia", "Terminos y condiciones", "Beca finalizada", "Fecha de finalización"]];
        axios.get(`auth/accessFamily/${userData.campus_id}`)
        .then(async response => {
            if(response.status === 200){
                var aux = [];
                response.data.map((item, key) => {
                    item.updated_at = item.updated_at.split(' ')[0];
                    item.terms_and_conditions = (item.terms_and_conditions === 0)? 'NO' : 'SI';
                    if(item.form_verification){
                        item.finished = (item.form_verification.finished === 0)? 'NO' : 'SI';
                    }
                    rows.push([item.family_id, item.name, item.terms_and_conditions, item.finished, item.updated_at]);
                    aux.push(item);
                })
                await setExcelData(rows);
                await setRowsData(aux);
                await setIsLoading(false);
            }
        })
    }

    const onChangeSearchField = (value) => {
        if (value.length > 3) {
            setTimeout(() => {
                onSearchPress(value);
            }, 500);
        }
        else {
            setIsSearching(false);
        }
    }

    const onDeleteSearch = () => {
        setIsSearching(false);
    }

    const onSearchPress = async(value) => {
        var aux = [];
        await rowsData.map(item => {
            if (
                ( item.name && item.name.toLowerCase().includes(value.toLowerCase())) ||
                ( item.email && item.email.toLowerCase().includes(value.toLowerCase()))
            ) {
                aux.push(item);
            }
        })
        await setFilteredData(aux);
        await setIsSearching(true);
    }

    return(
        <>
        
            <Page
                className={classes.root}
                title=".:BECAS - Accesos de familias:."
            >
                <Container maxWidth={false} style={{ display: 'flex', flexDirection: 'column'}}>
                    <Typography variant="h1">Accessos de familia</Typography>
                    <SearchBar
                        onChange={(value) => {onChangeSearchField(value)}}
                        onDeleteSearch={onDeleteSearch}
                        onSearchPress={(value) => {onSearchPress(value)}}
                    >    
                        <Button
                            size="small"
                            style={{alignSelf: 'flex-end'}}
                            endIcon={<TableChartOutlinedIcon/>}
                        >
                            <CSVLink
                                data={excelData}
                                filename={"Accessos de familia.csv"}
                                className="btn btn-primary"
                                target="_blank"
                                style={{height: 0, display: 'flex', color: '#263238',justifyContent: 'center', alignItems: 'center' }}
                            >
                            Exportar
                            </CSVLink>
                        </Button>
                    </SearchBar>
                    <Box mt={3}>
                        {
                            !isLoading &&
                            <CustomTable
                                cols={cols}
                                rows={isSearching? filteredData : rowsData}
                                actions={false}
                            />
                        }
                    </Box>
                </Container>
            </Page>
        </>
    )
}

export default AccessosFamilia;