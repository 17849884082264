import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import Inicio from 'src/views/Inicio/Inicio';
import RecoveryPass from 'src/views/RecoveryPass/RecoveryPass';
import RecoveryPassA from 'src/views/RecoveryPass/RecoveryPassA';
import RecoveryPassE from 'src/views/RecoveryPass/RecoveryPassE';
//Admin views
import Usuarios from 'src/views/Usuarios/Usuarios';
import Alumnos from 'src/views/Alumnos/Alumnos';
import Empresas from 'src/views/Empresas/Empresas';
import PagareLA from 'src/views/PagareLA/PagareLA';
import UsuariosFamilias from 'src/views/UsuariosFamilias/UsuariosFamilias';
import UpStudents from 'src/views/UpStudents/UpStudents';
import Familias from 'src/views/Familias/Familias';
import SolicitudesTerminadas from 'src/views/SolicitudesTerminadas/SolicitudesTerminadas';
import AccessosFamilia from 'src/views/AccessosFamilia/AccessosFamilia';
import SolicitudesEnCurso from 'src/views/SolicitudesEnCurso/SolicitudesEnCurso';
//Usuarios
import Confirmation from 'src/views/becas/Confirmation';
import LoginUsers from 'src/views/auth/LoginUsers';
import Orfandad from 'src/views/becas/Orfandad';
import Internas from 'src/views/becas/Internas';
import Apoyo from 'src/views/becas/Apoyo';
import ApoyoValidacion from 'src/views/becas/ApoyoValidacion';
//Estudios
import LoginEstudios from 'src/views/auth/LoginEstudios';
import EstudiosTerminados from 'src/views/EstudiosTerminados/EstudiosTerminados';
import AsignacionEstudios from 'src/views/AsignacionEstudios/AsignacionEstudios';
import InicioEmpresa from 'src/views/Estudios/Inicio';
import Estudio from 'src/views/Estudios/Estudio';

//ligas
import SolicitudesTerminada from 'src/components/SolicitudTerminada/SolicitudTerminada';
import RefBancaria from 'src/components/RefBancaria/RefBancaria';
import EstudioTerminado from 'src/components/EstudioTerminado/EstudioTerminado';
import UpValidation from 'src/components/UpValidation/UpValidation';

const routes = (loggedIn, authType) => [
  {
    path: 'app',
    element: (loggedIn && (authType === 2))? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      {
        path: 'consultas',
        children: [
          {path: 'usuarios', element: <Usuarios />},
          {path: 'familias', element: <Familias />},
          {path: 'alumnos', element: <Alumnos />},
          {path: 'solicitudes', element: <SolicitudesTerminadas />},
          {path: 'pagares-los-altos', element: <PagareLA />},
          {path: 'accesos', element: <AccessosFamilia />},
          {path: 'validacion-up', element: <UpStudents />},
          {path: 'solicitues-en-curso', element: <SolicitudesEnCurso />},
          // {path: 'usuarios-admin', element: <UsuariosFamilias />}
        ]
      },
      {
        path: 'config',
        element: <SettingsView />
      },
      {
        path: 'estudios',
        children: [
          {path: 'empresas', element: <Empresas />},
          {path: 'asignacion', element: <AsignacionEstudios />},
          {path: 'terminados', element: <EstudiosTerminados />},
        ]
      },
      
      { path: 'inicio', element: <Inicio />},
      { path: 'account', element: <AccountView /> },
      { path: 'customers', element: <CustomerListView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginUsers /> },
      { path: 'estudios', element: <LoginEstudios /> },
      { path: 'recoveryPassword', element: <RecoveryPass /> },
      { path: 'recoveryPasswordA', element: <RecoveryPassA /> },
      { path: 'recoveryPasswordE', element: <RecoveryPassE /> },
      { path: 'admin', element: <LoginView /> },
      { path: 'confirmation/:id', element: <Confirmation /> },
      { path: 'solicitud/:family_id/:schoolarship', element: <SolicitudesTerminada /> },
      { path: 'estudiofinalizado/:family_id/:company_id', element: <EstudioTerminado /> },
      { path: 'bankReference/:family_id', element: <RefBancaria /> },
      { path: 'upStudentValidation/:id', element: <UpValidation /> },
      //Becas
      {
        path: 'becas',
        children: [
          {path: 'internas', element: (loggedIn && (authType === 1))? <Internas /> : <Navigate to="/login" />},
          {path: 'familia', element: (loggedIn && (authType === 1))? <Apoyo /> : <Navigate to="/login" />},
          {path: 'familiaValidacion', element: (loggedIn && (authType === 1))? <ApoyoValidacion /> : <Navigate to="/login" />},
          {path: 'orfandad', element: (loggedIn && (authType === 1))? <Orfandad /> : <Navigate to="/login" />}
        ]
      },
      //Estudios
      {
        path: 'estudios',
        children: [
          {path: 'inicio', element: (loggedIn && (authType === 3))? <InicioEmpresa /> : <Navigate to="/estudios" />},
          {path: 'form/:family_id', element: (loggedIn && (authType === 3))? <Estudio /> : <Navigate to="/estudios" />},
        ]
      },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
];

export default routes;
