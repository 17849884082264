import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Button,
  TextField,
  Typography,
  MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import CustomTable from 'src/components/CustomTable/CustomTable';
import axios from 'src/utils/AxiosHelper';
import Toolbar from 'src/components/CustomTable/Toolbar';
import CustomModal from 'src/components/CustomModal';
import swal from 'sweetalert';
import Cookies from 'js-cookie';
import { CSVLink } from "react-csv";
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import SearchBar from 'src/components/SearchBar';

const cols = [
    { accessor: "id", header: "ID" },
    { accessor: "name", header: "Nombre" },
    { accessor: "email", header: "Email" },
    { accessor: "created_at", header: "Creación" },
    { accessor: "updated_at", header: "Ultima Actualización" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Usuarios = () => {

    const userRole = Cookies.getJSON('userData');
    const permission = Cookies.getJSON('permission');

    const classes = useStyles();
    const [rowsData, setRowsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [buttonMessage, setButtonMessage] = useState("Agregar");
    const [currentUser, setCurrentUser] = useState({});
    const [colleges, setColleges] = useState([]);
    const [roles, setRoles] = useState([]);
    const [excelData, setExcelData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [userData, setUserData] = useState({
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        role_id: '',
        campus_id: ''
    });
    
    useEffect(() => {
        getUsers();
        getData();
    }, [])

    const getData = () => {
        axios.get('general/campus')
        .then(response => {
            if(response.status === 200){
                if(userRole.role_id === 3){
                    setColleges(response.data.shift());
                }
                else{
                    setColleges(response.data);
                }
            }
        });
        axios.get('auth/role')
        .then(response => {
            if(response.status === 200){
                setRoles(response.data);
            }
        });
    }

    const getUsers = () => {
        var aux = [
            [ "ID", "Nombre", "Email", "Creación", "Ultima Actualización"]
        ];
        axios.get('auth/user')
        .then(response => {
            if (response.status === 200) {
                setRowsData(response.data);
                setIsLoading(false);
                response.data.map(item => {
                    aux.push([ item.id, item.name, item.email, item.created_at, item.updated_at ])
                })
                setExcelData(aux);
            }
        })
    }

    const handleChange = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        })
    }

    const onAddPress = () => {
        setUserData({
            name: "",
            email: "",
            password: "",
            password_confirmation: ""
        });
        setShowModal(true);
        setButtonMessage("Agregar");
    }

    const onEditAction = (customer) => {
        setCurrentUser(customer);
        setUserData({
            name: customer.name,
            email: customer.email,
            password: "",
            password_confirmation: "",
            campus_id: customer.campus_id,
            role_id: customer.role_id
        });
        setButtonMessage("Editar");
        setShowModal(true);
    }

    const onDeleteAction = async(customer) => {
        await swal({
            title: "Estas seguro?",
            text: "Una vez eliminado no se podra recuperar el usuario!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                axios.post(`auth/user/${customer.id}`, {_method: "delete"})
                .then(response => {
                    swal("Usuario eliminado correctamente", {icon: "success"});
                    getUsers();
                })
            }
        });
    }

    const onHandleSubmit = async() => {
        if ((userData.password.length < 6) || (userData.password_confirmation.length < 6)) {
            swal("La contraseña debe tener al menos 6 caracteres", {icon: "error"});
        }
        else {
            if (buttonMessage === "Agregar") {
                await axios.post("auth/register", userData)
                .then(response => {
                    if(response.status === 200){
                        swal("Usuario agregado correctamente", {icon: "success"});
                    }
                })
                .catch(error => {
                    Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                        swal(error.response.data[val][0], {icon: "error"});
                    });
                })
            }
            else {
                var aux = userData;
                aux._method = "put";
                await axios.post(`auth/user/${currentUser.id}`, aux)
                .then(response => {
                    if(response.status === 200){
                        swal("Usuario actualizado correctamente", {icon: "success"});
                    }
                })
                .catch(error => {
                    Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                        swal(error.response.data[val][0], {icon: "error"});
                    });
                })
            }
            setShowModal(false);
            getUsers();
        }
    }

    const onChangeSearchField = (value) => {
        if (value.length > 3) {
            setTimeout(() => {
                onSearchPress(value);
            }, 500);
        }
        else {
            setIsSearching(false);
        }
    }

    const onDeleteSearch = () => {
        setIsSearching(false);
    }

    const onSearchPress = async(value) => {
        var aux = [];
        await rowsData.map(item => {
            if (
                (item.name && item.name.toLowerCase().includes(value.toLowerCase())) ||
                (item.email && item.email.toLowerCase().includes(value.toLowerCase()))
            ) {
                aux.push(item);
            }
        })
        await setFilteredData(aux);
        await setIsSearching(true);
    }

    return (
        <Page
            className={classes.root}
            title=".:BECAS - Usuarios:."
        >
            <Container maxWidth={false} style={{ display: 'flex', flexDirection: 'column'}}>
                <Toolbar onAddPress={onAddPress} />
                <Typography variant="h1">Usuarios</Typography>
                <SearchBar
                    onChange={(value) => {onChangeSearchField(value)}}
                    onDeleteSearch={onDeleteSearch}
                    onSearchPress={(value) => {onSearchPress(value)}}
                >
                    <Button
                        size="small"
                        style={{alignSelf: 'flex-end'}}
                        endIcon={<TableChartOutlinedIcon/>}
                    >
                        <CSVLink
                            data={excelData}
                            filename={"Usuarios.csv"}
                            className="btn btn-primary"
                            target="_blank"
                            style={{height: 0, display: 'flex', color: '#263238',justifyContent: 'center', alignItems: 'center' }}
                        >
                        Exportar
                        </CSVLink>
                    </Button>
                </SearchBar>
                <Box mt={3}>
                    {
                        !isLoading &&
                        <CustomTable
                            cols={cols}
                            rows={isSearching? filteredData : rowsData}
                            data={isSearching? filteredData : rowsData}
                            actions={true}
                            showAction={false}
                            updateAction={(permission.update === 1) ? true : false}
                            delAction={(permission.delete === 1) ? true : false}
                            onEdit={(customer) => {onEditAction(customer)}}
                            onDelete={(customer) => {onDeleteAction(customer)}}
                        />
                    }
                </Box>
            </Container>
            <CustomModal
                open={showModal}
                handleClose={ () => setShowModal(false) }
            >
                <Box mb={3}>
                    <Typography
                        color="textPrimary"
                        variant="h2"
                    >
                        {buttonMessage} usuario
                    </Typography>
                </Box>
                <TextField
                    fullWidth
                    label="Nombre"
                    margin="normal"
                    name="name"
                    onChange={(e) => handleChange(e)}
                    value={userData.name}
                    variant="outlined"
                />
                <TextField
                    fullWidth
                    label="Email"
                    margin="normal"
                    name="email"
                    onChange={(e) => handleChange(e)}
                    value={userData.email}
                    variant="outlined"
                />
                <TextField id="select" select name="role_id" label="Rol" value={userData.role_id} onChange={(e) => handleChange(e)} margin="normal" fullWidth >
                {
                    roles.map((item, key) => {
                    return(
                        <MenuItem key={key} value={item.id} > {item.name} </MenuItem>
                    )
                    })
                }
                </TextField>
                <TextField id="select" select name="campus_id" label="Colegio" value={userData.campus_id} onChange={(e) => handleChange(e)} margin="normal" fullWidth >
                {
                    colleges.map((item, key) => {
                        if( (userData.role_id>2) && (key > 0) ){
                            return(
                                <MenuItem key={key} value={item.sie_id} > {item.name} </MenuItem>
                            )
                        }
                        if( (userData.role_id<3) && (key < 1) ){
                            return(
                                <MenuItem key={key} value={item.sie_id} > {item.name} </MenuItem>
                            )
                        }
                    })
                }
                </TextField>
                <TextField
                    fullWidth
                    label="Contraseña"
                    margin="normal"
                    name="password"
                    type="password"
                    required={true}
                    onChange={handleChange}
                    value={userData.password}
                    variant="outlined"
                />
                <TextField
                    fullWidth
                    label="Confirmar contraseña"
                    margin="normal"
                    name="password_confirmation"
                    required={true}
                    type="password"
                    onChange={(e) => handleChange(e)}
                    value={userData.password_confirmation}
                    variant="outlined"
                />
                <Box my={2}>
                    <Button
                        color="primary"
                        disabled={isSubmitting}
                        size="large"
                        variant="contained"
                        onClick={onHandleSubmit}
                    >
                        {buttonMessage}
                    </Button>
                </Box>
            </CustomModal>
            
        </Page>
    );
};

export default Usuarios;