import React, {useEffect} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Cookies from 'js-cookie';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import axios from 'src/utils/AxiosHelper';
import {useDispatch, useSelector} from 'react-redux';
import {loginUser} from 'src/redux/actions';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import swal from 'sweetalert';

//logos
import logo from 'src/icons/logo.png';
import altamira from 'src/icons/altamira.png';
import beelieve from 'src/icons/beelieve.png';
import laCima from 'src/icons/laCima.png';
import liceo from 'src/icons/liceo.png';
import losAltos from 'src/icons/losAltos.png';
import torre from 'src/icons/torre.png';
import tz from 'src/icons/tz.png';
import up from 'src/icons/up.png';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginEstudios = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if(isAuthenticated){
      navigate('/app/inicio', { replace: true });
    }
  }, [])

  //redux
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)

  return (
    <Page
      className={classes.root}
      title=".:BECAS - Inicio de sesión:."
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm" style={{display: 'flex', flexDirection: 'column'}}>
          <GridContainer direction="row" alignItems="center" justifyContent="center" >
            <GridItem container direction="column" xs={12} alignItems="center" justifyContent="center">
              <Typography color="textPrimary" variant="h2" style={{textAlign: "center", marginTop:20}} >
                Estudios socioeconómicos
              </Typography>
            </GridItem>
            <GridItem container direction="column" xs={3} alignItems="center" justifyContent="center">
              <img src={altamira} style={{width: 100, maxWidth: '25vw',marginTop: 8, alignSelf: 'center'}}/>
              <img src={up} style={{width: 80, maxWidth: '20vw',marginTop: 8, alignSelf: 'center'}}/>
              <img src={laCima} style={{width: 100, maxWidth: '25vw',marginTop: 8, alignSelf: 'center'}}/>
              <img src={liceo} style={{width: 120, maxWidth: '25vw',marginTop: 8, alignSelf: 'center'}}/>
            </GridItem>
            <GridItem container direction="column" xs={6} alignItems="center" justifyContent="center">
              <img src={logo} style={{width: 180, alignSelf: 'center'}}/>
              <Typography color="textPrimary" variant="h3" style={{textAlign: "center", marginTop:20}} >
                Iniciar sesión
              </Typography>
            </GridItem>
            <GridItem container direction="column" xs={3} alignItems="center" justifyContent="center">
              <img src={losAltos} style={{width: 100, maxWidth: '20vw',marginTop: 8, alignSelf: 'center'}}/>
              <img src={torre} style={{width: 60, maxWidth: '20vw',marginTop: 8, alignSelf: 'center'}}/>
              <img src={beelieve} style={{width: 100, maxWidth: '20vw',marginTop: 8, alignSelf: 'center'}}/>
              <img src={tz} style={{width: 75, maxWidth: '20vw',marginTop: 8, alignSelf: 'center'}}/>
            </GridItem>

          </GridContainer>
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('El email debe ser un email valido').max(255).required('El email es requerido'),
              password: Yup.string().max(255).required('La contraseña es requerida')
            })}
            onSubmit={(values, { setSubmitting }) => {
              axios.post('research/loginCompany', values)
              .then( async(response) => {
                if (response.status === 200) {
                  await Cookies.set('userData', response.data.user[0], { expires: 1 });
                  await dispatch(loginUser(3));
                  navigate('/estudios/inicio', { replace: true });
                }
              })
              .catch(error => {
                swal(error.response.data.message, {icon: "error"})
              });
              setSubmitting(false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
            <form onSubmit={(e) => {
                e.preventDefault()
                handleSubmit();              
              }}>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Correo"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Contraseña"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Typography>
                  <RouterLink to="/recoveryPasswordE">¿Olvidaste tu contraseña?</RouterLink>
                </Typography>
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Iniciar sesión
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginEstudios;
