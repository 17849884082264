import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import {
  AppBar,
  Grid,
  Box,
  Typography,
  IconButton,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import {useDispatch, useSelector} from 'react-redux';
import { logoutUser } from 'src/redux/actions';
import SchoolIcon from '@material-ui/icons/School';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  
  const navigate = useNavigate();
  const classes = useStyles();
  const [notifications] = useState([]);
  
  //redux
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  /**
   * Function to logOut of the session
   * @memberof TopBar
   */
  const handleLogOut = () => {
    dispatch(logoutUser());
    const userData = Cookies.getJSON('userData');
    if(userData.hasOwnProperty('email')){
      navigate('/estudios', { replace: true });
    }
    else{
      navigate('/login', { replace: true });
    }
    Cookies.remove('userData');
  }

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <Grid container item direction="row" xs={12} justify="center" alignItems="center" >
          <SchoolIcon />
          <Typography color="white" variant="h3" style={{textAlign: "center", marginLeft: 10}} >
            Becas {new Date().getFullYear()} - {new Date().getFullYear()+1}
          </Typography>
        </Grid>
        <Box flexGrow={1} />
        {isAuthenticated && <IconButton color="inherit" onClick={handleLogOut} >
          <InputIcon />
        </IconButton>}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
