import React, {useEffect, useState} from 'react';
import {
    Box,
    Container,
    Button,
    TextField,
    Typography,
    MenuItem,
    Input,
    InputLabel
  } from '@material-ui/core';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import axios from 'src/utils/AxiosHelper';
import municipiosJalisco from 'src/utils/municipiosJalisco.json';

const FormFamilias = ({
    currentCustomer,
    handleChange,
    handleSubmit,
    handleChangeUser
}) => {

    
    const [businessActibity, setBusinessActibity] = useState([]);
    const [professions, setProfessions] = useState([]);

    useEffect(() => {
        getFamilyData();
    }, [])

    const getFamilyData = () => {
        axios.get(`general/businessActivity`)
        .then(response => {
            if(response.status === 200){
                setBusinessActibity(response.data);
            }
        })
        axios.get(`general/profession`)
        .then(response => {
            if(response.status === 200){
                setProfessions(response.data);
            }
        })
    }

    return(
        <Container>
            <Box>
                <Typography color="textSecondary" gutterBottom variant="h3" >
                    Editar datos de familia
                </Typography>
            </Box>
            {/* Datos del padre */}
            <GridContainer direction="row" alignItems="center" justify="space-between" spacing={2}>
                <GridItem xs={12} >
                    <Typography color="textSecondary" gutterBottom variant="h4" >
                        Datos del padre
                    </Typography>
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="Nombre"
                    margin="normal"
                    fullWidth
                    name="ft_name"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.ft_name}
                    variant="outlined"
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="Apellido paterno"
                    margin="normal"
                    fullWidth
                    name="ft_lastname"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.ft_lastname}
                    variant="outlined"
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="Apellido materno"
                    margin="normal"
                    fullWidth
                    name="ft_second_lastname"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.ft_second_lastname}
                    variant="outlined"
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="Nombre de la empresa"
                    margin="normal"
                    fullWidth
                    name="ft_business_name"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.ft_business_name}
                    variant="outlined"
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField select label={"Giro de la empresa"}
                        value={currentCustomer.ft_business_activity} 
                        name="ft_business_activity"
                        onChange={(e) => handleChange(e)} 
                        margin="normal" 
                        fullWidth 
                    >
                    {
                        professions.map((item, key) => {
                            return(
                                <MenuItem key={key} value={item.name} > {item.name} </MenuItem>
                            )
                        })
                    }
                    </TextField>
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="Antiguedad de la empresa"
                    margin="normal"
                    fullWidth
                    name="ft_business_antiquity"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.ft_business_antiquity}
                    variant="outlined"
                    />
                </GridItem>                
                <GridItem xs={12} md={6}>
                    <TextField select label={"Puesto de la empresa"}
                        value={currentCustomer.ft_business_position} 
                        name="ft_business_position"
                        onChange={(e) => handleChange(e)} 
                        margin="normal" 
                        fullWidth 
                    >
                    {
                        professions.map((item, key) => {
                            return(
                                <MenuItem key={key} value={item.name} > {item.name} </MenuItem>
                            )
                        })
                    }
                    </TextField>
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField select 
                        label="¿Es dueño o socio de la empresa?"
                        value={currentCustomer.ft_business_society} 
                        name="ft_business_society"
                        onChange={(e) => handleChange(e)} 
                        margin="normal" 
                        fullWidth 
                    >
                        <MenuItem value={"NO"} > {"NO"} </MenuItem>
                        <MenuItem value={"SI"} > {"SI"} </MenuItem>
                    </TextField>
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="¿En qué porcentaje?"
                    margin="normal"
                    fullWidth
                    name="ft_business_percent"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.ft_business_percent}
                    variant="outlined"
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="Teléfono celular"
                    margin="normal"
                    fullWidth
                    name="ft_cellphone_number"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.ft_cellphone_number}
                    variant="outlined"
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <InputLabel shrink>Fecha de nacimiento del padre</InputLabel>
                    <Input 
                        label="Fecha de inicio"
                        margin="normal" fullWidth type="date"
                        name="ft_birthdate" value={currentCustomer.ft_birthdate}
                        onChange={(e) => handleChange(e)}
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="Email"
                    margin="normal"
                    fullWidth
                    name="ft_email"
                    error={!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(currentCustomer.ft_email)}
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.ft_email}
                    variant="outlined"
                    />
                </GridItem>
            </GridContainer>
            
            {/* Datos de la madre */}
            <GridContainer direction="row" alignItems="center" justify="space-between" spacing={2}>
                <GridItem xs={12}>
                    <Typography color="textSecondary" gutterBottom variant="h4" >
                        Datos de la madre
                    </Typography>
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="Nombre"
                    margin="normal"
                    fullWidth
                    name="mt_name"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.mt_name}
                    variant="outlined"
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="Apellido paterno"
                    margin="normal"
                    fullWidth
                    name="mt_lastname"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.mt_lastname}
                    variant="outlined"
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="Apellido materno"
                    margin="normal"
                    fullWidth
                    name="mt_second_lastname"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.mt_second_lastname}
                    variant="outlined"
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="Nombre de la empresa"
                    margin="normal"
                    fullWidth
                    name="mt_business_name"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.mt_business_name}
                    variant="outlined"
                    />
                </GridItem>                
                <GridItem xs={12} md={6}>
                    <TextField select label={"Giro de la empresa"}
                        value={currentCustomer.mt_business_activity} 
                        name="mt_business_activity"
                        onChange={(e) => handleChange(e)} 
                        margin="normal" 
                        fullWidth 
                    >
                    {
                        professions.map((item, key) => {
                            return(
                                <MenuItem key={key} value={item.name} > {item.name} </MenuItem>
                            )
                        })
                    }
                    </TextField>
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="Antiguedad de la empresa"
                    margin="normal"
                    fullWidth
                    name="mt_business_antiquity"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.mt_business_antiquity}
                    variant="outlined"
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField select label={"Puesto de la empresa"}
                        value={currentCustomer.mt_business_position} 
                        name="mt_business_position"
                        onChange={(e) => handleChange(e)} 
                        margin="normal" 
                        fullWidth 
                    >
                    {
                        professions.map((item, key) => {
                            return(
                                <MenuItem key={key} value={item.name} > {item.name} </MenuItem>
                            )
                        })
                    }
                    </TextField>
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField select 
                        label="¿Es dueña o socia de la empresa?"
                        value={currentCustomer.mt_business_society} 
                        name="mt_business_society"
                        onChange={(e) => handleChange(e)} 
                        margin="normal" 
                        fullWidth 
                    >
                        <MenuItem value={"NO"} > {"NO"} </MenuItem>
                        <MenuItem value={"SI"} > {"SI"} </MenuItem>
                    </TextField>
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="¿En qué porcentaje?"
                    margin="normal"
                    fullWidth
                    name="mt_business_percent"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.mt_business_percent}
                    variant="outlined"
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="Teléfono celular"
                    margin="normal"
                    fullWidth
                    name="mt_cellphone_number"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.mt_cellphone_number}
                    variant="outlined"
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <InputLabel shrink>Fecha de nacimiento de la madre</InputLabel>
                    <Input 
                        label="Fecha de inicio"
                        margin="normal" fullWidth type="date"
                        name="mt_birthdate" value={currentCustomer.mt_birthdate}
                        onChange={(e) => handleChange(e)}
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="Email"
                    margin="normal"
                    fullWidth
                    error={!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(currentCustomer.mt_email)}
                    name="mt_email"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.mt_email}
                    variant="outlined"
                    />
                </GridItem>
            </GridContainer>
            
            {/* Datos de la familia */}
            <GridContainer direction="row" alignItems="center" justify="space-between" spacing={2}>
                <GridItem xs={12} >
                    <Typography color="textSecondary" gutterBottom variant="h4" >
                        Datos familia
                    </Typography>
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="Dirección"
                    margin="normal"
                    fullWidth
                    name="address"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.address}
                    variant="outlined"
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="Colonia"
                    margin="normal"
                    fullWidth
                    name="suburb"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.suburb}
                    variant="outlined"
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="Teléfono"
                    margin="normal"
                    fullWidth
                    name="phone_number"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.phone_number}
                    variant="outlined"
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="Código postal"
                    margin="normal"
                    fullWidth
                    name="pc"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.pc}
                    variant="outlined"
                    />
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField select 
                        required
                        InputLabelProps={{ shrink: true }}
                        label="Municipio"
                        margin="normal"
                        fullWidth
                        name="town"
                        onChange={(e) => handleChange(e)}
                        value={currentCustomer.town}
                        fullWidth 
                    >
                        {municipiosJalisco.map((municipio) => (
                            <MenuItem value={municipio.nombre_municipio} > {municipio.nombre_municipio} </MenuItem>
                        ))}
                    </TextField>
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField
                    label="Referencia bancaria"
                    margin="normal"
                    fullWidth
                    name="bank_reference"
                    onChange={(e) => handleChange(e)}
                    value={currentCustomer.bank_reference}
                    variant="outlined"
                    />
                </GridItem>
            </GridContainer>
            
            {/* Datos del usuario */}
            <GridContainer direction="row" alignItems="center" justify="space-between" spacing={2}>
                <GridItem xs={12} >
                    <Typography color="textSecondary" gutterBottom variant="h4" >
                        Datos usuario
                    </Typography>
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField select 
                        margin="normal" fullWidth label="Acceso total"
                        value={currentCustomer.user.full_access}  name="full_access"
                        onChange={(e) => handleChangeUser(e)} 
                    >
                        <MenuItem value={0} > {"NO"} </MenuItem>
                        <MenuItem value={1} > {"SI"} </MenuItem>
                    </TextField>
                </GridItem>
                <GridItem xs={12} md={6}>
                    <TextField select 
                        margin="normal" fullWidth label="Fechas individuales"
                        value={currentCustomer.user.individual_dates}  name="individual_dates"
                        onChange={(e) => {
                            handleChangeUser(e)
                        }} 
                    >
                        <MenuItem value={0} > {"NO"} </MenuItem>
                        <MenuItem value={1} > {"SI"} </MenuItem>
                    </TextField>
                </GridItem>
                {(currentCustomer.user.individual_dates === 1) &&
                    <GridItem xs={12} md={6}>
                        <InputLabel shrink>Fecha de inicio</InputLabel>
                        <Input 
                            label="Fecha de inicio"
                            margin="normal" fullWidth type="date"
                            name="start_date" value={currentCustomer.user.start_date}
                            onChange={(e) => handleChangeUser(e)}
                        />
                    </GridItem>
                }
                {(currentCustomer.user.individual_dates === 1) &&
                    <GridItem xs={12} md={6}>
                        <InputLabel shrink>Fecha final</InputLabel>
                        <Input 
                            label="Fecha de inicio"
                            margin="normal" fullWidth type="date"
                            name="end_date" value={currentCustomer.user.end_date}
                            onChange={(e) => handleChangeUser(e)}
                        />
                    </GridItem>
                }
                <GridItem xs={12} md={6}>
                    <TextField
                    variant="outlined" margin="normal" fullWidth label="Observaciones"
                    name="observation" value={currentCustomer.user.observation}
                    onChange={(e) => handleChangeUser(e)}
                    />
                </GridItem>
            </GridContainer>

            <Box my={2}>
                <Button
                    color="primary"
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={handleSubmit}
                >
                    Modificar
                </Button>
            </Box>
        </Container>
    )
}

export default FormFamilias;