/**
 * @fileoverview Container Grid Component
 * @version 1.1
 * @author César Paul Hernández Camacho
 * @date 11/11/2020
 * @copyright 2020 Industrias RESSER S.A de Camacho
 * @update 30/11/2020
 */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = {
  grid: {
    margin: "0 -15px !important",
    width: "auto"
  }
};

const useStyles = makeStyles(styles);

export default function GridContainer(props) {
  const classes = useStyles();
  const { children, ...rest } = props;
  return (
    <Grid container {...rest} className={`${classes.grid} ${props.className}`}>
      {children}
    </Grid>
  );
}

GridContainer.propTypes = {
  children: PropTypes.node
};
