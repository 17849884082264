import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
  makeStyles
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const NavItem = ({
  className,
  data,
  href,
  icon: Icon,
  title,
  subModules,
  ...rest
}) => {
  const classes = useStyles();

  const renderData = () => {
    if (subModules === null) {
      return (
        <Button
          activeClassName={classes.active}
          className={classes.button}
          component={RouterLink}
          to={href}
          style={{paddingLeft: 15}}
        >
          {Icon && (
            <Icon
              className={classes.icon}
              size="20"
            />
          )}
          <span className={classes.title}>
            {title}
          </span>
        </Button>
      )
    }
    else {
      return(
        <Accordion style={{width: '100%', boxShadow: '0 0 0 0px transparent, 0 1px 2px 0 transparent'}}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            
            <Button
              activeClassName={classes.active}
              className={classes.button}
              style={{marginLeft: -10}}
            >
              {Icon && (
                <Icon
                  className={classes.icon}
                  size="20"
                />
              )}
              <span className={classes.title}>
                {title}
              </span>
            </Button>
          </AccordionSummary>
          <AccordionDetails style={{display: 'flex', flexDirection: 'column'}} >
            {subModules.map((item, key) => {
              return(
                <ListItem
                  className={clsx(classes.item, className)}
                  disableGutters
                  key={`${key}`}
                >
                  <RenderButtons
                    href={item.href}
                    icon={item.icon}
                    title={item.title}
                  />
                </ListItem>
              )
            })}
          </AccordionDetails>
        </Accordion>
      )
    }
  }

  const RenderButtons = ({
    href,
    icon: Icon,
    title,
  }) => {
    
      return (
        <Button
          activeClassName={classes.active}
          className={classes.button}
          component={RouterLink}
          to={href}
        >
          {Icon && (
            <Icon
              className={classes.icon}
              size="20"
            />
          )}
          <span className={classes.title}>
            {title}
          </span>
        </Button>
      )
  }

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters
      {...rest}
    >
      {renderData()}
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
