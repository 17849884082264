import React, {useEffect, useState} from 'react';
import {
    Button,
    TextField,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    MenuItem
} from '@material-ui/core';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import axios from 'src/utils/AxiosHelper';
import Cookies from 'js-cookie';
import swal from 'sweetalert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const initalData = {
    family_id: '',
    house: 0,
    mortgage: 0,
    rent: 0,
    condominium: 0,
    ground: 0,
    cottage: 0,
    property: 0,
    club: 0,
    club_name: 0,
}

const HeritageAssets = ({
    family_id,
    formVerification,
    getFormVerifications
}) => {

    useEffect(() => {
        getUsersData();
    }, [])

    //state
    const [userData, setUserData] = useState(initalData);
    const [requestType, setRequestType] = useState(0); // 0 post 1 put

    const handleSubmit = () => {
        userData.family_id=family_id;
        var url = 'family/heritageAssets';
        if(requestType===1){
            userData._method = "put";
            url += `/${family_id}`
        }
        axios.post(url, userData)
        .then(response => {
            if(response.status === 200){
                axios.post(`family/formVerification/${family_id}`, {heritage_assets: 1, _method: "put"})
                .then(response => {
                    if(response.status === 200){
                        swal("Datos de los alumnos actualizados correctamente", {icon: "success"});
                        getFormVerifications();
                    }
                })
            }
        })
        .catch(error => {
            Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                swal(error.response.data[val][0], {icon: "error"});
            });
        })
    }

    const handleChange = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        })
    }

    const getUsersData = () => {
        axios.get(`family/heritageAssets/${family_id}`)
        .then(response => {
            if(response.status === 200){
                if(response.data.length > 0){
                    setRequestType(1);
                    setUserData(response.data[0]);
                }
            }
        })
    }

    return (
        <form onSubmit={e => {
            e.preventDefault();
            handleSubmit();
        }}>
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                    Bienes patrimoniales
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {/* Datos de la familia */}
                <GridContainer  alignItems="center" justify="center" spacing={2} style={{width: '100%'}}>
                    <GridItem xs={12} >
                        <TextField 
                            select label="¿Tiene casa propia?" 
                            name="house"
                            value={userData.house} 
                            onChange={handleChange} margin="normal" fullWidth 
                        >
                            <MenuItem value={0} > No </MenuItem>
                            <MenuItem value={1} > Si </MenuItem>
                        </TextField>
                    </GridItem>
                    <GridItem xs={12} >
                        <TextField 
                            select label="Es hipotecada?" 
                            name="mortgage"
                            value={userData.mortgage} 
                            onChange={handleChange} margin="normal" fullWidth 
                        >
                            <MenuItem value={0} > No </MenuItem>
                            <MenuItem value={1} > Si </MenuItem>
                        </TextField>
                    </GridItem>
                    <GridItem xs={12} >
                        <TextField 
                            select label="Es rentada?" 
                            name="rent"
                            value={userData.rent} 
                            onChange={handleChange} margin="normal" fullWidth 
                        >
                            <MenuItem value={0} > No </MenuItem>
                            <MenuItem value={1} > Si </MenuItem>
                        </TextField>
                    </GridItem>
                    <GridItem xs={12} >
                        <TextField 
                            select label="Tiene algún condominio?" 
                            name="condominium"
                            value={userData.condominium} 
                            onChange={handleChange} margin="normal" fullWidth 
                        >
                            <MenuItem value={0} > No </MenuItem>
                            <MenuItem value={1} > Si </MenuItem>
                        </TextField>
                    </GridItem>
                    <GridItem xs={12} >
                        <TextField 
                            select label="¿Tiene algún terreno?" 
                            name="ground"
                            value={userData.ground} 
                            onChange={handleChange} margin="normal" fullWidth 
                        >
                            <MenuItem value={0} > No </MenuItem>
                            <MenuItem value={1} > Si </MenuItem>
                        </TextField>
                    </GridItem>
                    <GridItem xs={12} >
                        <TextField 
                            select label="¿Tiene casa de campo?" 
                            name="cottage"
                            value={userData.cottage} 
                            onChange={handleChange} margin="normal" fullWidth 
                        >
                            <MenuItem value={0} > No </MenuItem>
                            <MenuItem value={1} > Si </MenuItem>
                        </TextField>
                    </GridItem>
                    <GridItem xs={12} >
                        <TextField 
                            select label="¿Tiene otra propiedad?" 
                            name="property"
                            value={userData.property} 
                            onChange={handleChange} margin="normal" fullWidth 
                        >
                            <MenuItem value={0} > No </MenuItem>
                            <MenuItem value={1} > Si </MenuItem>
                        </TextField>
                    </GridItem>
                    <GridItem xs={12} >
                        <TextField 
                            select label="Pertenece a algún club?" 
                            name="club"
                            value={userData.club} 
                            onChange={handleChange} margin="normal" fullWidth 
                        >
                            <MenuItem value={0} > No </MenuItem>
                            <MenuItem value={1} > Si </MenuItem>
                        </TextField>
                    </GridItem>
                    <GridItem xs={12} >
                        <TextField
                            fullWidth
                            label="Nombre del club"
                            margin="normal"
                            name="club_name"
                            required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.club_name}
                            variant="outlined"
                        />
                    </GridItem>
                    <GridItem xs={12} style={{display: 'flex'}} alignItems="center" justify="center" >
                        <Button
                            color="primary"
                            size="large"
                            variant="contained"
                            type="submit"
                        >
                            Guardar
                        </Button>
                    </GridItem>
                </GridContainer>
            </AccordionDetails>
        </Accordion>
        </form>
    )
}

export default HeritageAssets;