import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Button,
  TextField,
  Typography,
  Grid,
  MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import CustomTable from 'src/components/CustomTable/CustomTable';
import axios from 'src/utils/AxiosHelper';
import CustomModal from 'src/components/CustomModal';
import swal from 'sweetalert';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import { CSVLink } from "react-csv";
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import SearchBar from 'src/components/SearchBar';
import Cookies from 'js-cookie';

const cols = [
    {accessor: "register", header: "Registro"},
    {accessor: "family_id", header: "Familia"},
    {accessor: "lastname", header: "Apellido paterno"},
    {accessor: "second_lastname", header: "Apellido Materno"},
    {accessor: "name", header: "Nombre"},
    {accessor: "semester", header: "Semestre"},
    {accessor: "career", header: "Carrera"},
];

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    card: {
        width: "100%",
        minHeight: "20vh",
    },
    pos: {
        marginBottom: 10
    },
}));

const UpStudents = () => {
    const classes = useStyles();
    const [rowsData, setRowsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [currentCustomer, setCurrectCustomer] = useState({});
    const [excelData, setExcelData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    const permission = Cookies.getJSON('permission');
    const userData = Cookies.getJSON('userData');
    
    useEffect(() => {
        getUsers();
    }, [])

    const getUsers = () => {
        var aux = [["Registro", "Familia", "Apellido paterno", "Apellido Materno", "Nombre", "Semestre", "Carrera"]];
        axios.get('auth/upStudent')
        .then(async response => {
            if (response.status === 200) {
                response.data.map(item => {
                    item.career = item.career? item.career.name : '';
                    aux.push([item.register, item.family_id, item.lastname, item.second_lastname, item.name, item.semester, item.career]);
                })
                await setExcelData(aux);
                await setRowsData(response.data);
                await setIsLoading(false);
            }
        })
    }

    const onShowAction = async (customer) => {
        console.log(customer)
        await setCurrectCustomer(customer);
        setViewModal(true);
    }

    const onEditAction = async (customer) => {
        await setCurrectCustomer(customer);
        setShowModal(true);
    }

    const handleChange = (e) => {
        setCurrectCustomer({
            ...currentCustomer,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        currentCustomer._method = "put";
        axios.post(`auth/upStudent/${currentCustomer.id}`, currentCustomer)
        .then(response => {
            if(response.status === 200){
                
                axios.post(`general/upStudent/${currentCustomer.id}`, {up_status_id: currentCustomer.up_status_id}, 'put')
                .then(response => {
                    if(response.status === 200){
                        swal("Correcto", "Alumno validado con exito", "success");
                    }
                });
                swal("Alumno actualizado correctamente", {icon: "success"});
                getUsers();
                setShowModal(false);
            }
        })
        .catch(error => {
            Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                swal(error.response.data[val][0], {icon: "error"});
            });
        })
        
    }

    const onChangeSearchField = (value) => {
        if (value.length > 3) {
            setTimeout(() => {
                onSearchPress(value);
            }, 500);
        }
        else {
            setIsSearching(false);
        }
    }

    const onDeleteSearch = () => {
        setIsSearching(false);
    }

    const onSearchPress = async(value) => {
        var aux = [];
        await rowsData.map(item => {
            if (
                ( item.register && item.register.toString().includes(value)) ||
                ( item.family_id && item.family_id.toString().includes(value)) ||
                ( item.lastname && item.lastname.toLowerCase().includes(value.toLowerCase())) ||
                ( item.second_lastname && item.second_lastname.toLowerCase().includes(value.toLowerCase())) ||
                ( item.name && item.name.toLowerCase().includes(value.toLowerCase()))
            ) {
                aux.push(item);
            }
        })
        await setFilteredData(aux);
        await setIsSearching(true);
    }

    const onDeleteAction = async(customer) => {
        await swal({
            title: "Estas seguro?",
            text: "Una vez eliminado no se podra recuperar el alumno!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                axios.post(`auth/upStudent/${customer.id}`, {_method: "delete"})
                .then(response => {
                    swal("Alumno eliminado correctamente", {icon: "success"});
                    getUsers();
                })
            }
        });
    }

    return (
        <Page
            className={classes.root}
            title=".:BECAS - Alumnos UP:."
        >
            <Container maxWidth={false} style={{ display: 'flex', flexDirection: 'column'}}>
                <Typography variant="h1">Alumnos UP</Typography>
                <SearchBar
                    onChange={(value) => {onChangeSearchField(value)}}
                    onDeleteSearch={onDeleteSearch}
                    onSearchPress={(value) => {onSearchPress(value)}}
                >
                    <Button
                        size="small"
                        style={{alignSelf: 'flex-end'}}
                        endIcon={<TableChartOutlinedIcon/>}
                    >
                        <CSVLink
                            data={excelData}
                            filename={"Accessos de familia.csv"}
                            className="btn btn-primary"
                            target="_blank"
                            style={{height: 0, display: 'flex', color: '#263238',justifyContent: 'center', alignItems: 'center' }}
                        >
                        Exportar
                        </CSVLink>
                    </Button>
                </SearchBar>
                <Box mt={3}>
                    {
                        !isLoading &&
                        <CustomTable
                            cols={cols}
                            rows={isSearching? filteredData : rowsData}
                            actions={true}
                            showAction={(permission.read === 1) ? true : false}
                            updateAction={(permission.update === 1) ? true : (userData.campus_id === 99)? true : false}
                            delAction={(permission.delete === 1) ? true : false}
                            onEdit={(customer) => {onEditAction(customer)}}
                            onShow={(customer) => onShowAction(customer)}
                            onDelete={(customer) => {onDeleteAction(customer)}}
                        />
                    }
                </Box>
            </Container>
            <CustomModal
                open={showModal}
                handleClose={ () => setShowModal(false) }
            >
                <Container>
                    <Box>
                        <Typography color="textSecondary" gutterBottom variant="h3" >
                            Editar alumno
                        </Typography>              
                    </Box>
                    <GridContainer direction="row" alignItems="center" justify="space-between" spacing={2}>
                        <GridItem xs={12} md={6}>
                            <TextField
                            label="Nombre"
                            margin="normal"
                            fullWidth
                            disabled={(userData.campus_id === 99)? true : false}
                            name="name"
                            onChange={(e) => handleChange(e)}
                            value={currentCustomer.name}
                            variant="outlined"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                            label="Apellido paterno"
                            margin="normal"
                            fullWidth
                            disabled={(userData.campus_id === 99)? true : false}
                            name="lastname"
                            onChange={(e) => handleChange(e)}
                            value={currentCustomer.lastname}
                            variant="outlined"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                            label="Apellido materno"
                            margin="normal"
                            fullWidth
                            disabled={(userData.campus_id === 99)? true : false}
                            name="second_lastname"
                            onChange={(e) => handleChange(e)}
                            value={currentCustomer.second_lastname}
                            variant="outlined"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6} >
                            <TextField 
                                select label="¿Qué estatus tiene?" 
                                name="up_status_id"
                                value={currentCustomer.up_status_id} 
                                fullWidth
                                style={{minWidth: 200}}
                                onChange={(e) => {handleChange(e)}} margin="normal" fullWidth 
                            >
                                <MenuItem value={1} > Candidato </MenuItem>
                                <MenuItem value={2} > No candidato </MenuItem>
                            </TextField>
                        </GridItem>
                    </GridContainer>
                    <Box my={2}>
                        <Button
                            color="primary"
                            fullWidth
                            size="large"
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Modificar
                        </Button>
                    </Box>
                </Container>
            </CustomModal>
            <CustomModal
                open={viewModal}
                handleClose={() => setViewModal(false)}
            >
                <Grid container justify="center" alignItems="flex-start" direction="column" style={{minHeight: "50vh", minWidth: "60vw"}}>
                    <Typography variant="h3" component="h2" style={{marginBottom: 20}}>
                        Datos del alumno
                    </Typography>
                    <Typography variant="h4" component="p" color="textSecondary"> Nombre </Typography>
                    <Typography className={classes.pos} color="textSecondary"> {currentCustomer.name} </Typography>
                    <Typography variant="h4" component="p" color="textSecondary"> Apellido paterno </Typography>
                    <Typography className={classes.pos} color="textSecondary"> {currentCustomer.lastname} </Typography>
                    <Typography variant="h4" component="p" color="textSecondary"> Apellido materno </Typography>
                    <Typography className={classes.pos} color="textSecondary"> {currentCustomer.second_lastname} </Typography>
                    <Typography variant="h4" component="p" color="textSecondary"> Registro </Typography>
                    <Typography className={classes.pos} color="textSecondary"> {currentCustomer.register} </Typography>
                    <Typography variant="h4" component="p" color="textSecondary"> Semestre </Typography>
                    <Typography className={classes.pos} color="textSecondary"> {currentCustomer.semester} </Typography>
                    <Typography variant="h4" component="p" color="textSecondary"> Estatus </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                        {(currentCustomer.hasOwnProperty('status'))? currentCustomer.status.name : currentCustomer.up_status_id}
                    </Typography>
                    <Typography variant="h4" component="p" color="textSecondary"> Familia </Typography>
                    <Typography className={classes.pos} color="textSecondary"> {currentCustomer.family_id} </Typography>
                    <Typography variant="h4" component="p" color="textSecondary"> Carrera </Typography>
                    <Typography className={classes.pos} color="textSecondary"> {currentCustomer.career} </Typography>
                    
                </Grid>
            </CustomModal>
        </Page>
    );
};

export default UpStudents;