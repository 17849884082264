/**
 * @fileoverview Auth 
 * Funciones a realizar en redux en base a la autentificación del usuario
 * @version 1.0
 * @author César Paul Hernández Camacho
 * @date 30/10/2020
 * @copyright 2020 Industrias RESSER S.A de C.V
 * 
 */

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const SEEN_PRIVACY_POLICY = 'SEEN_PRIVACY_POLICY';
export const SHOW_PRIVACY_POLICY = 'SHOW_PRIVACY_POLICY';

export const loginUser = (authType) => (dispatch) => {
  dispatch({
    type: LOGIN_SUCCESS,
    authType
  })
};

export const logoutUser = () => (dispatch) => {
  dispatch({
    type: LOGOUT_SUCCESS
  })
};

export const setSeenPrivacyPolicy = () => (dispatch) => {
  dispatch({
    type: SEEN_PRIVACY_POLICY
  })
}
export const setShowPrivacyPolicy = () => (dispatch) => {
  dispatch({
    type: SHOW_PRIVACY_POLICY
  })
}