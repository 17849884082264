import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import CustomTable from 'src/components/CustomTable/CustomTable';
import axios from 'src/utils/AxiosHelper';
import { CSVLink } from "react-csv";
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';

const cols = [
    { accessor: "name", header: "Nombre" },
    { accessor: "code", header: "Código" },
    { accessor: "created_at", header: "Creado el" },
    { accessor: "updated_at", header: "Actualizado el" }
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const UsuariosFamilias = () => {
    const classes = useStyles();
    const [rowsData, setRowsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        getUsers();
    }, [])

    const getUsers = () => {
        axios.get('auth/userFamily')
        .then(response => {
            if (response.status === 200) {
                setRowsData(response.data);
                setIsLoading(false);
            }
        })
    }
    return (
        <Page
            className={classes.root}
            title=".:BECAS - Usuarios de familias:."
        >
            <Container maxWidth={false}>
                <Box mt={3}>
                    {
                        !isLoading &&
                        <CustomTable
                            cols={cols}
                            rows={rowsData}
                        />
                    }
                </Box>
            </Container>
        </Page>
    );
};

export default UsuariosFamilias;