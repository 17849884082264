import React, {useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Page, Text, View, Document, Image, StyleSheet, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import axios from 'src/utils/AxiosHelper';
//Images
import talonPago from 'src/icons/talon_pago.jpg';
import headerSolicitud from 'src/icons/headerSolicitud.jpg';
import banorte from 'src/icons/banorte.jpg';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  section: {
    margin: 10,
    flexGrow: 1,
    flexDirection: 'column'
  },
  title: {
      fontSize: 18,
      textAlign: 'center'
  },
  negritas: {
    fontWeight: '800',
    fontSize: 10
  },
  negritas_italic: {
    fontWeight: '800',
    fontSize: 10,
    fontStyle: 'italic'
  },
  blueHeader: {
      backgroundColor: '#415f8e',
      height: 20,
      width: '100%',
  },
  table: {
      display: 'flex',
      width: '60%',
      flexDirection: 'row',
      flexWrap: 'wrap'
  },
  tableContent: {
      border: '1',
      borderStyle: 'solid',
      borderColor: 'black',
      width: '50%',
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingLeft: 10
  },
  tableRow: {
      border: '1',
      borderStyle: 'solid',
      borderColor: 'black',
      width: '100%',
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'space-around',
      flexDirection: 'row',
      paddingLeft: 10
  },
  tableText: {
      fontSize: 10
  }
});


// Create Document Component
const MyDocument = ({
    family,
    schoolarship,
    students,
    upStudents,
    heritageAssets,
    monthlyIncomes,
    monthlyExpenses,
    reason,
    vehicles,
    economicDependent,
    settings,
    formVerification
}) => {
    
    let date =formVerification.updated_at.split(' ')[0];
    date = date.split('-');
    date = `${date[2]}-${date[1]}-${date[0]}`;
    return(
        <Document>
        
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                        <Image src={headerSolicitud} style={{width: '100%'}}/>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        <Text style={styles.negritas} >
                            Ciclo Escolar: {`${new Date().getFullYear()} - ${new Date().getFullYear()+1}`}
                        </Text>
                        <Text style={styles.negritas} >
                            Tipo de beca: {(schoolarship === '1')? 'Interna Oficial' : (schoolarship === '2')? 'Apoyo familia numerosa' : 'Orfandad'}
                            {'     '}Familia: {`${family.family_id} ${'-'} ${family.ft_lastname} ${family.mt_lastname}`}{'     '}Fecha: {`${date}`}
                        </Text>
                    </View>
                    
                    {/*Inicio datos de la familia */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Datos de la familia </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        <Text style={styles.negritas} >
                            Domicilio particular: {family.address} {'  '} CP: {family.pc} {'\n\n'}
                            Ciudad: {family.town} {'   '} Colonia: {family.suburb} {'  '} Télefono de casa: {family.phone_number} {'\n\n'}
                        </Text>
                    </View>

                    {/* Inicio datos del padre */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Datos del padre </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        <Text style={{marginVertical: 5}}>Datos Personales</Text>
                        <Text style={[styles.negritas]} >
                            Nombre completo del padre: {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_name} ${family.ft_lastname} ${family.ft_second_lastname}    \t\n\n`}</Text>
                            Fecha de nacimiento:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_birthdate}    \t\t`}</Text>
                            {' '}Edad:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_age}    \t\t\n\n`}</Text>
                            Celular:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_cellphone_number}    \t\t`}</Text>
                            {' '}Email:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_email}    \t\t\n\n`}</Text>
                            {/* Profesión / Ocupación:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_business_position}    \t\t\n\n`}</Text> */}
                        </Text>
                        <Text style={{marginVertical: 5}}>Datos Laborales</Text>
                        <Text style={[styles.negritas]} >
                            Nombre de la empresa: {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_business_name}    \t\n\n`}</Text>
                            Giro de la empresa:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_business_activity}    \t\t\n\n`}</Text>
                            Puesto que desempeña:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_business_position}    \t\t`}</Text>
                            {' '}Antigüedad:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_business_antiquity}    \t\t\n\n`}</Text>
                            ¿Es dueño o socio de la empresa?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_business_society}    \t\t`}</Text>
                            {' '}¿Qué porcentaje?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.ft_business_percent}    \t\t\n\n`}</Text>
                        </Text>
                    </View>

                    {/* Inicio datos de la madre */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Datos de la madre </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        <Text style={{marginVertical: 5}}>Datos Personales</Text>
                        <Text style={[styles.negritas]} >
                            Nombre completo de la madre: {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_name} ${family.mt_lastname} ${family.mt_second_lastname}    \t\n\n`}</Text>
                            Fecha de nacimiento:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_birthdate}    \t\t`}</Text>
                            {' '}Edad:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_age}    \t\t\n\n`}</Text>
                            Celular:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_cellphone_number}    \t\t`}</Text>
                            {' '}Email:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_email}    \t\t\n\n`}</Text>
                            {/* Profesión / Ocupación:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_business_position}    \t\t\n\n`}</Text> */}
                        </Text>
                        <Text style={{marginVertical: 5}}>Datos Laborales</Text>
                        <Text style={[styles.negritas]} >
                            Nombre de la empresa: {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_business_name}    \t\n\n`}</Text>
                            Giro de la empresa:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_business_activity}    \t\t\n\n`}</Text>
                            Puesto que desempeña:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_business_position}    \t\t`}</Text>
                            {' '}Antigüedad:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_business_antiquity}    \t\t\n\n`}</Text>
                            ¿Es dueña o socia de la empresa?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_business_society}    \t\t`}</Text>
                            {' '}¿Qué porcentaje?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${family.mt_business_percent}    \t\t\n\n`}</Text>
                        </Text>
                    </View>
                </View>
            </Page>

            <Page size="A4" style={styles.page}>
                <View style={styles.section} >
                    {/* Datos del alumno */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Datos del alumno </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        {students.map((item, key) => (
                            <Text style={[styles.negritas]} key={key}>
                                Nombre completo : {'\t'}
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.name} ${item.lastname} ${item.second_lastname}    \t\t`}</Text>
                                {' '}Colegio : {'\t'}
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.campus.name}    \t\n\n`}</Text>
                                Matricula:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.register}    \t\t`}</Text>
                                {' '}Seccion a ingresar:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.section}    \t\t`}</Text>
                                {' '}Grado a ingresar:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.grade}    \t\t`}</Text>
                                {' '}% de beca solicitado:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.schoolarship_percent}    \t\t\n\n\n\n`}</Text>
                            </Text>
                        ))}
                        {upStudents.map((item, key) => (
                            <Text style={[styles.negritas]} key={key}>
                                Nombre completo : {'\t'}
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.name} ${item.lastname} ${item.second_lastname}    \t\t`}</Text>
                                {' '}Colegio : {'\t'}
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.campus.name}    \t\n\n`}</Text>
                                Matricula:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.register}    \t\t`}</Text>
                                {' '}Seccion a ingresar:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.section}    \t\t`}</Text>
                                {' '}Grado a ingresar:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.grade}    \t\t`}</Text>
                                {' '}% de beca solicitado:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.schoolarship_percent}    \t\t\n\n\n\n`}</Text>
                            </Text>
                        ))}
                    </View>
                    <Text style={{textAlign: 'center'}} >____________________</Text>
                    <Text style={{textAlign: 'center'}} >Firma colegio {'\n\n\n'}</Text>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                        <View style={{flexGrow:1, justifyContent: 'center', alignItems: 'center'}}>
                            <Text>____________________</Text>
                            <Text style={styles.negritas} >{`${family.ft_name} ${family.ft_lastname} ${family.ft_second_lastname}`}</Text>
                        </View>
                        <View style={{flexGrow:1, justifyContent: 'center', alignItems: 'center'}}>
                            <Text>____________________</Text>
                            <Text style={styles.negritas} >{`${family.mt_name} ${family.mt_lastname} ${family.mt_second_lastname}`}</Text>
                        </View>
                    </View>
                </View>
            </Page>
            
            {(schoolarship === '1') &&
            <Page size="A4" style={styles.page}>
                <View style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                    <Image src={headerSolicitud} style={{width: '100%'}}/>
                </View>
                <View style={styles.section} >
                    
                    {/* Bienes patrimoniales */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Bienes patrimoniales </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        <Text style={[styles.negritas]} >
                            ¿Tiene casa propia?: {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${(heritageAssets.house === 1) ? 'SI' : 'NO'}    \t\t`}</Text>
                            {' '}Es hipotecada?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${(heritageAssets.mortgage === 1) ? 'SI' : 'NO'}    \t\t\n\n`}</Text>
                            ¿Es rentada?: {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${(heritageAssets.rent === 1) ? 'SI' : 'NO'}    \t\t`}</Text>
                            {' '}¿Tiene algún condominio?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${(heritageAssets.condominium === 1) ? 'SI' : 'NO'}    \t\t\n\n`}</Text>
                            ¿Tiene algún terreno?: {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${(heritageAssets.ground === 1) ? 'SI' : 'NO'}    \t\t`}</Text>
                            {' '}¿Tiene casa de campo?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${(heritageAssets.cottage === 1) ? 'SI' : 'NO'}    \t\t\n\n`}</Text>
                            ¿Tiene alguna propiedad?: {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${(heritageAssets.property === 1) ? 'SI' : 'NO'}    \t\t`}</Text>
                           ¿Pertenece a algún club?: {'\t'}
                            <Text style={{textDecoration: 'underline'}}>{`    \t${(heritageAssets.club === 1) ? 'SI' : 'NO'}    \t\t`}</Text>
                            {' '}¿A qué club?:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${heritageAssets.club_name}    \t\t\n\n`}</Text>
                        </Text>
                    </View>
                    
                    {/* Ingresos mensuales */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Ingresos mensuales </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        <Text style={[styles.negritas]} >
                            Ingresos del padre(después de impuestos):
                            <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyIncomes.father}    \t\t`}</Text>
                            {' '}Ingresos de la madre(después de impuestos):
                            <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyIncomes.mother}    \t\t\n\n`}</Text>
                            Ingresos en vales de despensa:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyIncomes.pantry_voucher}    \t\t`}</Text>
                            {' '}Otros ingresos:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyIncomes.other}    \t\t\n\n`}</Text>
                            {' '}Ingresos totales:
                            <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyIncomes.total}    \t\t\n\n`}</Text>
                        </Text>
                    </View>
                    
                    {/* Gastos Mensuales */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Gastos Mensuales </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60, display: 'flex', flexDirection: 'row'}}>
                        <View style={{width: '45%'}}>
                            <Text style={[styles.negritas]} >
                                Alimentación:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.food}    \t\t\n\n`}</Text>
                                Prestamos:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.lending}    \t\t\n\n`}</Text>
                                Agua Potable:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.water}    \t\t\n\n`}</Text>
                                Autos:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.car}    \t\t\n\n`}</Text>
                                Predial:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.predial}    \t\t\n\n`}</Text>
                                Cable:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.cable}    \t\t\n\n`}</Text>
                                Colegiaturas:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.tuition}    \t\t\n\n`}</Text>
                                Servicio doméstico:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.domestic_service}    \t\t\n\n`}</Text>
                                Diversión:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.free_time}    \t\t\n\n`}</Text>
                                Dentista:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.dentist}    \t\t\n\n`}</Text>
                                Seguros:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.assured}    \t\t\n\n`}</Text>
                                Otros gastos:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.other}    \t\t\n\n`}</Text>
                                Desgloce de otros gastos: {`    \t${monthlyExpenses.other_description}    \t\t`}
                            </Text>
                        </View>
                        <View style={{width: '45%'}}>
                            <Text style={[styles.negritas]} >
                                Renta:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.rent}    \t\t\n\n`}</Text>
                                Energía electrica:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.electric_power}    \t\t\n\n`}</Text>
                                Gas:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.gas}    \t\t\n\n`}</Text>
                                Hipoteca:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.mortgage}    \t\t\n\n`}</Text>
                                Telefono:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.phone}    \t\t\n\n`}</Text>
                                Internet:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.internet}    \t\t\n\n`}</Text>
                                Clases o cursos extracurriculares:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.extra_class}    \t\t\n\n`}</Text>
                                Libros:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.book}    \t\t\n\n`}</Text>
                                Médicos:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.med}    \t\t\n\n`}</Text>
                                Vestido:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.clothes}    \t\t\n\n`}</Text>
                                Viajes:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.trip}    \t\t\n\n`}</Text>
                                Gastos totales:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${monthlyExpenses.total}    \t\t\n\n`}</Text>
                            </Text>
                        </View>
                    </View>
                    
                    {/* Motivo de la solicitud de beca */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Motivo de la solicitud de beca </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        <Text style={[styles.negritas]} >
                            Motivo:{`    \t${reason.description}    \t\n\n`}
                        </Text>
                    </View>

                </View>
            </Page>
            }

            {(schoolarship === '1') &&
            <Page size="A4" style={styles.page}>
                <View style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                    <Image src={headerSolicitud} style={{width: '100%'}}/>
                </View>
                <View style={styles.section} >

                    {/* Automoviles */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Automoviles </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        {vehicles.map((item, key) => (
                            <Text style={[styles.negritas]} key={key}>
                                Marca : {'\t'}
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.brand}    \t\t`}</Text>
                                Modelo:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.model}    \t\t`}</Text>
                                {' '}Propietario:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.owner}    \t\t`}</Text>
                                {' '}Año:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.year}    \t\t\n\n\n\n`}</Text>
                            </Text>
                        ))}
                    </View>

                    {/* Dependientes economicos */}
                    <View style={styles.blueHeader} >
                        <Text style={{textAlign: 'center', color: 'white'}} > Dependientes economicos </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 60}}>
                        {economicDependent.map((item, key) => (
                            <Text style={[styles.negritas]} key={key}>
                                Nombre : {'\t'}
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.name}    \t\t`}</Text>
                                Parentesco:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.kinship}    \t\t`}</Text>
                                {' '}Ocupación:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.ocupation}    \t\t`}</Text>
                                {' '}Edad:
                                <Text style={{textDecoration: 'underline'}}>{`    \t${item.age}    \t\t\n\n\n\n`}</Text>
                            </Text>
                        ))}
                    </View>
                </View>
            </Page>
            }
            
            {(schoolarship === '1') && // Mensaje
            <Page size="A4" style={styles.page}>
                <View style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}} >
                    <Image src={headerSolicitud} style={{width: '100%'}}/>
                </View>
                <View style={styles.section} >
                    <View style={{margin: 10, marginLeft: 60}}>
                        <Text style={styles.negritas} >
                            Gracias por realizar la solicitud de beca en línea.
                            {'\n'}
                            Para continuar en el proceso de selección deberá entregar en su colegio puntualmente la siguiente doumentación:
                            {'\n'}
                        </Text>
                    </View>
                    <View style={{margin: 10, marginLeft: 90}}>
                        <Text style={styles.negritas} >
                            1.- Solicitud de Beca impresa firmada por los padres de familia
                            {'\n'}
                            {'\n'}
                            2.-Comprobante de ingresos: 
                        </Text>
                        <Text style={[styles.negritas, {marginLeft: 40, marginTop: 5}]} >
                            *Comprobante de ingresos:
                        </Text>
                        <Text style={[styles.negritas, {marginLeft: 80, marginTop: 5}]} >
                            -Carta de la empresa que mencione ingreso promedio mensual (con descuento de impuestos e IMSS o ISSSTE),
                            prestaciones adicionales (aguinaldo, reparto de utilidades, donfo de ahorro, prima vacacional, seguros, etc.), puesto y antiguedad.
                            {'\n'}
                            -Copia de ingresos familiares (recibos de nómina - un mes completo vigente)
                        </Text>
                        <Text style={[styles.negritas, {marginLeft: 40, marginTop: 5}]} >
                            *Si tiene negocio propio o dueño único o socio:
                        </Text>
                        <Text style={[styles.negritas, {marginLeft: 80, marginTop: 5}]} >
                            -Carta de contador que mencione nombre de contribuyente, ingreso mensual neto (con desuento de ingresos y/o gastos de la actividad),
                            actividad o puesto que desempeña y antigüedad.
                            {'\n'}
                            -Copia de cédula profesional del contador público que emite la carta
                        </Text>
                        <Text style={[styles.negritas, {marginLeft: 40, marginTop: 5}]} >
                            *Si es negocio informal o realiza una actividad independiente fuera de la declaración de impuestos:
                        </Text>
                        <Text style={[styles.negritas, {marginLeft: 80, marginTop: 5}]} >
                            -Una carta personal indicando: nombre, actividad, ingresos netos mensuales y antiguedad.{'\n'}{'\n'}{'\n'}
                        </Text>
                        <Text style={styles.negritas} >
                            3.-Copia de comprobante de egresos:
                        </Text>
                        <Text style={[styles.negritas, {marginLeft: 20, marginTop: 5}]} >
                            * Luz, gas, teléfono, predial y agua.{'\n'}
                            * Seguro de gastos médicos, de autos, de casa, etc.{'\n'}
                            * Comprobante de  hipotecas o de renta.{'\n'}
                            * En caso de que la casa sea prestada, una carta del dueño y copia del prefial a nombre de quien esté la casa.{'\n'}
                            * Último estado de cuenta de tarjetas de crédito, débito, departamentales, inversiones.{'\n'}
                            * Recibos de colegiaturas de hermanos en otras escuelas y clases especiales.{'\n'}
                            * Pago de vehículos del último mes. {'\n'}
                            * Recibos o comprobantes de clubes deportivos y gimnasios. {'\n'}
                            * Tiempos compartidos (hoteles) y otros bienes raíces.{'\n'}{'\n'}{'\n'}
                        </Text>
                        <Text style={styles.negritas} >
                            4.-Copia de la boleta de calificaciones al mes de noviembre
                        </Text>
                    </View>
                </View>
            </Page>
            }

            {(schoolarship === '1') && //Talonario
            <Page size="A4" style={styles.page}>
                <View style={styles.section} >
                    <View style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', margin: 10}} >
                        <Image src={talonPago} style={{width: '100%'}}/>
                    </View>
                    <View style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 10}} >
                        <Image src={banorte} style={{height: 80}}/>
                    </View>
                    <View style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginVertical: 10}} >
                        <View style={styles.table}>
                            <View style={styles.tableContent}>
                                <Text style={styles.tableText} >Empresa: {settings.bank_reference}</Text>
                            </View>
                            <View style={styles.tableContent}>
                                <Text style={styles.tableText} >Referencia: {family.bank_reference}</Text>
                            </View>
                            <View style={styles.tableContent}>
                                <Text style={styles.tableText} >Monto: ${settings.research_cost}</Text>
                            </View>
                            <View style={styles.tableContent}>
                                <Text style={styles.tableText} > Familia {`${family.family_id} ${family.ft_lastname} ${family.mt_lastname}`}</Text>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={{width: '50%'}} >
                                    <Text style={[styles.tableText, {textAlign: 'left'}]} >Conepto</Text>
                                </View>
                                <View style={{width: '50%'}} >
                                    <Text style={[styles.tableText, {textAlign: 'right', paddingRight: 10}]} >Total</Text>
                                </View>
                            </View>
                            <View style={[styles.tableRow, {borderWidth: 0}]}>
                                <View style={{width: '50%'}} >
                                    <Text style={[styles.tableText, {textAlign: 'left'}]} >Estudio socioeconómico</Text>
                                </View>
                                <View style={{width: '50%'}} >
                                    <Text style={[styles.tableText, {textAlign: 'right', paddingRight: 10}]} >${settings.research_cost}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={{fontSize: 10, margin: 10, textAlign: 'center'}}>
                        <Text>*Pago no válido después de: {settings.research_date}</Text>
                    </View>
                </View>
            </Page>
            }

        </Document>
    );
}

const DownloadLink = (props) => {
    const navigate = useNavigate();
    let { family_id, schoolarship } = useParams();

    //state
    const [fileName, setFileName] = useState('Solicitud.pdf');
    const [family, setFamily] = useState({});
    const [students, setStudents] = useState([]);
    const [upStudents, setUpStudents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [heritageAssets, setHeritageAssets] = useState({});
    const [monthlyIncomes, setMonthlyIncomes] = useState({});
    const [monthlyExpenses, setMonthlyExpenses] = useState({});
    const [reason, setReason] = useState({});
    const [settings, setSettings] = useState({});
    const [vehicles, setVehicles] = useState([]);
    const [economicDependent, setEconomicDependent] = useState([]);
    const [formVerification, setFormVerification] = useState({});

    useEffect(() => {
        getData();
        setFileName(`Solicitud-${family_id}.pdf`);
    }, [])

    const getData = async() => {
        if(schoolarship === '1' ){
            await axios.get(`family/heritageAssets/${family_id}`)
            .then(response => {
                if(response.status === 200){
                    setHeritageAssets(response.data[0]);
                }
            });

            await axios.get(`family/monthlyIncome/${family_id}`)
            .then(response => {
                if(response.status === 200){
                    if(response.data.length > 0){
                        setMonthlyIncomes(response.data[0]);
                    }
                }
            });

            await axios.get(`family/monthlyExpense/${family_id}`)
            .then(response => {
                if(response.status === 200){
                    if(response.data.length > 0){
                        setMonthlyExpenses(response.data[0]);
                    }
                }
            });
            await axios.get(`family/reason/${family_id}`)
            .then(response => {
                if(response.status === 200){
                    if(response.data.length > 0){
                        setReason(response.data[0]);
                    }
                }
            });
            await axios.get(`family/vehicle/${family_id}`)
            .then(response => {
                if(response.status === 200){
                    setVehicles(response.data);
                }
            })
            await axios.get(`family/economicDependent/${family_id}`)
            .then(response => {
                if(response.status === 200){
                    setEconomicDependent(response.data);
                }
            })
        }
        
        await axios.get(`family/upStudent/${family_id}`)
        .then(async response => {
            if(response.status === 200){
                await setUpStudents(response.data);
            }
        });
        
        await axios.get(`family/studentSchoolarship/${family_id}`)
        .then(async response => {
            if(response.status === 200){
                await setStudents(response.data);
            }
        });
        await axios.get(`auth/settings`)
        .then(async response => {
            if(response.status === 200){
                await setSettings(response.data);
            }
        });
        await axios.get(`family/information/${family_id}`)
        .then(async response => {
            if(response.status === 200){
                await setFamily(response.data);
            }
        });
        await axios.get(`family/formVerification/${family_id}`)
        .then(async response => {
            if (response.status === 200) {
                await setFormVerification(response.data[0]);
                setIsLoading(false);
            }
        });
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            {!isLoading && 
            <PDFViewer style={{width: '100vw', height: '80vh', marginBottom: 15}} >
                <MyDocument 
                    family={family}
                    schoolarship={schoolarship}
                    students={students}
                    upStudents={upStudents}
                    heritageAssets={heritageAssets}
                    monthlyIncomes={monthlyIncomes}
                    monthlyExpenses={monthlyExpenses}
                    reason={reason}
                    vehicles={vehicles}
                    economicDependent={economicDependent}
                    settings={settings}
                    formVerification={formVerification}
                />
            </PDFViewer>
            }
            {!isLoading && 
                <PDFDownloadLink 
                    document={
                        <MyDocument 
                            family={family}
                            schoolarship={schoolarship}
                            students={students}
                            upStudents={upStudents}
                            vehicles={vehicles}
                            economicDependent={economicDependent}
                            heritageAssets={heritageAssets}
                            monthlyIncomes={monthlyIncomes}
                            monthlyExpenses={monthlyExpenses}
                            reason={reason}
                            settings={settings}
                            formVerification={formVerification}
                        />
                    }
                    style={{backgroundColor: '#415f8e', color: 'white', minWidth: 150, padding: 15, marginTop: 10, borderRadius: 5}}
                    fileName={fileName}>
                    {({ blob, url, loading, error }) => (loading ? 'Cargando documento...' : 'Descargar documento')}
                </PDFDownloadLink>
            }
        </div>
    )
}

export default React.memo(DownloadLink);