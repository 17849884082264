import React, {useEffect, useState} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Typography,
    makeStyles,
    Grid
} from '@material-ui/core';
import Cookies from 'js-cookie';
import Axios from 'src/utils/AxiosHelper';
import swal from 'sweetalert';

//Components
import Page from 'src/components/Page';

//Forms
import MomDadForm from './MomDadForm';
import StudentPercentForm from './StudentPercentForm';
import EconomicDependenciesForm from './EconomicDependenciesForm';
import UpStudents from './UpStudents';

const useStyles = makeStyles((theme) => ({
  root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100vh',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
  }
}));

const initialValues = {
    ft_name: '',
    ft_lastname: '',
    ft_second_lastname: '',
    ft_business_name: '',
    ft_business_activity: '',
    ft_business_antiquity: '',
    ft_business_position: '',
    ft_business_society: '',
    ft_business_percent: '',
    ft_cellphone_number: '',
    ft_email: '',
    mt_name: '',
    mt_lastname: '',
    mt_second_lastname: '',
    mt_business_name: '',
    mt_business_activity: '',
    mt_business_antiquity: '',
    mt_business_position: '',
    mt_business_society: '',
    mt_business_percent: '',
    mt_cellphone_number: '',
    mt_email: '',
    address: '',
    suburb: '',
    phone_number: '',
    pc: '',
    municipio: ''
}

const initVerification = {
    family: 0,
    finished: 0,
    heritage_assets: 0,
    monthly_expenses: 0,
    monthly_income: 0,
    schoolarship_id: 0,
    schoolarship_reason: 0,
    student: 0
}

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}

const Apoyo = () => {
    const userData = Cookies.getJSON('userData');
    const classes = useStyles();
    const navigate = useNavigate();
    const forceUpdate = useForceUpdate();
    //state
    const [currentCustomer, setCurrentCustomer] = useState({initialValues});
    const [formVerification, setFormVerification] = useState(initVerification);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        async function fetchData() {
            getFormVerifications();
        }
        fetchData();
    }, []);

    const getFormVerifications = () => {
        Axios.get(`family/formVerification/${userData.family_id}`)
        .then(response => {
            if (response.status === 200) {
                setFormVerification(response.data[0]);
                setLoaded(true);
                forceUpdate();
            }
        });
    }

    const handleChange = (e) => {
        setCurrentCustomer({
            ...currentCustomer,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = () => {
        currentCustomer._method = "put";
        Axios.post(`family/information/${userData.family_id}`, currentCustomer)
        .then(response => {
            if(response.status === 200){
                swal("Datos de la familia actualizados correctamente", {icon: "success"});
                Axios.post(`family/formVerification/${userData.family_id}`, {family: 1, _method: "put"})
                .then(response => {
                    getFormVerifications();
                })
            }
        })
        .catch(error => {
            Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                swal(error.response.data[val][0], {icon: "error"});
            });
        })
    }

    const handleFinishSubmit = () => {
        if( (formVerification.family === 1) && (formVerification.student === 1) ){
            Axios.post(`family/formVerification/${userData.family_id}`, {schoolarship_reason: 2, finished: 1, _method: "put"})
            .then(response => {
                if(response.status === 200){
                    getFormVerifications();
                    swal("Formulario finalizado correctamente", {icon: "success"});
                }
            })
        }
        else{
            swal("¡Atención!", "Favor de guardar todos los formularios antes de finalizar la solicitud.", "warning");
        }
    }

    const onShowPDF = () => {
        window.open(`/solicitud/${userData.family_id}/2`, "_blank");
    }


    return (
        <Page
        className={classes.root}
        title=".:BECAS - Colmenares:."
        >
            {loaded && <Container>
                <Typography color="textSecondary" gutterBottom variant="h3" style={{textAlign: 'center'}}>
                    Solicitud de beca AFN
                </Typography>

                {formVerification.family !== 1 && formVerification.finished !== 1 && 
                    <MomDadForm
                        currentCustomer={currentCustomer}
                        handleChange={(e) => handleChange(e)}
                        handleSubmit={handleSubmit}
                        setFamilyData={(data) => {setCurrentCustomer(data)}}
                        family_id={userData.family_id}
                    />
                }
                
                {formVerification.student !== 1 && formVerification.finished !== 1 && <StudentPercentForm 
                    family_id={userData.family_id}
                    formVerification={formVerification}
                    apoyo
                    familiaNumerosa={true}
                    getFormVerifications={getFormVerifications}
                    />}

                {formVerification.finished !== 1 && <EconomicDependenciesForm 
                    family_id={userData.family_id}
                    formVerification={formVerification}
                    getFormVerifications={getFormVerifications}
                />}

            </Container>}
            {formVerification.finished !== 1 ? 
            <Box my={2} style={{display: 'flex', justifyContent:'center', alignItems:'center', margin: 20}}>
                <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={handleFinishSubmit}
                >
                    Finalizar
                </Button>
            </Box> :
            <Grid container direction="column" justify="center" alignItems="center" style={{width: '100vw', height: '70vh'}}>
            <Typography variant="h1" >¡Felicidades finalizaste tu proceso de solicitud de beca!</Typography>
                <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={onShowPDF}
                >
                    Solicitud de beca
                </Button>
            </Grid>}
        </Page>
    );
};

export default Apoyo;
