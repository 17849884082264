import React, {useEffect, useState} from 'react';
import {
    Button,
    TextField,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box
} from '@material-ui/core';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import axios from 'src/utils/AxiosHelper';
import Cookies from 'js-cookie';
import CustomModal from 'src/components/CustomModal';
import Table from 'src/components/CustomTable/CustomTable';
import swal from 'sweetalert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const cols = [
    {accessor: "place", header: "Lugar"},
    {accessor: "name", header: "Nombre"},
    {accessor: "position", header: "Cargo que ocupa"}
];

const initalData = {
    place: "",
    name: "",
    position: ""
}

const InteresTable = ({
    family_id
}) => {

    useEffect(() => {
        getFamilyData();
    }, [])

    //state
    const [studetns, setStudetns] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [userData, setUserData] = useState(initalData);

    const handleSubmit = () => {
        swal("Datos actualizados correctamente", {icon: "success"});
    }

    const handleChange = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        })
    }

    const getFamilyData = () => {
        axios.get(`research/cultural/${family_id}`)
        .then(response => {
            if(response.status === 200){
                setStudetns(response.data);
            }
        })
    }

    const onDeleteAction = async(customer) => {
        await swal({
            title: "Estas seguro?",
            text: "Una vez eliminado no se podra recuperar el lugar de interes!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                axios.post(`research/cultural/${customer.id}`, {_method: "delete"})
                .then(response => {
                    if(response.status === 200){
                        swal("Lugar de interes eliminado correctamente", {icon: "success"});
                        getFamilyData();
                    }
                })
            }
        });
    }

    const handleNewDependency = () => {
        setShowForm(true);
    }

    const onSaveNew = () => {
        userData.family_id = family_id;
        axios.post(`research/cultural`, userData)
        .then(response => {
            if(response.status === 200){
                swal("Lugar de interes agregado correctamente", {icon: "success"});
                setUserData(initalData);
                getFamilyData();
            }
        })
        .catch(error => {
            Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                swal(error.response.data[val][0], {icon: "error"});
            });
        });
        setShowForm(false);
    }

    return (
        <>
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                    Interes cultural
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {/* Datos de la familia */}
                <GridContainer  alignItems="center" justify="center" spacing={2} style={{width: '100%'}}>
                    <GridItem xs={12} >
                        <Table 
                            cols={cols}
                            rows={studetns}
                            actions
                            showAction={false}
                            updateAction={false}
                            onDelete={customer => onDeleteAction(customer)}
                        />
                    </GridItem>
                    <GridItem xs={6} style={{display: 'flex'}} alignItems="center" justify="center" >
                        <Button
                            color="primary"
                            size="large"
                            variant="contained"
                            onClick={handleNewDependency}
                        >
                            Agregar
                        </Button>
                    </GridItem>
                    <GridItem xs={6} style={{display: 'flex'}} alignItems="center" justify="center" >
                        <Button
                            color="primary"
                            size="large"
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Guardar
                        </Button>
                    </GridItem>
                </GridContainer>
            </AccordionDetails>
        </Accordion>
        <CustomModal
            open={showForm}
            handleClose={ () => {
                setShowForm(false);
                setUserData(initalData);
            }}
        >
            <Box mb={3}>
                <Typography
                    color="textPrimary"
                    variant="h2"
                >
                    Agregar lugar de interes
                </Typography>
            </Box>
            <TextField
                fullWidth
                label="Lugar"
                margin="normal"
                name="place"
                required={true}
                onChange={(e) => handleChange(e)}
                value={userData.place}
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Nombre"
                margin="normal"
                name="name"
                required={true}
                onChange={(e) => handleChange(e)}
                value={userData.name}
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Cargo que ocupa"
                margin="normal"
                name="position"
                required={true}
                onChange={handleChange}
                value={userData.position}
                variant="outlined"
            />
            <Box my={2}>
                <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={onSaveNew}
                >
                    Agregar
                </Button>
            </Box>
        </CustomModal>
        </>
    )
}

export default InteresTable;