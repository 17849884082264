import React, {useEffect, useState} from 'react';
import {
    Button,
    TextField,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    MenuItem,
    InputLabel,
    Input
} from '@material-ui/core';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import axios from 'src/utils/AxiosHelper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import municipiosJalisco from 'src/utils/municipiosJalisco.json';
import swal from 'sweetalert';

const MomDadForm = ({
    currentCustomer,
    handleChange,
    handleSubmit,
    setFamilyData,
    family_id,
    estudios = false,
    getFormVerifications,
    observations= {}
}) => {

    const [businessActibity, setBusinessActibity] = useState([]);
    const [professions, setProfessions] = useState([]);
    const [reason, setReason] = useState('');
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        getFamilyData();
        if(estudios){
            setReason(observations.family);
        }
    }, [])

    const getFamilyData = async() => {
        await axios.get(`general/businessActivity`)
        .then(response => {
            if(response.status === 200){
                setBusinessActibity(response.data);
            }
        })
        await axios.get(`general/profession`)
        .then(response => {
            if (response.status === 200) {
                setProfessions(response.data);
            }
        });
        await axios.get(`family/information/${family_id}`)
        .then(async response => {
            if(response.status === 200){
                await setFamilyData(response.data);
                await setLoaded(true);
            }
        });
    }

    const submitFamilyReason = () => {
        axios.post(`research/observation/${family_id}`, { family: reason }, 'put')
        .then(response => {
            if(response.status === 200){
                axios.post(`research/researchFormVerification/${family_id}`, {family: 1}, 'put')
                .then(response => {
                    if(response.status === 200){
                        getFormVerifications();
                        swal("Correcto", "Observaciones guardadas de manera correcta", "success");
                    }
                })
            }
        })
        .catch(error => {
            Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                swal(error.response.data[val][0], {icon: "error"});
            });
        })
    }

    return (
        <>
        {loaded && 
            <form onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}>
                <Accordion defaultExpanded={!estudios}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                            Datos del padre
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* Datos del padre */}
                        <GridContainer direction="row" alignItems="center" justify="center" spacing={2}>
                            <GridItem xs={12} md={6}>
                                <TextField
                                disabled={estudios}
                                required
                                InputLabelProps={{ shrink: true }}
                                label="Nombre"
                                margin="normal"
                                fullWidth
                                name="ft_name"
                                onChange={(e) => handleChange(e)}
                                value={currentCustomer.ft_name}
                                variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField
                                disabled={estudios}
                                required
                                InputLabelProps={{ shrink: true }}
                                label="Apellido paterno"
                                margin="normal"
                                fullWidth
                                name="ft_lastname"
                                onChange={(e) => handleChange(e)}
                                value={currentCustomer.ft_lastname}
                                variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField
                                disabled={estudios}
                                required
                                InputLabelProps={{ shrink: true }}
                                label="Apellido materno"
                                margin="normal"
                                fullWidth
                                name="ft_second_lastname"
                                onChange={(e) => handleChange(e)}
                                value={currentCustomer.ft_second_lastname}
                                variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField
                                disabled={estudios}
                                required
                                InputLabelProps={{ shrink: true }}
                                label="Nombre de la empresa"
                                margin="normal"
                                fullWidth
                                name="ft_business_name"
                                onChange={(e) => handleChange(e)}
                                value={currentCustomer.ft_business_name}
                                variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField select label={"Giro de la empresa"}
                                disabled={estudios}
                                required
                                    value={currentCustomer.ft_business_activity} 
                                    name="ft_business_activity"
                                    onChange={(e) => handleChange(e)} 
                                    margin="normal" 
                                    fullWidth 
                                >
                                {
                                    businessActibity.map((item, key) => {
                                        return(
                                            <MenuItem key={key} value={item.name} > {item.name} </MenuItem>
                                        )
                                    })
                                }
                                </TextField>
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField
                                disabled={estudios}
                                required
                                InputLabelProps={{ shrink: true }}
                                label="Antiguedad de la empresa (años)"
                                margin="normal"
                                fullWidth
                                name="ft_business_antiquity"
                                onChange={(e) => handleChange(e, true)}
                                value={currentCustomer.ft_business_antiquity}
                                variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField select label={"Puesto de la empresa"}
                                disabled={estudios}
                                required
                                    value={currentCustomer.ft_business_position} 
                                    name="ft_business_position"
                                    onChange={(e) => handleChange(e)} 
                                    margin="normal" 
                                    fullWidth 
                                >
                                {
                                    professions.map((item, key) => {
                                        return(
                                            <MenuItem key={key} value={item.name} > {item.name} </MenuItem>
                                        )
                                    })
                                }
                                </TextField>
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField select 
                                disabled={estudios}
                                required
                                    label="¿Es dueño o socio de la empresa?"
                                    value={currentCustomer.ft_business_society} 
                                    name="ft_business_society"
                                    onChange={(e) => handleChange(e)} 
                                    margin="normal" 
                                    fullWidth 
                                >
                                    <MenuItem value={"NO"} > {"NO"} </MenuItem>
                                    <MenuItem value={"SI"} > {"SI"} </MenuItem>
                                </TextField>
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField
                                disabled={estudios}
                                required
                                InputLabelProps={{ shrink: true }}
                                label="¿En qué porcentaje? (%)"
                                margin="normal"
                                fullWidth
                                name="ft_business_percent"
                                onChange={(e) => handleChange(e, true)}
                                value={currentCustomer.ft_business_percent}
                                variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField
                                disabled={estudios}
                                required
                                InputLabelProps={{ shrink: true }}
                                label="Teléfono celular"
                                margin="normal"
                                fullWidth
                                name="ft_cellphone_number"
                                onChange={(e) => handleChange(e)}
                                value={currentCustomer.ft_cellphone_number}
                                variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <InputLabel shrink>Fecha de nacimiento del padre</InputLabel>
                                <Input 
                                    disabled={estudios}
                                    label="Fecha de inicio"
                                    margin="normal" fullWidth type="date"
                                    name="ft_birthdate" value={currentCustomer.ft_birthdate}
                                    onChange={(e) => handleChange(e)}
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField
                                disabled={estudios}
                                required
                                InputLabelProps={{ shrink: true }}
                                label="Email"
                                margin="normal"
                                fullWidth
                                name="ft_email"
                                onChange={(e) => handleChange(e)}
                                value={currentCustomer.ft_email}
                                variant="outlined"
                                />
                            </GridItem>
                        </GridContainer>
                    </AccordionDetails>
                </Accordion>
                
                <Accordion defaultExpanded={!estudios}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                            Datos de la madre
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {/* Datos de la madre */}
                        <GridContainer direction="row" alignItems="center" justify="center" spacing={2}>
                            <GridItem xs={12} md={6}>
                                <TextField
                                disabled={estudios}
                                required
                                InputLabelProps={{ shrink: true }}
                                label="Nombre"
                                margin="normal"
                                fullWidth
                                name="mt_name"
                                onChange={(e) => handleChange(e)}
                                value={currentCustomer.mt_name}
                                variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField
                                disabled={estudios}
                                required
                                InputLabelProps={{ shrink: true }}
                                label="Apellido paterno"
                                margin="normal"
                                fullWidth
                                name="mt_lastname"
                                onChange={(e) => handleChange(e)}
                                value={currentCustomer.mt_lastname}
                                variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField
                                disabled={estudios}
                                required
                                InputLabelProps={{ shrink: true }}
                                label="Apellido materno"
                                margin="normal"
                                fullWidth
                                name="mt_second_lastname"
                                onChange={(e) => handleChange(e)}
                                value={currentCustomer.mt_second_lastname}
                                variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField
                                disabled={estudios}
                                required
                                InputLabelProps={{ shrink: true }}
                                label="Nombre de la empresa"
                                margin="normal"
                                fullWidth
                                name="mt_business_name"
                                onChange={(e) => handleChange(e)}
                                value={currentCustomer.mt_business_name}
                                variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField select label={"Giro de la empresa"}
                                disabled={estudios}
                                required
                                    value={currentCustomer.mt_business_activity} 
                                    name="mt_business_activity"
                                    onChange={(e) => handleChange(e)} 
                                    margin="normal" 
                                    fullWidth 
                                >
                                {
                                    businessActibity.map((item, key) => {
                                        return(
                                            <MenuItem key={key} value={item.name} > {item.name} </MenuItem>
                                        )
                                    })
                                }
                                </TextField>
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField
                                disabled={estudios}
                                required
                                InputLabelProps={{ shrink: true }}
                                label="Antiguedad de la empresa (años)"
                                margin="normal"
                                fullWidth
                                name="mt_business_antiquity"
                                onChange={(e) => handleChange(e, true)}
                                value={currentCustomer.mt_business_antiquity}
                                variant="outlined"
                                />
                            </GridItem>
                            
                            <GridItem xs={12} md={6}>
                                <TextField select label={"Puesto de la empresa"}
                                disabled={estudios}
                                required
                                    value={currentCustomer.mt_business_position} 
                                    name="mt_business_position"
                                    onChange={(e) => handleChange(e)} 
                                    margin="normal" 
                                    fullWidth 
                                >
                                {
                                    professions.map((item, key) => {
                                        return(
                                            <MenuItem key={key} value={item.name} > {item.name} </MenuItem>
                                        )
                                    })
                                }
                                </TextField>
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField select 
                                disabled={estudios}
                                required
                                    label="¿Es dueña o socia de la empresa?"
                                    value={currentCustomer.mt_business_society} 
                                    name="mt_business_society"
                                    onChange={(e) => handleChange(e)} 
                                    margin="normal" 
                                    fullWidth 
                                >
                                    <MenuItem value={"NO"} > {"NO"} </MenuItem>
                                    <MenuItem value={"SI"} > {"SI"} </MenuItem>
                                </TextField>
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField
                                disabled={estudios}
                                required
                                InputLabelProps={{ shrink: true }}
                                label="¿En qué porcentaje? (%)"
                                margin="normal"
                                fullWidth
                                name="mt_business_percent"
                                onChange={(e) => handleChange(e, true)}
                                value={currentCustomer.mt_business_percent}
                                variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField
                                disabled={estudios}
                                required
                                InputLabelProps={{ shrink: true }}
                                label="Teléfono celular"
                                margin="normal"
                                fullWidth
                                name="mt_cellphone_number"
                                onChange={(e) => handleChange(e)}
                                value={currentCustomer.mt_cellphone_number}
                                variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <InputLabel shrink>Fecha de nacimiento de la madre</InputLabel>
                                <Input 
                                    disabled={estudios}
                                    label="Fecha de inicio"
                                    margin="normal" fullWidth type="date"
                                    name="mt_birthdate" value={currentCustomer.mt_birthdate}
                                    onChange={(e) => handleChange(e)}
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField
                                disabled={estudios}
                                required
                                InputLabelProps={{ shrink: true }}
                                label="Email"
                                margin="normal"
                                fullWidth
                                name="mt_email"
                                onChange={(e) => handleChange(e)}
                                value={currentCustomer.mt_email}
                                variant="outlined"
                                />
                            </GridItem>
                        </GridContainer>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={!estudios}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                            Datos familia
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    {/* Datos de la familia */}
                    <GridContainer direction="row" alignItems="center" justify="center" spacing={2}>
                        <GridItem xs={12} md={6}>
                            <TextField
                                disabled={estudios}
                                required
                            InputLabelProps={{ shrink: true }}
                            label="Dirección"
                            margin="normal"
                            fullWidth
                            name="address"
                            onChange={(e) => handleChange(e)}
                            value={currentCustomer.address}
                            variant="outlined"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                disabled={estudios}
                                required
                            InputLabelProps={{ shrink: true }}
                            label="Colonia"
                            margin="normal"
                            fullWidth
                            name="suburb"
                            onChange={(e) => handleChange(e)}
                            value={currentCustomer.suburb}
                            variant="outlined"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                disabled={estudios}
                                required
                            InputLabelProps={{ shrink: true }}
                            label="Teléfono"
                            margin="normal"
                            fullWidth
                            name="phone_number"
                            onChange={(e) => handleChange(e)}
                            value={currentCustomer.phone_number}
                            variant="outlined"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                disabled={estudios}
                                required
                            InputLabelProps={{ shrink: true }}
                            label="Código postal"
                            margin="normal"
                            fullWidth
                            name="pc"
                            onChange={(e) => handleChange(e)}
                            value={currentCustomer.pc}
                            variant="outlined"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField select 
                                disabled={estudios}
                                required
                                InputLabelProps={{ shrink: true }}
                                label="Municipio"
                                margin="normal"
                                fullWidth
                                name="town"
                                disabled={estudios}
                                onChange={(e) => handleChange(e)}
                                value={currentCustomer.town}
                                fullWidth 
                            >
                                {municipiosJalisco.map((municipio) => (
                                    <MenuItem value={municipio.nombre_municipio} > {municipio.nombre_municipio} </MenuItem>
                                ))}
                            </TextField>
                        </GridItem>
                        {!estudios && <GridItem xs={12} style={{display: 'flex'}} alignItems="center" justify="center" >
                            <Button
                                color="primary"
                                size="large"
                                variant="contained"
                                type="submit"
                            >
                                Guardar
                            </Button>
                        </GridItem>}
                    </GridContainer>
                </AccordionDetails>
                </Accordion>
            </form>
        }
            {estudios && 
            <form onSubmit={(e) => {
                e.preventDefault();
                submitFamilyReason();
            }}>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                            Observaciones datos de familia
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <GridContainer direction="row" alignItems="center" justify="center" spacing={2} style={{width: '100%'}}>
                            <GridItem xs={12} >
                                <TextField
                                    fullWidth
                                    label="Observaciones"
                                    margin="normal" name="reason"
                                    required={true} multiline rows={3}
                                    onChange={(e) => setReason(e.target.value)}
                                    value={reason}
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} style={{display: 'flex'}} alignItems="center" justify="center" >
                                <Button
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    type="submit"
                                >
                                    Guardar
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </AccordionDetails>
                </Accordion>
            </form>}
        </>
    )
}

export default MomDadForm;