import React, {useEffect, useState} from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Typography,
    makeStyles,
    TextField,
    MenuItem,
    Grid
} from '@material-ui/core';
import axios from 'src/utils/AxiosHelper';
import swal from 'sweetalert';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';

//Components
import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100vh',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
  }
}));




const UpValidation = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    let { id } = useParams();
    //state
    const [loaded, setLoaded] = useState(false);
    const [userData, setUserData] = useState({});
    const [upSataus, setUpSataus] = useState(3);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        axios.get(`general/upStudent/${id}`)
        .then(response => {
            if(response.status === 200){
                setUserData(response.data);
                setLoaded(true);
            }
        })
    }

    const onValidate = () => {
        axios.post(`general/upStudent/${id}`, {up_status_id: upSataus}, 'put')
        .then(response => {
            if(response.status === 200){
                navigate(`/admin`, { replace: true });
                swal("Correcto", "Alumno validado con exito", "success");
            }
        })
        .catch(error => {
            Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                swal(error.response.data[val][0], {icon: "error"});
            });
        })
    }

    return (
        <Page
        className={classes.root}
        title=".:BECAS - Validación de alumnos:."
        >
            {loaded && 
            <Container>
                <GridContainer direction="column" alignItems="center" justify="center" spacing={2} style={{width: '100%'}}>
                    <Typography variant="h1">Validación de alumnos UP</Typography>
                    <GridItem container xs={12} direction="row" alignItems="center" justify="center">
                        <Typography variant="subtitle1" style={{fontWeight: 'bold'}}> Nombre:</Typography>
                        <Typography> {` ${userData.name}`} </Typography>
                    </GridItem>
                    <GridItem container xs={12} direction="row" alignItems="center" justify="center">
                        <Typography variant="subtitle1" style={{fontWeight: 'bold'}}> Apellido paterno:</Typography>
                        <Typography> {` ${userData.lastname}`} </Typography>
                    </GridItem>
                    <GridItem container xs={12} direction="row" alignItems="center" justify="center">
                        <Typography variant="subtitle1" style={{fontWeight: 'bold'}}> Apellido materno:</Typography>
                        <Typography> {` ${userData.second_lastname}`} </Typography>
                    </GridItem>
                    <GridItem container xs={12} direction="row" alignItems="center" justify="center">
                        <Typography variant="subtitle1" style={{fontWeight: 'bold'}}> Semestre:</Typography>
                        <Typography> {` ${userData.semester}`} </Typography>
                    </GridItem>
                    <GridItem container xs={12} direction="row" alignItems="center" justify="center">
                        <Typography variant="subtitle1" style={{fontWeight: 'bold'}}> Matricula:</Typography>
                        <Typography> {` ${userData.register}`} </Typography>
                    </GridItem>
                    <GridItem xs={12} >
                        <TextField 
                            select label="¿Qué estatus tiene?" 
                            name="house"
                            value={upSataus} 
                            fullWidth
                            style={{minWidth: 200}}
                            onChange={(e) => {setUpSataus(e.target.value)}} margin="normal" fullWidth 
                        >
                            <MenuItem value={1} > Candidato </MenuItem>
                            <MenuItem value={2} > No candidato </MenuItem>
                        </TextField>
                    </GridItem>
                </GridContainer>
            </Container>}
            <Grid container direction="column" justify="center" alignItems="center" style={{width: '100vw'}}>
                <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={onValidate}
                >
                    Aceptar
                </Button>
            </Grid>
        </Page>
    );
};

export default UpValidation;
