import React, {useEffect, useState} from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Typography,
    makeStyles,
} from '@material-ui/core';
import Cookies from 'js-cookie';
import swal from 'sweetalert';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import axios from 'src/utils/AxiosHelper';
//Components
import Page from 'src/components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100vh',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    button: {
        [theme.breakpoints.down('md')]: {
            marginTop: 10
        }
    }
}));

const Confirmation = () => {
    const userData = Cookies.getJSON('userData');
    const classes = useStyles();
    const navigate = useNavigate();
    let { id } = useParams();
    //state
    const [tipoBeca, setTipoBeca] = useState('');

    useEffect(() => {
        let mounted = true;
        
        setTipoBeca((id === '1') ? 'Interna / Oficial' : (id === '2') ? 'Apoyo a familia numerosa' : 'Orfandad');

        return function cleanup() {
            mounted = false
        }
    }, []);

    const handleFinishSubmit = () => {
        var option = (id === '1') ? 'internas' : (id === '2') ? 'familiaValidacion' : 'orfandad';
        axios.post(`family/formVerification`, {
            family_id: userData.family_id,
            schoolarship_id: parseInt(id, 10),
            family: 0,
            student: 0,
            heritage_assets: 0,
            monthly_income: 0,
            monthly_expenses: 0,
            schoolarship_reason: 0,
            finished: 0
        })
        .then(response => {
            if (response.status === 200) {
                navigate(`/becas/${option}`, { replace: true });
            }
        });
    }

    const handleCancelClick = () => {
        navigate(`/login`, { replace: true });
    }

    return (
        <Page
        className={classes.root}
        title=".:BECAS - Colmenares:."
        >
            <Container style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', minHeight: '90vh'}}>
                <Typography color="textSecondary" gutterBottom variant="h2" style={{textAlign: 'center'}}>
                    Ingresaste al proceso de:
                </Typography>
                
                <Typography color="primary" gutterBottom variant="h2" style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    {tipoBeca}
                </Typography>

                {(id === '2')  && 
                    <Typography color="textSecondary" gutterBottom variant="h3" style={{textAlign: 'center', fontWeight: 'bold' }}>
                        **Aplica para familias que cuenten con 3 o más hijos en los colegios del Grupo Colmenares, el Centro Escolar Los Altos y/o la Universidad Panamericana Campus Guadalajara.**
                    </Typography>
                }

                <Typography color="textSecondary" gutterBottom variant="h2" style={{textAlign: 'center'}}>
                    ¿Esta seguro que desea aplicar para este tipo de beca?
                </Typography>
                <GridContainer direction="row" justify="center" alignItems="center" >
                    <GridItem xs={12} md={6}>
                        <Button
                            className={classes.button}
                            style={{backgroundColor: 'red', color: 'white'}}
                            fullWidth
                            size="large"
                            variant="contained"
                            onClick={handleCancelClick}
                        >
                            Cancelar
                        </Button>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Button
                            className={classes.button}
                            style={{backgroundColor: 'green', color: 'white'}}
                            fullWidth
                            size="large"
                            variant="contained"
                            onClick={handleFinishSubmit}
                        >
                            Continuar
                        </Button>
                    </GridItem>
                </GridContainer>
            </Container>
        </Page>
    );
};

export default Confirmation;
