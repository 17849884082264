import React, {useState, useEffect} from 'react';
import {
    Button,
    TextField,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    MenuItem
} from '@material-ui/core';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import axios from 'src/utils/AxiosHelper';
import Cookies from 'js-cookie';
import CustomModal from 'src/components/CustomModal';
import Table from 'src/components/CustomTable/CustomTable';
import swal from 'sweetalert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const initalData = {
    register: '',
    family_id: '',
    campus_id: '99',
    lastname: '',
    second_lastname: '',
    name: '',
    semester: '',
    career_id: '',
    up_status_id: 3
}

const cols = [
    {accessor: "register", header: "Matricula"},
    {accessor: "lastname", header: "Apellido paterno"},
    {accessor: "second_lastname", header: "Apellido materno"},
    {accessor: "name", header: "Nombre"},
    {accessor: "campus_name", header: "Campus"},
    {accessor: "grade", header: "Grado"}
];
const colsUp = [
    {accessor: "register", header: "Matricula"},
    {accessor: "lastname", header: "Apellido paterno"},
    {accessor: "second_lastname", header: "Apellido materno"},
    {accessor: "name", header: "Nombre"},
    {accessor: "semester", header: "Semestre"}
];

const UpStudents = ({
    family_id,
    onFinish
}) => {

    //state
    const [showForm, setShowForm] = useState(false);
    const [careers, setCareers] = useState([]);
    const [students, setStudents] = useState([]);
    const [upStudents, setUpStudents] = useState([]);
    const [formData, setFormData] = useState(initalData)
    const [nextBlocked, setNextBlocked] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    const getData = async() => {
        var cont = 0;
        await axios.get('general/upCareer')
        .then(response => {
            if(response.status === 200){
                setCareers(response.data);
                cont = cont + response.data.length;
            }
        });
        
        await axios.get(`family/upStudent/${family_id}`)
        .then(response => {
            if(response.status === 200){
                var aux = [];
                response.data.map(item => {
                    if(item.up_status_id !== 1){
                        setNextBlocked(true);
                    }
                    item.campus_name = item.campus.name
                    aux.push(item);
                    cont = cont + response.data.length;
                })
                setUpStudents(aux);
            }
        });
        
        await axios.get(`family/studentSchoolarship/${family_id}`)
        .then(async response => {
            if(response.status === 200){
                var aux = [];
                response.data.map(item => {
                    item.campus_name = item.campus.name
                    aux.push(item);
                })
                await setStudents(aux);
            }
        });
        if(cont < 3){
            setNextBlocked(true);
        }
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    const onSaveNewStudent = () => {
        setShowForm(false);
        formData.family_id = family_id;
        axios.post(`family/upStudent`, formData)
        .then(response => {
            if(response.status === 200){
                swal("Alumno dado de alta correctamente, para continuar el proceso debe esperar la validación del alumno.", {icon: "success"});
                getData();
            }
        })
        .catch(error => {
            Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                swal(error.response.data[val][0], {icon: "error"});
            });
        });
        setFormData(initalData);
    }

    return(
        <>
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                    Datos de los alumnos
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {/* Datos de la familia */}
                <GridContainer  alignItems="center" justify="center" spacing={2} style={{width: '100%'}}>
                    {students.length > 0 && <GridItem xs={12} >
                        <Table 
                            cols={cols}
                            rows={students}
                        />
                    </GridItem>}
                    {upStudents.length > 0 && <GridItem xs={12} >
                        <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                            Alumnos UP
                        </Typography>
                        <Table 
                            cols={colsUp}
                            rows={upStudents}
                        />
                    </GridItem>}
                    <GridItem xs={6} style={{display: 'flex'}} alignItems="center" justify="center" >
                        <Button
                            color="primary" size="large" variant="contained"
                            onClick={() => {setShowForm(true)}}
                        >
                            Agregar Alumno UP
                        </Button>
                    </GridItem>
                    <GridItem xs={6} style={{display: 'flex'}} alignItems="center" justify="center" >
                        <Button
                            color="primary"
                            size="large"
                            disabled={nextBlocked}
                            variant="contained"
                            onClick={onFinish}
                        >
                            Siguiente
                        </Button>
                    </GridItem>
                </GridContainer>
            </AccordionDetails>
        </Accordion>
        <CustomModal
            open={showForm}
            handleClose={ () => {
                setShowForm(false);
                setFormData(initalData);
            }}
        >
            <Box mb={3}>
                <Typography
                    color="textPrimary"
                    variant="h2"
                >
                    Alta de alumno UP
                </Typography>
            </Box>
            <TextField
                fullWidth
                label="Matricula"
                margin="normal"
                name="register"
                required={true}
                onChange={(e) => handleChange(e)}
                value={formData.register}
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Nombre"
                margin="normal"
                name="name"
                required={true}
                onChange={(e) => handleChange(e)}
                value={formData.name}
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Apellido Paterno"
                margin="normal"
                name="lastname"
                required={true}
                onChange={handleChange}
                value={formData.lastname}
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Apellido Materno"
                margin="normal"
                name="second_lastname"
                required={true}
                onChange={(e) => handleChange(e)}
                value={formData.second_lastname}
                variant="outlined"
            />
            <TextField
                fullWidth
                label="Semestre"
                margin="normal"
                name="semester"
                type="number"
                required={true}
                onChange={(e) => handleChange(e)}
                value={formData.semester}
                variant="outlined"
            />
            <TextField id="select" select name="career_id" label="Carrera" value={formData.career_id} onChange={(e) => handleChange(e)} margin="normal" fullWidth >
            {
                careers.map((item, key) => {
                    return(
                        <MenuItem key={key} value={item.id} > {item.name} </MenuItem>
                    )
                })
            }
            </TextField>
            <Box my={2}>
                <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={onSaveNewStudent}
                >
                    Agregar
                </Button>
            </Box>
        </CustomModal>
        </>
    )
}

export default UpStudents;