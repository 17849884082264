import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import citas from 'src/utils/citas.json';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  card: {
      width: "80%",
      minHeight: "20vh",
      backgroundColor: "white"
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
      fontSize: 14,
      marginBottom: 10
  },
    pos: {
      marginTop: 20,
    marginBottom: 12,
  },
}));

const Usuarios = () => {
    const classes = useStyles();
    const [cita, setCita] = useState({});
    const bull = <span className={classes.bullet}>•</span>;

    useEffect(() => {
        getRandom(0,41);
    }, []);

    const getRandom = (min, max) => {
        setCita(citas[Math.floor(Math.random() * (max - min)) + min]);
    }

    return (
        <Page
            className={classes.root}
            title="Inicio"
        >
            <Grid container justify="center" alignItems="center" style={{minHeight: "80vh"}}>
                <Card className={classes.card} variant="outlined">
                    <CardContent style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Frase del día
                        </Typography>
                        <Typography variant="h3" component="h2">
                        {`"${cita.cita}"`}
                        </Typography>
                        <Typography className={classes.pos} color="textSecondary">
                        {cita.autor}
                        </Typography>
                        <Typography variant="h6" component="p">
                        Bienvenido al sistema de becas
                        <br />
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Page>
    );
};

export default Usuarios;