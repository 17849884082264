import React, {useEffect, useState} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormHelperText,
    Grid,
    TextField,
    Typography,
    makeStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core';
import Cookies from 'js-cookie';
import Axios from 'src/utils/AxiosHelper';
import swal from 'sweetalert';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';

//Components
import Page from 'src/components/Page';

//Forms
import MomDadForm from './MomDadForm';
import StudentPercentForm from './StudentPercentForm';
import EconomicDependenciesForm from './EconomicDependenciesForm';
import HeritageAssets from './HeritageAssets';
import MonthlyIncome from './MonthlyIncome';
import MonthlyExpense from './MonthlyExpense';
import Autos from './Autos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100vh',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
  }
}));

const initialValues = {
    ft_name: '',
    ft_lastname: '',
    ft_second_lastname: '',
    ft_business_name: '',
    ft_business_activity: '',
    ft_business_antiquity: '',
    ft_business_position: '',
    ft_business_society: '',
    ft_business_percent: '',
    ft_cellphone_number: '',
    ft_email: '',
    mt_name: '',
    mt_lastname: '',
    mt_second_lastname: '',
    mt_business_name: '',
    mt_business_activity: '',
    mt_business_antiquity: '',
    mt_business_position: '',
    mt_business_society: '',
    mt_business_percent: '',
    mt_cellphone_number: '',
    mt_email: '',
    address: '',
    suburb: '',
    phone_number: '',
    pc: '',
    municipio: ''
}

const initVerification = {
    family: 0,
    finished: 0,
    heritage_assets: 0,
    monthly_expenses: 0,
    monthly_income: 0,
    schoolarship_id: 0,
    schoolarship_reason: 0,
    student: 0
}

const Internas = () => {
    const userData = Cookies.getJSON('userData');
    const classes = useStyles();
    const navigate = useNavigate();
    //state
    const [currentCustomer, setCurrentCustomer] = useState({initialValues});
    const [formVerification, setFormVerification] = useState(initVerification);
    const [loaded, setLoaded] = useState(false);
    const [reason, setReason] = useState('');
    const [hasReason, setHasReason] = useState(false);

    useEffect(() => {
        getFormVerifications();
        getFamilyReason();
    }, []);

    const getFormVerifications = () => {
        Axios.get(`family/formVerification/${userData.family_id}`)
        .then(response => {
            if (response.status === 200) {
                setFormVerification(response.data[0]);
                setLoaded(true);
            }
        });
    }

    const getFamilyReason = () => {
        Axios.get(`family/reason/${userData.family_id}`)
        .then(response => {
            if (response.status === 200) {
                if (response.data.length > 0) {
                    setHasReason(true);
                    setReason(response.data[0].description);
                }
            }
        });
    }

    const handleChange = (e, onlyNumbers = false) => {
        if (onlyNumbers) {
            if ((/^$|^\d+$|^\d+\.\d{0,2}$/.test(e.target.value))) {
                setCurrentCustomer({
                    ...currentCustomer,
                    [e.target.name]: e.target.value
                });
            }
            return 0;
        }
        else {
            setCurrentCustomer({
                ...currentCustomer,
                [e.target.name]: e.target.value
            });
        }
    }

    const handleSubmit = () => {
        currentCustomer._method = "put";
        Axios.post(`family/information/${userData.family_id}`, currentCustomer)
        .then(response => {
            if(response.status === 200){
                swal("Datos de la familia actualizados correctamente", {icon: "success"});
                Axios.post(`family/formVerification/${userData.family_id}`, {family: 1, _method: "put"})
                .then(response => {
                    getFormVerifications();
                })
            }
        })
        .catch(error => {
            Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                swal(error.response.data[val][0], {icon: "error"});
            });
        })
    }

    const handleFinishSubmit = () => {
        if (reason !== '') {
            var url = 'family/reason'
            var objectToSend = {
                family_id: userData.family_id, 
                description: reason
            }
            if(hasReason){
                objectToSend._method = 'put';
                url = `family/reason/${userData.family_id}`;
            }
            Axios.post(url, objectToSend)
            .then(response => {
                if(response.status === 200){
                    if( (formVerification.family === 1) && (formVerification.student === 1) && (formVerification.heritage_assets === 1) && (formVerification.monthly_income === 1) && (formVerification.monthly_expenses === 1) ){
                        Axios.post(`family/formVerification/${userData.family_id}`, {schoolarship_reason: 1, finished: 1, _method: "put"})
                        .then(response => {
                            if(response.status === 200){
                                getFormVerifications();
                                swal("Formulario finalizado correctamente", {icon: "success"});
                            }
                        })
                    }
                    else{
                        swal("¡Atención!", "Favor de guardar todos los formularios antes de finalizar la solicitud.", "warning");
                    }
                }
            })
        }
    }

    const onShowPDF = (showSolicitud = false) => {
        if(showSolicitud){
            window.open(`/solicitud/${userData.family_id}/1`, "_blank");
        }
        else{
            window.open(`/bankReference/${userData.family_id}`, "_blank");
        }
    }

    return (
        <Page
        className={classes.root}
        title=".:BECAS - Colmenares:."
        >
            {loaded && <Container>
                <Typography color="textSecondary" gutterBottom variant="h3" style={{textAlign: 'center'}}>
                    Solicitud de beca interna/oficial
                </Typography>

                {formVerification.family !== 1 && formVerification.finished !== 1 && 
                    <MomDadForm
                        currentCustomer={currentCustomer}
                        handleChange={(e, validate = false) => handleChange(e, validate)}
                        handleSubmit={handleSubmit}
                        setFamilyData={(data) => {setCurrentCustomer(data)}}
                        family_id={userData.family_id}
                    />
                }
                
                {formVerification.student !== 1 && formVerification.finished !== 1 && <StudentPercentForm 
                    family_id={userData.family_id}
                    formVerification={formVerification}
                    getFormVerifications={getFormVerifications}
                    />}

                {formVerification.finished !== 1 && <EconomicDependenciesForm 
                    family_id={userData.family_id}
                    formVerification={formVerification}
                    getFormVerifications={getFormVerifications}
                />}

                {formVerification.heritage_assets !== 1 && formVerification.finished !== 1 && <HeritageAssets 
                    family_id={userData.family_id}
                    formVerification={formVerification}
                    getFormVerifications={getFormVerifications}
                />}

                {formVerification.finished !== 1 && <Autos 
                    family_id={userData.family_id}
                    formVerification={formVerification}
                    getFormVerifications={getFormVerifications}
                />}

                {formVerification.monthly_income !== 1 && formVerification.finished !== 1 && <MonthlyIncome 
                    family_id={userData.family_id}
                    formVerification={formVerification}
                    getFormVerifications={getFormVerifications}
                />}
                
                {formVerification.monthly_expenses !== 1 && formVerification.finished !== 1 && <MonthlyExpense 
                    family_id={userData.family_id}
                    formVerification={formVerification}
                    getFormVerifications={getFormVerifications}
                />}

                {formVerification.schoolarship_reason !== 1 && formVerification.finished !== 1 &&  <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                            Motivo de la solicitud de beca
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <GridContainer  alignItems="center" justify="center" spacing={2} style={{width: '100%'}}>
                            <GridItem xs={12} >
                                <TextField
                                    fullWidth
                                    label="Motivo de la solicitud"
                                    margin="normal"
                                    name="reason"
                                    required={true}
                                    multiline
                                    rows={10}
                                    onChange={(e) => setReason(e.target.value)}
                                    value={reason}
                                    variant="outlined"
                                />
                            </GridItem>
                        </GridContainer>
                    </AccordionDetails>
                </Accordion>}
                
            </Container>
            }
            {formVerification.finished !== 1 ? 
            <Box my={2} style={{display: 'flex', justifyContent:'center', alignItems:'center', margin: 20}}>
                <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={handleFinishSubmit}
                >
                    Finalizar
                </Button>
            </Box> :
            <Grid container direction="column" justify="center" alignItems="center" style={{width: '100vw', height: '70vh'}}>
                <Typography variant="h1" >¡Felicidades finalizaste tu proceso de solicitud de beca!</Typography>
                <Button
                    style={{backgroundColor: '#981818', color: 'white'}}    
                    size="large"
                    variant="contained"
                    onClick={() => onShowPDF()}
                >
                    Talonario de pago
                </Button>
                <Button
                style={{marginTop:5}}
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={() => onShowPDF(true)}
                >
                    Solicitud de beca
                </Button>
            </Grid> 
            }
        </Page>
    );
};

export default Internas;
