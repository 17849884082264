import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Grid,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import {useDispatch} from 'react-redux';
import {logoutUser} from 'src/redux/actions';
import SchoolIcon from '@material-ui/icons/School';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  }
}));

const TopBar = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  
  const navigate = useNavigate();
  const classes = useStyles();
  const [notifications] = useState([]);
  const dispatch = useDispatch();

  /**
   * Function to logOut of the session
   * @memberof TopBar
   */
  const handleLogOut = () => {
    dispatch(logoutUser());
    Cookies.remove('userData');
    navigate('/admin', { replace: true });
  }

  return (
    <AppBar
      className={clsx(classes.root, className)}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <Grid container item direction="row" xs={12} justify="center" alignItems="center" >
          <SchoolIcon />
          <Typography color="white" variant="h3" style={{textAlign: "center", marginLeft: 10}} >
            Becas {new Date().getFullYear()} - {new Date().getFullYear()+1}
          </Typography>
        </Grid>
        <Box flexGrow={1} />
        <IconButton color="inherit" onClick={handleLogOut} >
          <InputIcon />
        </IconButton>
        {/* <Hidden lgUp> */}
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        {/* </Hidden> */}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
