import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Typography,
    makeStyles,
} from '@material-ui/core';
import Cookies from 'js-cookie';

//Components
import Page from 'src/components/Page';

//Forms
import UpStudents from './UpStudents';

const useStyles = makeStyles((theme) => ({
  root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100vh',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
  }
}));

const ApoyoValidacion = () => {
    const userData = Cookies.getJSON('userData');
    const classes = useStyles();
    const navigate = useNavigate();
    //state

    const onFinish = () => {
        navigate(`/becas/familia`);
    }

    return (
        <Page
        className={classes.root}
        title=".:BECAS - Colmenares:."
        >
            <Container>
                <Typography color="textSecondary" gutterBottom variant="h3" style={{textAlign: 'center'}}>
                    Alumnos
                </Typography>
                <UpStudents 
                    family_id={userData.family_id}
                    onFinish={onFinish}
                />
            </Container>
        </Page>
    );
};

export default ApoyoValidacion;
