import React, {useState} from 'react';
import CustomModal from 'src/components/CustomModal';
import {
  Box,
  Container,
  Button,
  TextField,
  Typography
} from '@material-ui/core';
import swal from 'sweetalert';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';
import axios from 'src/utils/AxiosHelper';

const PasswordUpdateForm = ({showModal, setShowModal, customer}) => {
    
    //state
    const [password, setpassword] = useState('');
    const [confirm_password, setConfirm_password] = useState('');


    const handleSubmit = () => {
        if (password === confirm_password) {
            axios.post(`auth/userFamilyPassword/${customer.family_id}`,
                {
                    password: password,
                    password_confirmation: confirm_password
                }
            , 'put')
            .then(response => {
                if (response.status === 200) {
                    swal("Contraseña actualizada correctamente", { icon: "success" });
                }
                else {
                    swal("Ocurrio un error al actualizar la contraseña", {icon: "error"});
                }
            })
        }
        else {
            swal("Las contraseñas no coinciden", {icon: "error"});
        }
    }

    return (
        <>
            <CustomModal
                open={showModal}
                handleClose={ () => setShowModal(false) }
            >
                <form onSubmit={e => {
                    e.preventDefault();
                    handleSubmit();
                }}>

                    <Container>
                        <Box>
                            <Typography color="textSecondary" gutterBottom variant="h3" >
                                Editar contraseña
                            </Typography>              
                        </Box>
                        <GridContainer direction="row" alignItems="center" justify="space-between" spacing={2}>
                            <GridItem xs={12} md={6}>
                                <TextField
                                label="Contraseña"
                                margin="normal"
                                fullWidth
                                name="password"
                                required
                                type="password"
                                onChange={(e) => setpassword(e.target.value)}
                                value={password}
                                variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} md={6}>
                                <TextField
                                label="Confirmar contraseña"
                                margin="normal"
                                fullWidth
                                name="confirm_password"
                                required
                                type="password"
                                onChange={(e) => setConfirm_password(e.target.value)}
                                value={confirm_password}
                                variant="outlined"
                                />
                            </GridItem>
                        </GridContainer>
                        <Box my={2}>
                            <Button
                                color="primary"
                                fullWidth
                                size="large"
                                variant="contained"
                                type="submit"
                            >
                                Modificar contraseña
                            </Button>
                        </Box>
                    </Container>
                </form>
            </CustomModal>
        </>
    );
}

export default PasswordUpdateForm;