import React, {useState, useEffect} from 'react';
import {
    Box,
    Container,
    makeStyles,
    Button,
    TextField,
    Typography,
    MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import CustomTable from 'src/components/CustomTable/CustomTable';
import axios from 'src/utils/AxiosHelper';
import Cookies from 'js-cookie';
import swal from 'sweetalert';
import { CSVLink } from "react-csv";
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import SearchBar from 'src/components/SearchBar';

  const cols = [
    { accessor: "family_id", header: "Familia" },
    { accessor: "familyName", header: "Nombre de la familia" },
    { accessor: "schoolarship", header: "Tipo de beca" },
    { accessor: "updated_at", header: "Fecha de finalización" },
    // { accessor: "updated_at", header: "Ultima Actualización" },
];

const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    }
  }));

const SolicitudesTerminadas = (props) => {
    
    //cookies
    const userRole = Cookies.getJSON('userData');
    const permission = Cookies.getJSON('permission');

    //estilos
    const classes = useStyles();

    //state
    const [rowsData, setRowsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [excelData, setExcelData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        getData();
    }, [])

    const getData = () => {
        var rows = [["Familia", "Nombre de la familia", "Matricula alumno", "Nombre alumno", "Seccion a ingresar", "Grado a ingresar", "Fecha de terminación", "Tipo de beca", "Procentaje de beca", "Colegio"]];
        axios.get(`auth/finishedRequest/${userRole.campus_id}`)
        .then(async response => {
            if(response.status === 200){
                var aux = [];
                response.data.map((item, key) => {
                    item.familyName = `${item.family.ft_lastname} ${item.family.mt_lastname}`;
                    item.schoolarship = item.schoolarship.name;
                    item.updated_at = item.updated_at.split(' ')[0];
                    aux.push(item);
                    item.student.map(alumno => {
                        rows.push([
                            item.family_id,
                            item.familyName,
                            alumno.register,
                            `${alumno.name} ${alumno.lastname} ${alumno.second_lastname}`,
                            alumno.section,
                            alumno.grade,
                            item.updated_at,
                            item.schoolarship,
                            alumno.schoolarship_percent,
                            alumno.campus.name
                        ])
                    })
                })
                await setExcelData(rows);
                await setRowsData(aux);
                await setIsLoading(false);
                await setIsSearching(false);
            }
        })
    }

    const onShowAction = (customer) => {
        window.open(`/solicitud/${customer.family_id}/${customer.schoolarship_id}`, "_blank");
    }

    const onChangeSearchField = (value) => {
        if (value.length > 3) {
            setTimeout(() => {
                onSearchPress(value);
            }, 500);
        }
        else {
            setIsSearching(false);
        }
    }

    const onDeleteSearch = () => {
        setIsSearching(false);
    }

    const onSearchPress = async(value) => {
        var aux = [];
        await rowsData.map(item => {
            if (
                (item.family_id && item.family_id.toString().includes(value)) ||
                (item.familyName && item.familyName.toLowerCase().includes(value.toLowerCase())) ||
                (item.schoolarship && item.schoolarship.toLowerCase().includes(value.toLowerCase()))
            ) {
                aux.push(item);
            }
        })
        await setFilteredData(aux);
        await setIsSearching(true);
    }

    const onDeleteAction = async(customer) => {
        await swal({
            title: "Estas seguro?",
            text: "Una vez eliminado se deberá de llenar nuevamente el formulario de solicitud de beca!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
            if (willDelete) {
                axios.post(`auth/finishedRequest/${customer.id}`, {_method: "delete"})
                .then(response => {
                    swal("Formulario eliminado correctamente", {icon: "success"});
                    getData();
                })
            }
        });
    }

    return(
        <>
        
            <Page
                className={classes.root}
                title=".:BECAS - Solicitudes terminadas:."
            >
                <Container maxWidth={false} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h1">Solicitudes terminadas</Typography>
                    <SearchBar
                        onChange={(value) => {onChangeSearchField(value)}}
                        onDeleteSearch={onDeleteSearch}
                        onSearchPress={(value) => {onSearchPress(value)}}
                        >
                            
                        <Button
                            size="small"
                            style={{alignSelf: 'flex-end'}}
                            endIcon={<TableChartOutlinedIcon/>}
                        >
                            <CSVLink
                                data={excelData}
                                filename={"Solicitudes terminadas.csv"}
                                className="btn btn-primary"
                                target="_blank"
                                style={{height: 0, display: 'flex', color: '#263238',justifyContent: 'center', alignItems: 'center' }}
                            >
                            Exportar
                            </CSVLink>
                        </Button>
                    </SearchBar>
                    <Box mt={3}>
                        {
                            !isLoading &&
                            <CustomTable
                                cols={cols}
                                rows={isSearching? filteredData : rowsData}
                                actions={true}
                                updateAction={false}
                                showAction={(permission.read === 1) ? true : false}
                                delAction={(permission.delete === 1) ? true : false}
                                onShow={(customer) => { onShowAction(customer) }}
                                onDelete={(customer) => {onDeleteAction(customer)}}
                            />
                        }
                    </Box>
                </Container>
            </Page>
        </>
    )
}

export default SolicitudesTerminadas;