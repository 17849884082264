import React, {useEffect, useState} from 'react';
import {
    Button,
    TextField,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    MenuItem
} from '@material-ui/core';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import axios from 'src/utils/AxiosHelper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import swal from 'sweetalert';

const initalData = {
    family_id: '',
    food: 0,
    rent: 0,
    lending: 0,
    electric_power: 0,
    water: 0,
    gas: 0,
    car: 0,
    mortgage: 0,
    predial: 0,
    phone: 0,
    cable: 0,
    internet: 0,
    tuition: 0,
    extra_class: 0,
    domestic_service: 0,
    book: 0,
    free_time: 0,
    med: 0,
    dentist: 0,
    clothes: 0,
    assured: 0,
    trip: 0,
    other: 0,
    other_description: '',
    total: 0
}

const MonthlyExpense = ({
    family_id,
    formVerification,
    getFormVerifications,
    estudios = false,
    observations= {}
}) => {

    useEffect(() => {
        getUsersData();
        if(estudios){
            setReason(observations.monthly_expenses);
        }
    }, [])

    //state
    const [userData, setUserData] = useState(initalData);
    const [requestType, setRequestType] = useState(0); // 0 post 1 put
    const [reason, setReason] = useState('');

    const handleSubmit = () => {
        userData.family_id=family_id;
        userData.total = getTotal();
        Object.getOwnPropertyNames(userData).forEach(function(val, idx, array) {
            if( (val !== 'other_description') && (val !== 'total') && (val !== 'family_id')){
                if(userData[val] !== ''){
                    userData[val]= parseFloat(userData[val]).toFixed(2);
                }
                else{
                    userData[val] = parseFloat('0.00').toFixed(2);
                }
            }
        });
        var url = 'family/monthlyExpense';
        if(requestType===1){
            userData._method = "put";
            url += `/${family_id}`
        }
        axios.post(url, userData)
        .then(response => {
            if(response.status === 200){
                axios.post(`family/formVerification/${family_id}`, {monthly_expenses: 1, _method: "put"})
                .then(response => {
                    if(response.status === 200){
                        swal("Datos de los alumnos actualizados correctamente", {icon: "success"});
                        getFormVerifications();
                    }
                })
            }
        })
        .catch(error => {
            Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                swal(error.response.data[val][0], {icon: "error"});
            });
        })
    }

    const handleChange = async(e) => {
        if(e.target.name === 'other_description'){
            setUserData({
                ...userData,
                [e.target.name]: e.target.value
            })
        }
        else if((parseFloat(e.target.value)>=0) || (e.target.value === '')){
            setUserData({
                ...userData,
                [e.target.name]: e.target.value
            })
        }
    }

    const getTotal = () => {
        var aux = 0;
        Object.getOwnPropertyNames(userData).forEach(function(val, idx, array) {
            if( (val !== 'other_description') && (val !== 'total') && (val !== 'family_id') && (val !== 'created_at') && (val !== 'updated_at') && (val !== 'id')){
                if(userData[val] !== ''){
                    aux += parseFloat(userData[val]);
                }
            }
        });
        return aux.toFixed(2);
    }

    const getUsersData = () => {
        axios.get(`family/monthlyExpense/${family_id}`)
        .then(response => {
            if(response.status === 200){
                if(response.data.length > 0){
                    setRequestType(1);
                    setUserData(response.data[0]);
                }
            }
        })
    }

    const submitReason = () => {
        axios.post(`research/observation/${family_id}`, { monthly_expenses: reason }, 'put')
        .then(response => {
            if(response.status === 200){
                axios.post(`research/researchFormVerification/${family_id}`, {monthly_expenses: 1}, 'put')
                .then(response => {
                    if(response.status === 200){
                        swal("Correcto", "Observaciones guardadas de manera correcta", "success");
                    }
                })
            }
        })
    }

    return (
        <>
        <form onSubmit={e => {
            e.preventDefault();
            handleSubmit();
        }}>
        <Accordion defaultExpanded={!estudios}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                    Gastos mensuales
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {/* Datos de la familia */}
                <GridContainer  alignItems="center" justify="center" spacing={2} style={{width: '100%'}}>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.food} name="food"
                            label="Alimentación"
                            helperText="Gasto mensual"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.rent} name="rent"
                            label="Renta"
                            helperText="Gasto mensual"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.lending} name="lending"
                            label="Préstamos"
                            helperText="Gasto mensual"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.electric_power} name="electric_power"
                            label="Energía eléctrica"
                            helperText="Considerar únicamente lo porporcional a un mes"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.water} name="water"
                            label="Agua"
                            helperText="Gasto mensual"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.gas} name="gas"
                            label="Gas"
                            helperText="Gasto mensual"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.car} name="car"
                            label="Autos"
                            helperText="Combustuble, reparaciones, limpieza, etc."
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.mortgage} name="mortgage"
                            label="Hipoteca"
                            helperText="Gasto mensual"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.predial} name="predial"
                            label="Predial"
                            helperText="Considerar únicamente lo proporcional a un mes"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.phone} name="phone"
                            label="Teléfono"
                            helperText="Si el pago del teléfono e internet se encuentran en el mismo recibo dividir lo correspondiente de cada uno"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.cable} name="cable"
                            label="Cable"
                            helperText="Si el pago del teléfono e internet se encuentran en el mismo recibo dividir lo correspondiente de cada uno"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.internet} name="internet"
                            label="Internet"
                            helperText="Gasto mensual"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.tuition} name="tuition"
                            label="Colegiaturas"
                            helperText="Gasto mensual"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.extra_class} name="extra_class"
                            label="Clases o cursos extracurriculares"
                            helperText="Gasto mensual"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.domestic_service} name="domestic_service"
                            label="Servicio domestico"
                            helperText="Gasto mensual"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.book} name="book"
                            label="Libros"
                            helperText="Gasto mensual"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.free_time} name="free_time"
                            label="Diversión"
                            helperText="Gasto mensual"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.med} name="med"
                            label="Médicos"
                            helperText="Gasto mensual"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.dentist} name="dentist"
                            label="Dentista"
                            helperText="Gasto mensual"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.clothes} name="clothes"
                            label="Vestido"
                            helperText="Gasto mensual"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.assured} name="assured"
                            label="Seguros"
                            helperText="Gasto mensual"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.trip} name="trip"
                            label="Viajes"
                            helperText="Gasto mensual"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" type="number" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.other} name="other"
                            label="Otros gastos"
                            helperText="Gasto mensual"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            disabled={estudios}
                            fullWidth margin="normal" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.other_description} name="other_description"
                            multiline rows={5}
                            label="Desgloce de otros gastos aquí"
                            helperText="Gasto mensual"
                        />
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            fullWidth type="number" margin="normal"
                            disabled required={true} variant="outlined"
                            label="Total"
                            name="total"
                            onChange={(e) => handleChange(e)}
                            value={getTotal()}
                        />
                    </GridItem>
                    {!estudios && <GridItem xs={12} style={{display: 'flex'}} alignItems="center" justify="center" >
                        <Button
                            color="primary"
                            size="large"
                            variant="contained"
                            type="submit"
                        >
                            Guardar
                        </Button>
                    </GridItem>}
                </GridContainer>
            </AccordionDetails>
        </Accordion>
        </form>
        {estudios && 
        <form onSubmit={(e) => {
            e.preventDefault();
            submitReason();
        }}>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                        Observaciones gastos mensuales
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <GridContainer direction="row" alignItems="center" justify="center" spacing={2} style={{width: '100%'}}>
                        <GridItem xs={12} >
                            <TextField
                                fullWidth
                                label="Observaciones"
                                margin="normal" name="reason"
                                required={true} multiline rows={3}
                                onChange={(e) => setReason(e.target.value)}
                                value={reason}
                                variant="outlined"
                            />
                        </GridItem>
                        <GridItem xs={12} style={{display: 'flex'}} alignItems="center" justify="center" >
                            <Button
                                color="primary"
                                size="large"
                                variant="contained"
                                type="submit"
                            >
                                Guardar
                            </Button>
                        </GridItem>
                    </GridContainer>
                </AccordionDetails>
            </Accordion>
        </form>}
        </>
    )
}

export default MonthlyExpense;