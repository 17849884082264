import React, {useEffect, useState} from 'react';
import {
    Button,
    TextField,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    MenuItem
} from '@material-ui/core';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import axios from 'src/utils/AxiosHelper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import swal from 'sweetalert';

const initalData = {
    location: '',
    property_price: '',
    property: '',
    permanence: '',
    people: '',
    property_type: '',
    property_status: '',
    owner: '',
    building: '',
    surface: '',
    building_price: '',
    added_value: '',
    bedroom: '',
    bathroom: '',
    living_room: '',
    dinning_room: '',
    yard: '',
    tv: '',
    computer: '',
    dvd_bluray_videogame: '',
    smartdevice: '',
    refrigerator: '',
    cellphone_tablet: '',
    gardener: '',
    domestic_employee: '',
    driver: '',
    kitchener: '',
    cable_phone_internet: '',
    streaming_service: '',
    general_conditions: '',
    observation: ''
}

const BienesDetallados = ({
    family_id,
    formVerification,
    getFormVerifications
}) => {

    useEffect(() => {
        getData();
    }, [])

    //state
    const [userData, setUserData] = useState(initalData);
    const [loaded, setLoaded] = useState(false);
    const [update, setUpdate] = useState(false);

    const getData = () => {
        axios.get(`research/heritageAssetsDetail/${family_id}`)
        .then(async response => {
            if((response.status === 200) && response.data.length > 0){
                await setUserData(response.data[0]);
                setUpdate(true);
            }
            setLoaded(true);
        })
    }

    const handleSubmit = () => {
        userData.family_id=family_id;
        var url = 'research/heritageAssetsDetail';
        if(update){
            userData._method = 'put';
            url = `research/heritageAssetsDetail/${family_id}`;
        }
        axios.post(url, userData)
        .then(response => {
            if(response.status === 200){
                axios.post(`research/researchFormVerification/${family_id}`, {heritage_assets_detail: 1, _method: "put"})
                .then(response => {
                    if(response.status === 200){
                        swal("Datos guardados correctamente", {icon: "success"});
                        getFormVerifications();
                    }
                })
            }
        })
        .catch(error => {
            Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                swal(error.response.data[val][0], {icon: "error"});
            });
        })
    }

    const handleChange = async(e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        })
    }

    return (
        <>
        {
            loaded &&
            <form onSubmit={e => {
                e.preventDefault();
                handleSubmit();
            }}>
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                        Bienes patrimoniales
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* Datos de la familia */}
                    <GridContainer  alignItems="center" justify="center" spacing={2} style={{width: '100%'}}>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.property} name="property"
                                label="Propiedad"
                                select
                                // helperText="Ubicación"
                            >
                                <MenuItem value={'Ninguna'}> Ninguna </MenuItem>
                                <MenuItem value={'Departamento'}> Departamento </MenuItem>
                                <MenuItem value={'Casa'}> Casa </MenuItem>
                                <MenuItem value={'Otra propiedad'}> Otra propiedad </MenuItem>
                            </TextField>
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal"  variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.location} name="location"
                                label="Ubicación"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.property_price} name="property_price"
                                label="Valor estimado"
                            />
                        </GridItem>
                        <GridItem xs={12}>
                            <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                                Datos de la vivienda
                            </Typography>
                        </GridItem>
                        <GridItem xs={12}>
                            <Typography color="textSecondary" gutterBottom variant="h5" style={{textAlign: 'center'}}>
                                Datos generales
                            </Typography>
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.permanence} name="permanence"
                                label="Tiempo viviendo en el domicilio"
                                helperText="años"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.people} name="people"
                                label="¿Nº de personas que viven ahí?"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.property_type} name="property_type"
                                label="Tipo de vivienda"
                                select
                            >
                                <MenuItem value={'Residencial'}> Residencial </MenuItem>
                                <MenuItem value={'Convencional'}> Convencional </MenuItem>
                                <MenuItem value={'Departamento'}> Departamento </MenuItem>
                                <MenuItem value={'Duplex'}> Duplex </MenuItem>
                            </TextField>
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.property_status} name="property_status"
                                label="Estatus de la vivienda"
                                select
                            >
                                <MenuItem value={'Propia'}> Propia </MenuItem>
                                <MenuItem value={'Rentada'}> Rentada </MenuItem>
                                <MenuItem value={'Prestada'}> Prestada </MenuItem>
                                <MenuItem value={'Hipotecada'}> Hipotecada </MenuItem>
                            </TextField>
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.surface} name="surface"
                                label="Superficie"
                                helperText="mts2"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.building} name="building"
                                label="Construcción mts2"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.owner} name="owner"
                                label="Propietario"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.building_price} name="building_price"
                                label="¿Precio de la construcción?"
                            />
                        </GridItem>
                        <GridItem xs={12}>
                            <Typography color="textSecondary" gutterBottom variant="h5" style={{textAlign: 'center'}}>
                                Número de compartimentos
                            </Typography>
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.bedroom} name="bedroom"
                                label="Dormitorios"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.bathroom} name="bathroom"
                                label="Baños"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.living_room} name="living_room"
                                label="Salas"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.yard} name="yard"
                                label="Patios"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.dinning_room} name="dinning_room"
                                label="Comedores"
                            />
                        </GridItem>
                        <GridItem xs={12}>
                            <Typography color="textSecondary" gutterBottom variant="h5" style={{textAlign: 'center'}}>
                                Número de mobiliario
                            </Typography>
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.tv} name="tv"
                                label="TV's"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.computer} name="computer"
                                label="PC's"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.dvd_bluray_videogame} name="dvd_bluray_videogame"
                                label="DVD / Teatro en casa / Videojuegos"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.refrigerator} name="refrigerator"
                                label="Refrigerador"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.smartdevice} name="smartdevice"
                                label="Dispositivos inteligentes"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.cellphone_tablet} name="cellphone_tablet"
                                label="Celulares/Tablets"
                            />
                        </GridItem>
                        
                        <GridItem xs={12}>
                            <Typography color="textSecondary" gutterBottom variant="h5" style={{textAlign: 'center'}}>
                                Número de servicios
                            </Typography>
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.gardener} name="gardener"
                                label="Jardinero"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.domestic_employee} name="domestic_employee"
                                label="Empleado domestico"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.driver} name="driver"
                                label="Conductor"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.kitchener} name="kitchener"
                                label="Cocinero"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.cable_phone_internet} name="cable_phone_internet"
                                label="Internet"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" type="number" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.streaming_service} name="streaming_service"
                                label="Servicios de streaming"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.added_value} name="added_value"
                                multiline rows={5}
                                label="Plusvalia del inmueble"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.general_conditions} name="general_conditions"
                                multiline rows={5}
                                label="Condiciones generales"
                            />
                        </GridItem>
                        <GridItem xs={12} md={6}>
                            <TextField
                                fullWidth margin="normal" variant="outlined" required={true}
                                onChange={(e) => handleChange(e)}
                                value={userData.observation} name="observation"
                                multiline rows={5}
                                label="Observaciones"
                            />
                        </GridItem>
                        <GridItem xs={12} style={{display: 'flex'}} alignItems="center" justify="center" >
                            <Button
                                color="primary"
                                size="large"
                                variant="contained"
                                type="submit"
                            >
                                Guardar
                            </Button>
                        </GridItem>
                    </GridContainer>
                </AccordionDetails>
            </Accordion>
            </form>
        }
        </>
    )
}

export default BienesDetallados;