import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Grid,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  IconButton,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchStyles: {
    width: '100%'
  }
}));

const SearchBar = (props) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = useState('');

  const onChangeInputValue = (e) => {
    setInputValue(e.target.value);
    props.onChange(e.target.value);
  }

  const handleSearchPress = () => {
    props.onSearchPress(inputValue);
  }

  const handleDeletePress = () => {
    setInputValue('');
    props.onDeleteSearch();
  }

  return (
    <div
      className={clsx(classes.root, props.className)}
    >
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid container justify="center" alignItems="center" >
              <Grid item xs={12} md={6} >
                <TextField
                  className={classes.searchStyles}
                  value={inputValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {(inputValue.length > 3) &&
                          <IconButton onClick={handleDeletePress} >
                            <HighlightOffIcon />
                          </IconButton>
                        }
                        <IconButton onClick={handleSearchPress} >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Buscar ..."
                  variant="outlined"
                  onChange={(e) => onChangeInputValue(e)}
                />
              </Grid>
              <Grid container item xs={12} md={6} justify="flex-end" alignItems="flex-end">
                {props.children}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

SearchBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  onDeleteSearch: PropTypes.func.isRequired,
  onSearchPress: PropTypes.func.isRequired
}

export default SearchBar;
