import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Button,
  TextField,
  Typography,
  Grid
} from '@material-ui/core';
import axios from 'src/utils/AxiosHelper';
import CustomModal from 'src/components/CustomModal';
import swal from 'sweetalert';
import Cookies from 'js-cookie';
//redux
import {useSelector, useDispatch} from 'react-redux';
import {setSeenPrivacyPolicy} from 'src/redux/actions';

const PrivacyPolicy = () => {
    
    //cookies
    const userRole = Cookies.getJSON('userData');

    //redux
    const dispatch = useDispatch();
    const showPrivacyPolicy = useSelector(state => state.auth.showPrivacyPolicy);

    useEffect(() => {
    }, [])

    const onHandleClose = () => {
        dispatch(setSeenPrivacyPolicy());
        axios.post(`family/termsAndConditions/${userRole.family_id}`, {terms_and_conditions: 1}, 'put');
    }

    return(
        <>
        <CustomModal
            open={showPrivacyPolicy}
            handleClose={ () => {}}
            hideClose
        >
            <Grid container direction="column" justify="flex-start" alignItems="flex-start" >
                <Box mb={3}>
                    <Typography
                        color="textPrimary"
                        variant="h2"
                    >
                        Aviso de privacidad
                    </Typography>
                </Box>
                <Box mb={3}>
                    <Typography align="left" >
                    En cumplimiento con la ley federal de protección de datos personales en posesión de los particulares y con el fin de asegurar la protección y privacidad de los datos personales, así como regular el acceso, rectificación, cancelación y oposición del manejo de los mismos, Liceo del Valle, A.C. informa que la recolección de datos podrán ser usados con fines promocionales, informativos y estadísticos relacionados con la operación administrativa diaria del colegio.
                    </Typography>
                </Box>
                <Box mb={3}>
                    <Typography align="left" >
                    En cualquier momento, el titular de los datos podrá hacer uso de sus derechos de acceso, rectificación, cancelación y oposición.
                    </Typography>
                </Box>
                <Box mb={3}>
                    <Typography align="left" >
                    Si desea más información, podrá consultar la versión completa de este documento, en el sitio web del colegio en el apartado aviso de privacidad.
                    </Typography>
                </Box>
            </Grid>
            <Box my={2}>
                <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={onHandleClose}
                >
                    Aceptar
                </Button>
            </Box>
        </CustomModal>
        </>
    )
}

export default PrivacyPolicy;