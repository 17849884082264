import React, {useEffect, useState} from 'react';
import {
    Button,
    TextField,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    MenuItem
} from '@material-ui/core';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import axios from 'src/utils/AxiosHelper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from 'src/components/CustomTable/CustomTable';
import swal from 'sweetalert';

const cols = [
    {accessor: "register", header: "Matricula"},
    {accessor: "lastname", header: "Apellido paterno"},
    {accessor: "second_lastname", header: "Apellido materno"},
    {accessor: "name", header: "Nombre"},
    {accessor: "section", header: "Sección"},
    {accessor: "grade", header: "Grado"}
];

const colsUp = [
    {accessor: "register", header: "Matricula"},
    {accessor: "lastname", header: "Apellido paterno"},
    {accessor: "second_lastname", header: "Apellido materno"},
    {accessor: "name", header: "Nombre"},
    {accessor: "semester", header: "Semestre"}
];

const StudentPercentForm = ({
    family_id,
    formVerification,
    getFormVerifications,
    apoyo = false,
    estudios = false,
    observations= {}
}) => {

    useEffect(() => {
        getFamilyData();
        if(estudios){
            setReason(observations.student);
        }
    }, [])

    //state
    const [studetns, setStudetns] = useState([]);
    const [percentages, setPercentages] = useState([]);
    const [upStudents, setUpStudents] = useState([]);
    const [settings, setSettings] = useState({});
    const [numberOfChilds, setNumberOfChilds] = useState(0);
    const [reason, setReason] = useState('');

    const handleSubmit = () => {
        axios.post(`family/formVerification/${family_id}`, {student: 1, _method: "put"})
        .then(response => {
            if(response.status === 200){
                swal("Datos de los alumnos actualizados correctamente", {icon: "success"});
                getFormVerifications();
            }
        });
    }

    const getFamilyData = async() => {
        var cont = 0;
        await axios.get(`family/studentSchoolarship/${family_id}`)
        .then(response => {
            if(response.status === 200){
                setStudetns(response.data);
                cont = cont + response.data.length;
            }
        });
        await axios.get(`family/upStudent/${family_id}`)
        .then(response => {
            if(response.status === 200){
                setUpStudents(response.data);
                cont = cont + response.data.length;
            }
        });
        getPercentages(cont);
    }

    const getPercentages = (cont) => {
        if (apoyo) {
            axios.get(`auth/settings`)
            .then(async response => {
                if(response.status === 200){
                    await setSettings(response.data);
                }
                if (cont > 3) {
                    setPercentages([{value: response.data.four_up_children_percent, name: `${response.data.four_up_children_percent}%`}])
                }
                else if(cont === 3){
                    setPercentages([{value: response.data.three_children_percent, name: `${response.data.three_children_percent}%`}])
                }
            });
        }
        else {
            var aux = [];
            for (let index = 0; index < 101; index++) {
                aux.push({value: index, name: `${index}%`})
            }
            setPercentages(aux);
        }
    }

    const handleChange = (e, customer) => {
        axios.post(`family/studentSchoolarship/${customer.id}`, {schoolarship_percent: e.target.value, _method: "put"})
        .then(response => {
            if(response.status === 200){
                swal("Datos del alumnos actualizados correctamente", {icon: "success"});
                getFamilyData();
            }
        })
        .catch(error => {
            Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                swal(error.response.data[val][0], {icon: "error"});
            });
        })
    }
    
    const handleChangeUp = (e, customer) => {
        axios.post(`family/upStudent/${customer.id}`, {schoolarship_percent: e.target.value, _method: "put"})
        .then(response => {
            if(response.status === 200){
                swal("Datos del alumnos actualizados correctamente", {icon: "success"});
                getFamilyData();
            }
        })
    }

    const submitReason = () => {
        axios.post(`research/observation/${family_id}`, { student: reason }, 'put')
        .then(response => {
            if(response.status === 200){
                axios.post(`research/researchFormVerification/${family_id}`, {student: 1}, 'put')
                .then(response => {
                    if(response.status === 200){
                        swal("Correcto", "Observaciones guardadas de manera correcta", "success");
                    }
                })
            }
        })
    }

    return (
        <>
        <Accordion defaultExpanded={!estudios}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                    Datos alumno
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {/* Datos de la familia */}
                <form onSubmit={e => {
                    e.preventDefault();
                    handleSubmit()
                }}>
                    <GridContainer  alignItems="center" justify="center" spacing={2} style={{width: '100%'}}>
                        <GridItem xs={12} >
                            <Table 
                                cols={cols}
                                rows={studetns}
                                select
                                selectorTitle="% de beca solicitado"
                                selectAccesor={"schoolarship_percent"}
                                selectOptions={percentages}
                                disableSelectors={estudios}
                                onSelectChange={(e, customer) => {handleChange(e, customer)}}
                            />
                        </GridItem>
                        <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                            Datos alumnos UP
                        </Typography>
                        <GridItem xs={12} >
                            <Table 
                                cols={colsUp}
                                rows={upStudents}
                                select
                                disableSelectors={estudios}
                                selectorTitle="% de beca solicitado"
                                selectAccesor={"schoolarship_percent"}
                                selectOptions={percentages}
                                onSelectChange={(e, customer) => {handleChangeUp(e, customer)}}
                            />
                        </GridItem>
                        {!estudios && <GridItem xs={12} style={{display: 'flex'}} alignItems="center" justify="center" >
                            <Button
                                color="primary"
                                size="large"
                                variant="contained"
                                type="submit"
                                // onClick={handleSubmit}
                            >
                                Guardar
                            </Button>
                        </GridItem>}
                    </GridContainer>
                </form>
            </AccordionDetails>
        </Accordion>
        {estudios && 
            <form onSubmit={(e) => {
                e.preventDefault();
                submitReason();
            }}>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                            Observaciones alumnos
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <GridContainer direction="row" alignItems="center" justify="center" spacing={2} style={{width: '100%'}}>
                            <GridItem xs={12} >
                                <TextField
                                    fullWidth
                                    label="Observaciones"
                                    margin="normal" name="reason"
                                    required={true} multiline rows={3}
                                    onChange={(e) => setReason(e.target.value)}
                                    value={reason}
                                    variant="outlined"
                                />
                            </GridItem>
                            <GridItem xs={12} style={{display: 'flex'}} alignItems="center" justify="center" >
                                <Button
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    type="submit"
                                >
                                    Guardar
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </AccordionDetails>
                </Accordion>
            </form>}
        </>
    )
}

export default StudentPercentForm;