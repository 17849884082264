import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    makeStyles,
    TextField,
    MenuItem,
    Typography,
    Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import CustomTable from 'src/components/CustomTable/CustomTable';
import axios from 'src/utils/AxiosHelper';
import CustomModal from 'src/components/CustomModal';
import swal from 'sweetalert';
import Cookies from 'js-cookie';
//Core components
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
//Estudios components
import { CSVLink } from "react-csv";
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import SearchBar from 'src/components/SearchBar';

const cols = [
    {accessor: "code", header: "Codigo"},
    {accessor: "name", header: "Nombre"},
    {accessor: "ft_name", header: "Nombre (Padre)"},
    // {accessor: "ft_email", header: "Email (Padre)"},
    {accessor: "mt_name", header: "Nombre (Madre)"},
    {accessor: "company_name", header: "Empresa"}
];

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    card: {
        width: "100%",
        minHeight: "20vh",
    },
    pos: {
        marginBottom: 10
    },
}));

const AsignacionEstudios = () => {
    const classes = useStyles();
    const [rowsData, setRowsData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [currentCustomer, setCurrectCustomer] = useState({});
    //Company
    const [hasComany, setHasComany] = useState(false);
    const [companySelector, setcompanySelector] = useState('');
    const [companies, setCompanies] = useState([]);

    const permission = Cookies.getJSON('permission');
    
    useEffect(() => {
        getUsers();
    }, [])

    const getUsers = () => {
        var aux = [["Codigo", "Nombre","Nombre (Padre)", "Email (Padre)", "Nombre (Madre)", "Email(Madre)", "Empresa"]];
        axios.get('auth/familiesForResearch')
        .then(async response => {
            if (response.status === 200) {
                response.data.map((item, key) => {
                    item.code = item.family.user.code;
                    item.name = item.family.user.name;
                    item.ft_name = item.family.ft_name;
                    item.ft_email = item.family.ft_email;
                    item.mt_name = item.family.mt_name;
                    item.mt_email = item.family.mt_email;
                    item.company_name = item.family.company? item.family.company.name : 'No asignado';
                    aux.push([ item.code, item.name, item.ft_name, item.ft_email, item.mt_name, item.mt_email, item.company_name])
                })
                await setExcelData(aux);
                await setRowsData(response.data);
                await setIsLoading(false);
            }
        })

        
        axios.get('auth/userCompany')
        .then(response => {
            if(response.status === 200){
                setCompanies(response.data);
            }
        })
    }

    const onEditAction = async (customer) => {
        await setCurrectCustomer(customer);
        if(customer.family.company_id === null){
            await setHasComany(false);
        }
        else{
            await setHasComany(true);
        }
        setShowModal(true);
    }

    const handleSubmit = () => {
        if(hasComany){
            axios.post( `auth/familiesForResearch/${currentCustomer.family.id}`, { company_id: companySelector, _method: 'put' } )
            .then(response => {
                if (response.status === 200) {
                    axios.post( `research/researchFormVerification/${currentCustomer.family_id}`, { company_id: companySelector, _method: 'put' } )
                    .then(response => {
                        if (response.status === 200) {
                            swal("Empresa asignada correctamente", { icon: "success" });
                            getUsers();
                            setShowModal(false);
                        }
                    })
                    .catch(error => {
                        Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                            swal(error.response.data[val][0], {icon: "error"});
                        });
                        // swal("Oops!", "Ocurrio un error al intentar asignar la empresa", "error")
                    });
                }
            })
            .catch(error => {
                Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                    swal(error.response.data[val][0], {icon: "error"});
                });
                // swal("Oops!", "Ocurrio un error al intentar asignar la empresa", "error")
            });
        }
        else{
            axios.post(`research/formVerification`,
            {
                family_id: currentCustomer.family_id,
                company_id: companySelector,
                family: 0,
                student: 0,
                economic_dependent: 0,
                monthly_income: 0,
                monthly_expenses: 0,
                vehicle: 0,
                heritage_assets_detail: 0,
                family_health: 0,
                leisure_time: 0,
                economic_situation_and_conclusion: 0,
                photos: 0,
                finished: 0,
            })
            .then(response => {
                if(response.status === 200){
                    axios.post( `auth/familiesForResearch/${currentCustomer.family.id}`, { company_id: companySelector, _method: 'put' } )
                    .then(response => {
                        if (response.status === 200) {
                            swal("Empresa asignada correctamente", { icon: "success" });
                            getUsers();
                            setShowModal(false);
                        }
                    })
                    .catch(error => {
                        Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                            swal(error.response.data[val][0], {icon: "error"});
                        });
                        // swal("Oops!", "Ocurrio un error al intentar asignar la empresa", "error")
                    });
                    swal("Empresa asignada correctamente", { icon: "success" });
                }
            })
            .catch(error => {
                Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                    swal(error.response.data[val][0], {icon: "error"});
                });
            });
        }
    }

    const onChangeSearchField = (value) => {
        if (value.length > 3) {
            setTimeout(() => {
                onSearchPress(value);
            }, 500);
        }
        else {
            setIsSearching(false);
        }
    }

    const onDeleteSearch = () => {
        setIsSearching(false);
    }

    const onSearchPress = async(value) => {
        var aux = [];
        await rowsData.map(item => {
            if (
                (item.code && item.code.toString().toLowerCase().includes(value.toLowerCase())) ||
                (item.ft_name && item.ft_name.toLowerCase().includes(value.toLowerCase())) ||
                (item.ft_email && item.ft_email.toLowerCase().includes(value.toLowerCase())) ||
                (item.mt_name && item.mt_name.toLowerCase().includes(value.toLowerCase())) || 
                (item.mt_email && item.mt_email.toLowerCase().includes(value.toLowerCase()))
            ){
                aux.push(item);
            }
        })
        await setFilteredData(aux);
        await setIsSearching(true);
    }

    return (
        <Page
            className={classes.root}
            title="Asignación de empresas"
        >
            <Container maxWidth={false} style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h1">Asignación de empresas</Typography>
                <SearchBar
                    onChange={(value) => {onChangeSearchField(value)}}
                    onDeleteSearch={onDeleteSearch}
                    onSearchPress={(value) => {onSearchPress(value)}}
                >
                    <Button
                        size="small"
                        style={{alignSelf: 'flex-end'}}
                        endIcon={<TableChartOutlinedIcon/>}
                    >
                        <CSVLink
                            data={excelData}
                            filename={"Estudios-por-asignar.csv"}
                            className="btn btn-primary"
                            target="_blank"
                            style={{height: 0, display: 'flex', color: '#263238',justifyContent: 'center', alignItems: 'center' }}
                        >
                        Exportar
                        </CSVLink>
                    </Button>
                </SearchBar>
                <Box mt={3}>
                    {
                        !isLoading &&
                        <CustomTable
                            cols={cols}
                            rows={isSearching? filteredData : rowsData}
                            actions={true}
                            delAction={false}
                            showAction={false}
                            updateAction={(permission.update === 1) ? true : false}
                            onEdit={(customer) => {onEditAction(customer)}}
                        />
                    }
                </Box>
            </Container>
            <CustomModal
                open={showModal}
                handleClose={ () => setShowModal(false) }
            >
                <GridContainer direction="column" alignItems="center" justify="center" spacing={2} style={{width: '100%'}}>
                    <GridItem xs={12} >
                        <Typography variant="h3" component="h2" style={{marginBottom: 20}}>
                            Asignación de empresa
                        </Typography>
                        <TextField 
                            select label="Seleccione una empresa" 
                            value={companySelector} 
                            fullWidth
                            style={{minWidth: 200}}
                            onChange={(e) => {setcompanySelector(e.target.value)}} margin="normal" fullWidth 
                        >
                            {
                                companies.map((item, key) => (
                                    <MenuItem value={item.id} > {item.name} </MenuItem>
                                ))
                            }
                        </TextField>
                    </GridItem>
                    <GridItem>
                        <Button
                            color="primary"
                            fullWidth
                            size="large"
                            variant="contained"
                            onClick={handleSubmit}
                        >
                            Modificar
                        </Button>
                    </GridItem>
                </GridContainer>
            </CustomModal>
        </Page>
    );
};

export default AsignacionEstudios;