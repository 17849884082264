import React, {useEffect, useState} from 'react';
import {
    Button,
    TextField,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    MenuItem
} from '@material-ui/core';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import axios from 'src/utils/AxiosHelper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import swal from 'sweetalert';

const initalData = {
    situation: '',
    conclusion: ''
}

const Situación = ({
    family_id,
    formVerification,
    getFormVerifications
}) => {

    useEffect(() => {
        getData();
    }, [])

    //state
    const [userData, setUserData] = useState(initalData);
    const [update, setUpdate] = useState(false);

    const getData = () => {
        axios.get(`research/situationAndConclusion/${family_id}`)
        .then(async response => {
            if((response.status === 200) && response.data.length > 0){
                await setUserData(response.data[0]);
                setUpdate(true);
            }
        })
    }

    const handleSubmit = () => {
        userData.family_id=family_id;
        var url = 'research/situationAndConclusion';
        if(update){
            userData._method = 'put';
            url = `research/situationAndConclusion/${family_id}`;
        }
        axios.post(url, userData)
        .then(response => {
            if(response.status === 200){
                axios.post(`research/researchFormVerification/${family_id}`, {economic_situation_and_conclusion: 1, _method: "put"})
                .then(response => {
                    if(response.status === 200){
                        swal("Datos guardados correctamente", {icon: "success"});
                        getFormVerifications();
                    }
                })
            }
        })
        .catch(error => {
            Object.getOwnPropertyNames(error.response.data).forEach(function(val, idx, array) {
                swal(error.response.data[val][0], {icon: "error"});
            });
        })
    }

    const handleChange = async(e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        })
    }

    return (
        <>
        <form onSubmit={e => {
            e.preventDefault();
            handleSubmit();
        }}>
        <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography color="textSecondary" gutterBottom variant="h4" style={{textAlign: 'center'}}>
                    Situación económica y conclusión
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <GridContainer alignItems="center" justify="center" spacing={2} style={{width: '100%'}}>
                    <GridItem xs={12} container direction="column" alignItems="center" justify="center" spacing={2} style={{width: '100%'}}>
                        <Typography>
                            Excelente - Familia con superavit y economia estable
                        </Typography>
                        <Typography>
                            Buena - Familia sin superavit, pero con economia estable
                        </Typography>
                        <Typography>
                            Regular - Familia con economía variable
                        </Typography>
                        <Typography>
                            Mala - Familia con déficit
                        </Typography>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            fullWidth margin="normal" variant="outlined" required={true} 
                            onChange={(e) => handleChange(e)}
                            value={userData.situation} name="situation"
                            label="Situación economica"
                            select
                        >
                            <MenuItem value={"Excelente"} > {"Excelente"} </MenuItem>
                            <MenuItem value={"Buena"} > {"Buena"} </MenuItem>
                            <MenuItem value={"Regular"} > {"Regular"} </MenuItem>
                            <MenuItem value={"Mala"} > {"Mala"} </MenuItem>
                        </TextField>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <TextField
                            fullWidth margin="normal" variant="outlined" required={true}
                            onChange={(e) => handleChange(e)}
                            value={userData.conclusion} name="conclusion"
                            multiline rows={5}
                            label="Conclusión"
                        />
                    </GridItem>
                    <GridItem xs={12} style={{display: 'flex'}} alignItems="center" justify="center" >
                        <Button
                            color="primary"
                            size="large"
                            variant="contained"
                            type="submit"
                        >
                            Guardar
                        </Button>
                    </GridItem>
                </GridContainer>
            </AccordionDetails>
        </Accordion>
        </form>
        </>
    )
}

export default Situación;