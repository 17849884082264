import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Cookies from 'js-cookie';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Page from 'src/components/Page';
import axios from 'src/utils/AxiosHelper';
import swal from 'sweetalert';
import GridContainer from 'src/components/Grid/GridContainer';
import GridItem from 'src/components/Grid/GridItem';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser, logoutUser, setShowPrivacyPolicy } from 'src/redux/actions';

//logos
import logo from 'src/icons/logo.png';
import altamira from 'src/icons/altamira.png';
import beelieve from 'src/icons/beelieve.png';
import laCima from 'src/icons/laCima.png';
import liceo from 'src/icons/liceo.png';
import losAltos from 'src/icons/losAltos.png';
import torre from 'src/icons/torre.png';
import tz from 'src/icons/tz.png';
import up from 'src/icons/up.png';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  //redux
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

  //state
  const [schoolarships, setSchoolarships] = useState([]);

  useEffect(() => {

    let mounted = true
    getSchoolarchips();
    if (isAuthenticated) {
      dispatch(logoutUser());
    }

    return function cleanup() {
      mounted = false
    }
  }, [])

  const getSchoolarchips = () => {
    axios.get('general/schoolarship')
      .then(response => {
        if (response.status === 200) {
          setSchoolarships(response.data);
        }
      })
      .catch(error => {
        swal("Error", "Ocurrio un error al traer los tipos de becas disponibles", "error");
      })
  }

  return (
    <Page
      className={classes.root}
      title=".:BECAS -Inicio de sesión:."
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm" style={{ display: 'flex', flexDirection: 'column' }}>
          <GridContainer direction="row" alignItems="center" justifyContent="center" >
            <GridItem container direction="column" xs={3} alignItems="center" justifyContent="center">
              <img src={altamira} style={{ width: 100, maxWidth: '25vw', marginTop: 8, alignSelf: 'center' }} />
              <img src={up} style={{ width: 80, maxWidth: '20vw', marginTop: 8, alignSelf: 'center' }} />
              <img src={laCima} style={{ width: 100, maxWidth: '25vw', marginTop: 8, alignSelf: 'center' }} />
              <img src={liceo} style={{ width: 120, maxWidth: '25vw', marginTop: 8, alignSelf: 'center' }} />
            </GridItem>
            <GridItem container direction="column" xs={6} alignItems="center" justifyContent="center">
              <img src={logo} style={{ width: 180, alignSelf: 'center' }} />
              <Typography color="textPrimary" variant="h3" style={{ textAlign: "center", marginTop: 20 }} >
                Iniciar sesión
              </Typography>
            </GridItem>
            <GridItem container direction="column" xs={3} alignItems="center" justifyContent="center">
              <img src={losAltos} style={{ width: 100, maxWidth: '20vw', marginTop: 8, alignSelf: 'center' }} />
              <img src={torre} style={{ width: 60, maxWidth: '20vw', marginTop: 8, alignSelf: 'center' }} />
              <img src={beelieve} style={{ width: 100, maxWidth: '20vw', marginTop: 8, alignSelf: 'center' }} />
              <img src={tz} style={{ width: 75, maxWidth: '20vw', marginTop: 8, alignSelf: 'center' }} />
            </GridItem>

          </GridContainer>
          <Formik
            initialValues={{
              code: '', password: '', schoolarship_id: 1
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().max(255).required('La contraseña es requerida'),
              schoolarship_id: Yup.number().required('Seleccione una opción de becas')
            })}
            onSubmit={(values, { setSubmitting }) => {
              axios.post('family/loginFamily', values)
                .then(async (response) => {
                  console.log(response);
                  if ((response.status === 200) && response.data) {
                    await dispatch(loginUser(1));
                    if (response.data.user.terms_and_conditions === 0) {
                      await dispatch(setShowPrivacyPolicy())
                    }
                    await Cookies.set('userData', response.data.user, { expires: 1 });
                    axios.get(`family/formVerification/${response.data.user.family_id}`)
                      .then(response => {
                        if (response.status === 200) {
                          if (response.data.length > 0) {
                            var schoolarship_id = response.data[0].schoolarship_id;
                            var option = (schoolarship_id === 1) ? 'internas' : (schoolarship_id === 2) ? 'familiaValidacion' : 'orfandad';
                            navigate(`/becas/${option}`, { replace: true });
                          }
                          else {
                            navigate(`/confirmation/${values.schoolarship_id}`, { replace: true });
                          }
                        }
                      });
                  }
                })
                .catch((error) => {
                  swal(error.response.data.message, { icon: "error" })
                });
              setSubmitting(false);
            }}
          >
            {({
              errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  error={Boolean(touched.code && errors.code)}
                  fullWidth
                  helperText={touched.code && errors.code}
                  label="Código" margin="normal" name="code" onBlur={handleBlur} onChange={handleChange} type="text" value={values.code} variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Contraseña" margin="normal" name="password" onBlur={handleBlur} onChange={handleChange} type="password"
                  value={values.password} variant="outlined"
                />
                <TextField id="select" required select label="Tipo de beca" value={values.schoolarship_id} onChange={handleChange("schoolarship_id")} margin="normal" fullWidth >
                  {
                    schoolarships.map((item, key) => {
                      return (
                        <MenuItem key={key} value={item.id} > {item.name} </MenuItem>
                      )
                    })
                  }
                </TextField>
                <Typography>
                  <RouterLink to="/recoveryPassword">Recuperar contraseña</RouterLink>
                </Typography>
                <Box my={2}>
                  <Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" >
                    Iniciar sesión
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
